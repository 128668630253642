import React from 'react';
import '../css/Kidney.css'; // Add custom styles here
const KidneyPage = () => {
  
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/sh11.webp')}alt="Urology Hero" />
        <h1>Urticaria</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Urticaria</h2>
          <img src={require('../images/skin11.webp')}alt="Urology Hero" />
          <p>
          Homoeopathy treats urticaria by focusing on the individual’s unique symptoms, triggers, and overall constitution. Remedies are selected based on the specific type of rash, as well as any underlying health issues or emotional factors. The goal is to relieve itching, reduce inflammation, prevent recurrence, and strengthen the body’s natural defences. Urticaria can be acute or chronic, so homoeopathic treatment focuses on immediate relief as well as long-term management.          </p>
          <h3>Causes </h3>
          <ul>
            <li><h5>Allergic Reactions:</h5>Common allergens such as certain foods (nuts, shellfish, eggs), medications (antibiotics, NSAIDs), and insect bites can trigger urticaria.            </li>
            <li><h5>Physical Triggers:</h5>Environmental factors like heat, cold, sunlight, or pressure can cause hives in susceptible individuals.            </li>
            <li><h5>Infections:</h5>Viral infections, bacterial infections, and sometimes fungal infections can lead to urticaria.            </li>
            <li><h5>Stress:</h5>Emotional stress and anxiety can trigger or worsen urticaria, particularly in chronic cases.            </li>
            <li><h5>Autoimmune Disorders:</h5>Conditions such as lupus, thyroid disease, or other autoimmune diseases may be associated with chronic urticaria.            </li>
            <li><h5>Exercise and Sweating:</h5>Physical activity or increased body temperature can sometimes cause hives, particularly in exercise-induced urticaria.            </li>
          </ul>
          <h3>Symptoms </h3>
          <ul>
            <li><h5>Raised, Red Welts:</h5>Welts or wheals may appear anywhere on the body and can vary in size. They are often red or pink, with a pale center.            </li>
            <li><h5>Intense Itching:</h5>The rash is typically accompanied by severe itching, which may worsen at night or when the skin is warm.            </li>
            <li><h5>Swelling:</h5>In some cases, swelling can extend beyond the skin, affecting areas such as the lips, eyelids, and even the throat, a condition known as angioedema.            </li>
            <li><h5>Temporary Marks:</h5>The welts may last for a few hours before fading, only to reappear in other areas.            </li>
            <li><h5>Burning or Stinging Sensation:</h5>Some individuals may experience a burning or stinging sensation, especially when the rash is exposed to heat.            </li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Angioedema:</h5>Severe swelling of deeper skin layers, particularly around the eyes and lips, can accompany urticaria and may cause breathing difficulties if it affects the throat.            </li>
            <li><h5>Anaphylaxis:</h5>In rare cases, urticaria can be part of a severe allergic reaction that includes difficulty breathing, low blood pressure, and other life-threatening symptoms. Immediate medical attention is required.            </li>
            <li><h5>Chronic Urticaria:</h5>Recurring episodes lasting more than six weeks can significantly impact quality of life and may require long-term management.            </li>
            <li><h5>Emotional Impact:</h5>Chronic urticaria can lead to stress, anxiety, and reduced quality of life due to constant itching and discomfort.            </li>
         </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Antihistamines:</h5>Oral antihistamines are the first line of treatment for relieving itching and reducing welts. Non-drowsy formulations are often preferred.            </li>
            <li><h5>Corticosteroids:</h5>For severe or persistent cases, corticosteroids may be prescribed to reduce inflammation and relieve symptoms. These are typically used only for short periods.            </li>
            <li><h5>Anti-inflammatory Medications:</h5>In some cases, medications such as leukotriene inhibitors or immunosuppressants are used for chronic urticaria.In cases of severe allergic reactions or anaphylaxis, an epinephrine injection is administered immediately.        </li>
            <li><h5>Avoidance of Triggers:</h5>Identifying and avoiding known triggers, such as allergens, foods, or environmental factors, is crucial in managing urticaria.            </li>
            <li><h5>Epinephrine:</h5>In cases of severe allergic reactions or anaphylaxis, an epinephrine injection is administered immediately.            </li>
          </ul>
          <h3>Homoeopathic Treatment</h3>
          <ul>
            <li><h5>Apis Mellifica:</h5>Effective for hives that are red, swollen, and accompanied by intense stinging or burning. The symptoms often worsen with heat and improve with cold applications.            </li>
            <li><h5>Urtica Urens:</h5>Useful for hives with intense itching and burning, particularly when triggered by shellfish or insect bites. It is also suitable for cases with a history of recurring hives.            </li>
            <li><h5>Rhus Toxicodendron:</h5>Suitable for hives that are itchy, red, and aggravated by cold but improve with warmth and movement. This remedy is particularly helpful for urticaria caused by physical triggers like cold or pressure.            </li>
            <li><h5>Natrum Muriaticum:</h5>Recommended for hives associated with emotional stress or triggered by sun exposure. It is useful for individuals with dry skin, sensitivity to heat, and a tendency to develop hives on exposed areas.            </li>
            <li><h5>Sulphur:</h5>Known for treating chronic, itchy skin conditions, this remedy is ideal for hives with intense burning and itching that worsen with heat, particularly at night. Sulphur is suitable for individuals with dry skin and a tendency toward recurring skin problems.            </li>
          </ul>
         <h3>Conclusion / Our Experience</h3>
          <p>
          In our experience, homoeopathy offers an individualized and gentle approach to managing urticaria by addressing both the physical symptoms and underlying triggers. Many individuals report relief from itching, reduced frequency of flare-ups, and improved skin health with consistent homoeopathic treatment. While acute urticaria often resolves with prompt treatment, chronic cases may require long-term homoeopathic care to prevent recurrence and improve overall quality of life. For the best outcomes, combining homoeopathic remedies with lifestyle modifications, such as avoiding known triggers and maintaining good skin care, is essential. Consulting a qualified healthcare provider is recommended to ensure safe and effective management of urticaria.
          </p>
          </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Urticaria Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Urticaria.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/skin/urticaria/urticaria-diet">Diet for Urticaria prevention</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Urticaria</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Urticaria</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Urticaria Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/2xRA-fm0nDI?si=xkifZiM5ewz1OPUv"
                    title="Infertility Treatment Video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
