import React, { useState } from 'react';
import '../css/ContactUs.css';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile_number: '',
    message: ''
  });

  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="contact-us-page">
      <div className="heros-section">
        <div className='Contact-new'>
          <h1>Get in Touch with Niramaya Homoeopathy</h1>
          <h2>We are here to assist you with all your healthcare needs.</h2>
          <h5>Contact us today and take the first step towards a healthier life.</h5>
        </div>
      </div>

      <div className="contact-us-container">
        <div className="contact-info-cards">
          <div className="card">
            <i className="fas fa-map-marker-alt"></i>
            <h3>OUR CLINIC</h3>
            <p>0230 B Civil Lines 2 Bilandpur near DIG Bunglow, Gorakhpur, Uttar Pradesh, India, 273001</p>
          </div>
          <div className="card">
            <i className="fas fa-phone"></i>
            <h3>PHONE NUMBER</h3>
            <a href="tel:+919236185711" className="contact-link">+919236185711</a>
            <i className="fas fa-envelope"></i>
            <h3>EMAIL</h3>
            <a href="mailto:niramayaforyou@gmail.com" className="contact-link">niramayaforyou@gmail.com</a>
          </div>
        </div>

        {/* Contact Form */}
        <div className="contact-form">
          <h2>Contact Us</h2>
          <form 
            action="https://api.web3forms.com/submit" 
            method="POST"
            onSubmit={() => setResponseMessage("Form submitted successfully!")}
          >
            {/* Access Key for Web3Forms */}
            <input
              type="hidden"
              name="access_key"
              value="90277967-72b9-4089-93c9-5ec2b7137a38" // Replace with your actual Web3Forms access key
            />
            <input
              type="text"
              name="name"
              placeholder="Enter your Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Enter a valid email address"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="mobile_number"
              placeholder="Enter your mobile number"
              value={formData.mobile_number}
              onChange={handleChange}
              required
            />
            <textarea
              name="message"
              placeholder="Your message"
              rows="4"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            <button type="submit">SUBMIT</button>
          </form>
          {/* Display response message */}
          {responseMessage && <p>{responseMessage}</p>}
        </div>
      </div>

      <div className="map-section">
  <iframe
    title="Niramaya Homoeopathy Location"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d114008.58764245966!2d83.31226966300316!3d26.751766994497036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39914486bfb2c0e9%3A0xaf54983a8579801c!2sDr%20Atul%20kumar%20singh%20(BHMS%2C%20MD%2C%20PG%20London)%20Homeopath!5e0!3m2!1sen!2sin!4v1728539308354!5m2!1sen!2sin"
    width="100%"
    height="450"
    style={{ border: 0 }}
    allowFullScreen=""
    loading="lazy"
    referrerPolicy="no-referrer-when-downgrade"
  ></iframe>
</div>

    </div>
  );
};

export default ContactUs;
























