import React from 'react';
import '../css/Kidney.css'; // Add custom styles here
const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/sh12.webp')}alt="Urology Hero" />
        <h1>Keloid</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Keloid</h2>
          <img src={require('../images/skin12.webp')}alt="Urology Hero" />
          <p>
          Homoeopathy treats keloids by focusing on the individual’s constitution and the body’s response to injury. Remedies are selected based on the specific characteristics of the keloid, as well as the patient’s overall health. Homoeopathic treatment aims to reduce the size and hardness of the keloid, alleviate associated symptoms such as itching and discomfort, and prevent further growth. Since keloids are often recurrent, homoeopathy can be helpful for long-term management.          </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Injury or Trauma:</h5>Keloids typically form after skin injuries such as cuts, burns, surgical incisions, or piercings. Any break in the skin can potentially lead to keloid formation in susceptible individuals.            </li>
            <li><h5>Genetic Predisposition:</h5>People with a family history of keloids are more likely to develop them, suggesting a genetic component to keloid formation.            </li>
            <li><h5>Infections:</h5>Skin infections or inflammation after an injury can increase the likelihood of keloid development.            </li>
            <li><h5>Skin Conditions:</h5>Acne, chickenpox, and other skin conditions that cause inflammation or scarring can trigger keloid formation.            </li>
            <li><h5>Surgical Procedures:</h5>Certain surgical procedures, especially in areas prone to keloids (like the chest, shoulders, and back), may lead to keloid development.            </li>
          </ul>
          <h3>Symptoms </h3>
          <ul>
            <li><h5>Raised, Firm Scars:</h5>Keloids are typically thick, raised, and firm to the touch, extending beyond the original wound area.            </li>
            <li><h5>Shiny and Smooth Appearance:</h5>Keloids often have a shiny, smooth surface, sometimes with a pink, red, or purple hue.            </li>
            <li><h5>Itching and Discomfort:</h5>Keloids can be itchy, tender, or even painful, especially if they are growing or located in an area of frequent movement.            </li>
            <li><h5>Gradual Growth:</h5>Keloids can continue to grow over time, even after the wound has healed, sometimes reaching several inches in size.            </li>
            <li><h5>Asymmetry:</h5>Keloids can have irregular shapes and may look different from regular scars, often growing outward in a spreading pattern.            </li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Recurrence:</h5>Keloids are prone to recurrence, especially if not adequately treated. Even after removal, they may grow back.            </li>
            <li><h5>Functional Impairment:</h5>Large keloids in areas like joints can restrict movement, causing discomfort and difficulty with daily activities.            </li>
            <li><h5>Aesthetic Concerns:</h5>Due to their size, location, and appearance, keloids can cause self-consciousness or emotional distress.            </li>
            <li><h5>Infection Risk:</h5>In rare cases, keloids can become infected if irritated or scratched excessively, particularly if located on areas prone to friction.            </li>
         </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Corticosteroid Injections:</h5>Corticosteroids are injected directly into the keloid to reduce inflammation and flatten the scar. Multiple treatments may be required.            </li>
            <li><h5>Cryotherapy:</h5>Freezing the keloid with liquid nitrogen can help reduce its size. This treatment is often combined with other therapies.            </li>
            <li><h5>Surgical Removal:</h5>In some cases, the keloid may be surgically removed. However, this may lead to recurrence or an even larger keloid formation.            </li>
            <li><h5>Laser Therapy:</h5>Lasers can reduce the thickness and pigmentation of keloids. This treatment is often used alongside other therapies for better results.            </li>
            <li><h5>Silicone Gel Sheets:</h5>These are applied over the keloid to help flatten it and reduce itching. They work best on smaller or new keloids.            </li>
          </ul>
          <h3>Homoeopathic Treatment</h3>
          <ul>
            <li><h5>Thiosinaminum:</h5>Known for its effectiveness in reducing scar tissue, Thiosinaminum is often used to soften and reduce the size of keloids. It is particularly helpful for firm, thick scars.            </li>
            <li><h5>Silicea:</h5>Effective for keloids that are sensitive and tend to grow. It is beneficial for individuals prone to recurring keloids and helps reduce itchiness.            </li>
            <li><h5>Natrum Muriaticum:</h5>Suitable for keloids that form due to emotional stress or grief, particularly in cases where the scars are itchy, hard, and slowly growing.            </li>
            <li><h5>Graphites:</h5>Recommended for individuals with raised, hard, and rough scars. It is suitable for keloids that are itchy or that ooze a sticky discharge when irritated.            </li>
            <li><h5>Fluoricum Acidum:</h5>This remedy is helpful for keloids that are painful, hard, and sensitive to touch. It is beneficial for scars that grow rapidly or form along with other skin conditions.            </li>
          </ul>
         <h3>Conclusion / Our Experience</h3>
          <p>
          In our experience, homoeopathy offers a natural approach to managing keloids by addressing both the physical symptoms and underlying factors that contribute to scar formation. Many individuals report softer, smaller scars, reduced itching, and improved skin texture with consistent homoeopathic treatment. While homoeopathy may not eliminate large or longstanding keloids, it serves as an effective complementary approach that helps prevent recurrence and minimizes discomfort. For optimal results, it is essential to combine homoeopathic remedies with protective measures, such as avoiding trauma or irritation in prone areas. Working with a qualified healthcare provider can ensure comprehensive and safe care for keloids.
          </p>
          </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Keloid Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Keloid.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/skin/keloid/keloid-diet">Diet for Keloid prevention</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Keloid</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Keloid</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Keloid Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/881Ui-soo20?si=YZSfLZ_J3CFucSRH"
                    title="Infertility Treatment Video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
