import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/fh11.webp')}alt="Urology Hero" />
        <h1>Hormonal Imbalance Treatment</h1>
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Hormonal Imbalance Disorder</h2>
          <img src={require('../images/female11.webp')}alt="Urology Hero" />
         <h3>Basic Homoeopathic Approach</h3>
         <p>
         Homoeopathy treats hormonal imbalances in women by addressing the individual as a whole, considering their physical, mental, and emotional well-being. The goal is to identify and treat the underlying causes of hormonal imbalances rather than merely addressing symptoms. Homoeopathic remedies are selected based on the woman’s unique symptoms and constitution, aiming to restore hormonal balance and enhance overall vitality.
         </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Stress:</h5>Chronic stress affects the hypothalamus and pituitary glands, which regulate hormones. This can lead to imbalances in cortisol, oestrogen, and progesterone.            </li>
            <li><h5>Poor Diet and Lifestyle:</h5>Diets high in sugar, processed foods, and unhealthy fats, along with a sedentary lifestyle, can affect insulin levels and disrupt hormonal balance.            </li>
            <li><h5>Polycystic Ovary Syndrome (PCOS):</h5>A common endocrine disorder that affects the production of hormones like oestrogen and testosterone, leading to irregular cycles and other symptoms.            </li>
            <li><h5>Thyroid Disorders:</h5>Hypothyroidism or hyperthyroidism can lead to imbalances in reproductive hormones, affecting menstrual cycles, mood, and energy levels.            </li>
            <li><h5>Menopause:</h5>The transition to menopause causes fluctuations in oestrogen and progesterone, leading to symptoms like hot flashes, mood swings, and insomnia.            </li>
            <li><h5>Birth Control and Hormonal Therapies:</h5>The use of hormonal contraceptives or therapies can disrupt the body’s natural hormone production and lead to imbalances once discontinued.            </li>
          </ul>
          <h3>Symptoms of Hormonal Imbalance          </h3>
          <ul>
            <li><h5>Irregular Menstrual Cycles:</h5>Short, long, or absent periods, as well as heavy or light bleeding.            </li>
            <li><h5>Mood Swings and Irritability:</h5>Frequent changes in mood, anxiety, and depression can be linked to hormonal fluctuations.            </li>
            <li><h5>Weight Gain and Difficulty Losing Weight:</h5>Especially around the abdomen, due to insulin resistance or cortisol imbalance.            </li>
            <li><h5>Fatigue and Low Energy Levels:</h5>Constant tiredness, lack of motivation, and difficulty staying alert.</li>
            <li><h5>Sleep Disturbances:</h5>Difficulty falling asleep, frequent waking, or poor sleep quality due to hormone fluctuations.            </li>
            <li><h5>Skin and Hair Changes:</h5>Issues like acne, hair thinning, hair loss, or excessive hair growth on the face or body.            </li>
          </ul>
          <h3>Investigations:</h3>
          <ul>
            <li><h5>Blood Tests:</h5>Hormonal assays to measure levels of oestrogen, progesterone, FSH, LH, cortisol, and thyroid hormones.            </li>
            <li><h5>Pelvic Ultrasound:</h5>Imaging to check for conditions like PCOS or fibroids, which may be linked to hormonal imbalances.            </li>
            <li><h5>Saliva Test:</h5>Used to measure hormone levels, particularly cortisol, throughout the day to assess adrenal function.            </li>
            <li><h5>Thyroid Panel:</h5>TSH, Free T4, and Free T3 tests to evaluate thyroid function.            </li>
            <li><h5>DHEA and Testosterone Levels:</h5>Tests for androgens that can influence hormonal balance, particularly in cases of PCOS or adrenal issues.            </li>
          </ul>
          <h3>Complications and Risk Factors:</h3>
          <ul>
            <li><h5>Infertility:</h5>Hormonal imbalances can affect ovulation, making it difficult to conceive.            </li>
            <li><h5>Osteoporosis:</h5>Low oestrogen levels can reduce bone density, increasing the risk of fractures and osteoporosis.            </li>
            <li><h5>Cardiovascular Disease:</h5>Imbalances in oestrogen and progesterone can raise the risk of heart disease, especially after menopause.            </li>
            <li><h5>Metabolic Syndrome:</h5>Hormonal imbalances are often linked to conditions like obesity, high blood pressure, and insulin resistance.            </li>
            <li><h5>Mental Health Issues:</h5>Anxiety, depression, and other mood disorders are frequently linked to hormone fluctuations.            </li>
          </ul>
          <h3>Traditional Basic Treatment:          </h3>
          <ul>
            <li><h5>Hormone Replacement Therapy (HRT):</h5>Used to supplement or replace hormones during menopause or in cases of significant hormonal deficiencies.            </li>
            <li><h5>Oral Contraceptives:</h5>Birth control pills may be prescribed to regulate menstrual cycles and manage symptoms related to hormone imbalances.            </li>
            <li><h5>Supplements:</h5>Vitamins, minerals, and herbal supplements like vitamin D, magnesium, and black cohosh are often used to support hormonal balance.            </li>
            <li><h5>Medications:</h5>Drugs for thyroid imbalances, insulin resistance, or other specific conditions affecting hormone levels.            </li>
            <li><h5>Lifestyle Modifications:</h5>Adopting a healthy diet, regular exercise, stress-reduction techniques, and proper sleep can improve hormonal health.            </li>
          </ul>
          <h3>Homoeopathic Treatment:</h3>
          <ul>
            <li><h5>Sepia:</h5>Often recommended for women experiencing menstrual irregularities, fatigue, mood swings, and symptoms related to menopause.            </li>
            <li><h5>Pulsatilla:</h5>Suitable for women with irregular menstrual cycles, mood fluctuations, and a gentle, yielding disposition.            </li>
            <li><h5>Calcarea Carbonica:</h5>Effective for women with hypothyroid symptoms, including weight gain, fatigue, and menstrual irregularities.            </li>
            <li><h5>Lachesis:</h5>Useful for hot flashes, irritability, and other symptoms associated with menopause or PMS.            </li>
            <li><h5>Ignatia:</h5>Recommended for stress-related hormonal imbalances, particularly when symptoms include anxiety, mood swings, and sensitivity.            </li>
          </ul>
          <h3>Conclusion</h3>
          <p>In our experience, Niramaya homoeopathy can offer best Homoeopathic medicine for female hormonal imbalance & significant relief for women with hormonal imbalances by focusing on the whole person and promoting long-term wellness. Many women report improved menstrual regularity, reduced mood swings, and enhanced energy levels after starting homoeopathic treatment. While homoeopathy may not replace conventional treatments, it serves as a gentle, complementary approach that can help restore balance and support overall health, especially for those seeking natural and less invasive options.          </p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Hormonal Imbalance Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Hormonal Imbalance conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/female-troubles/hormonal-imbalance/hormonal-imbalance-diet">Diet for relief of Hormonal Imbalance disorder</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Hormonal Imbalance </a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Hormonal Imbalance Health</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Hormonal Imbalance Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/rNPy3uf9emc?si=-c88ozAdJNmvbiGf"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
      </div>
  );
};

export default KidneyPage;
