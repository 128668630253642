import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/treat5.webp')}alt="Urology Hero" />
        {/* <h1>Cervical Spondylitis Treatment</h1> */}
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Cervical Spondylitis</h2>
          <img src={require('../images/spinal1.webp')}alt="Urology Hero" />
        <h3> Basic Homoeopathic Approach</h3>
          <p>
          Homoeopathy offers a personalized approach to treating cervical spondylosis, focusing on individual symptoms and overall health. Remedies are selected based on the principle of “like cures like,” aiming to stimulate the body’s self-healing mechanisms. Treatment focuses on reducing pain, stiffness, and inflammation, improving neck mobility, and preventing progression of the condition. It emphasizes holistic care, addressing physical symptoms, emotional well-being, and lifestyle factors to achieve long-term relief and enhance quality of life.          </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Age-related Changes:</h5> As people age, the cervical spine undergoes degenerative changes, including loss of disc elasticity and bone spurs, which contribute to cervical spondylosis.</li>
            <li><h5>Degenerative Disc Disease:</h5>Wear and tear on spinal discs over time, leading to thinning and potential herniation, causing nerve compression and pain.</li>
            <li><h5>Poor Posture:</h5> Incorrect posture, especially with prolonged sitting or poor ergonomics, can strain neck muscles and contribute to cervical spine problems.</li>
            <li><h5>Injuries: </h5>Past neck injuries, such as whiplash from car accidents or sports-related trauma, can accelerate degenerative changes.</li>
            <li><h5>Genetic Factors: </h5>Family history of cervical spine disorders may increase susceptibility to developing cervical spondylosis earlier in life.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li><h5>Medical History and Physical Examination: </h5>Detailed assessment of symptoms, previous injuries, and activities exacerbating or alleviating pain. Physical examination to evaluate neck mobility, tenderness, and neurological signs.</li>
            <li><h5>Imaging Tests:</h5>X-rays provide detailed images of the cervical spine to identify bone spurs, disc degeneration, and alignment issues. MRI scans offer more precise views of soft tissues, including discs and nerves, to assess herniations and nerve compression.</li>
            <li><h5>Electromyography (EMG): </h5>Measures electrical activity in muscles to detect nerve dysfunction and determine the extent of nerve compression.</li>
            <li><h5>Computed Tomography (CT): </h5>Provides cross-sectional images of the spine to assess bone density and structural abnormalities, useful for evaluating bone spurs and spinal canal narrowing.</li>
            <li><h5>Blood Tests:</h5>To rule out other systemic conditions or inflammatory markers that may contribute to neck pain and stiffness.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Chronic Pain and Disability: </h5>Persistent neck pain, stiffness, and limited mobility affecting daily activities and overall quality of life.</li>
            <li><h5>Radicular Symptoms: </h5>Radiating pain, numbness, tingling, or weakness in the arms or hands due to nerve compression in the cervical spine.</li>
            <li><h5>Spinal Cord Compression:</h5>Severe cases may lead to spinal cord compression, causing motor weakness, coordination difficulties, or bowel/bladder dysfunction.</li>
            <li><h5>Reduced Quality of Life:</h5>Impaired ability to perform routine tasks, disrupted sleep patterns, and emotional distress due to chronic pain and physical limitations.</li>
            <li><h5>Risk Factors:</h5>Advanced age, occupations involving repetitive neck movements or heavy lifting, obesity, smoking, and poor posture increase the risk of developing cervical spondylosis.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Medications:</h5>Nonsteroidal anti-inflammatory drugs (NSAIDs), muscle relaxants, and pain relievers to alleviate pain, inflammation, and muscle spasms.</li>
            <li><h5>Physical Therapy:</h5>Exercise programs to strengthen neck muscles, improve flexibility, and correct posture. Manual therapy techniques may include massage, manipulation, and traction to relieve pressure on nerves and improve range of motion.</li>
            <li><h5>Heat and Cold Therapy:</h5>Application of heat packs or ice packs to reduce inflammation and relieve muscle tension in the neck.</li>
            <li><h5>Cervical Traction:</h5>Mechanical traction devices or therapeutic exercises designed to stretch and decompress the cervical spine, easing pressure on discs and nerves.</li>
            <li><h5>Steroid Injections:</h5>Corticosteroid injections directly into the affected area to reduce inflammation and provide temporary relief from severe pain and swelling.</li>
          </ul>
          <h3>Homoeopathic Treatment:</h3>
          <ul>
            <li><h5>Rhus Toxicodendron:</h5>Indicated for stiffness and pain in the neck that worsens with initial movement but improves with continued motion.</li>
            <li><h5>Bryonia Alba:</h5>Suitable for sharp, stitching pains aggravated by movement and alleviated by rest, often associated with inflammation.</li>
            <li><h5>Cimicifuga Racemosa:</h5>Helpful for neck and shoulder pain, particularly in individuals with sensitive spine conditions.</li>
            <li><h5>Kalmia Latifolia:</h5>Addresses shooting pains from the neck down the arms, particularly when symptoms worsen with cold weather.</li> 
            <li><h5>Gelsemium:</h5>Useful for neck muscle weakness and heaviness, often accompanied by dizziness and lethargy.</li>
            </ul>
          <h3>Conclusion</h3>
          <p>Homoeopathic treatment for cervical spondylosis focuses on personalized care and holistic management. By addressing symptoms and underlying causes, Homoeopathy supports the body’s natural healing processes and improves overall well-being. Many patients experience significant relief from pain, improved neck mobility, and enhanced quality of life with consistent Homoeopathic care. Our experience demonstrates that with appropriate remedy selection and patient compliance, Homoeopathy can complement conventional treatments effectively. This approach not only alleviates symptoms but also promotes long-term health benefits, emphasizing individualized treatment and lifestyle adjustments. Homoeopathy offers a gentle and non-invasive alternative, emphasizing holistic care and restoring balance to promote optimal health and well-being.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Cervical Spondylitis Treatment</h3>
            <p>We offer best Homoeopathic medicine for back pain with natural, safe, and effective treatment for Cervical Spondylitis conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/spinal-disease/cervical-spondylitis/cervical-spondylitis-diet">Diet for Cervical Spondylitis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Cervical Spondylitis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Cervical Spondylitis</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Cervical Spondylitis Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/_ASv05AP1so?si=FL1lehL1vBCvn2_m"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
