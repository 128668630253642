import React from 'react';
import '../css/Kidney.css'; // Add custom styles here
const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/sh9.webp')}alt="Urology Hero" />
        <h1>Chloasma / Melasma</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Chloasma / Melasma</h2>
          <img src={require('../images/skin9.webp')}alt="Urology Hero" />
          <p>
          Homoeopathy treats melasma by focusing on the individual’s overall health and considering factors such as physical, mental, and emotional well-being. Remedies are selected based on the individual’s unique symptoms and constitution, aiming to reduce pigmentation, prevent recurrence, and address any underlying hormonal or systemic imbalances. Melasma is often triggered by hormonal factors, so homoeopathic treatment aims to balance hormones and improve skin health naturally.
          </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Sun Exposure:</h5>UV radiation from the sun stimulates melanin production, which can worsen melasma or trigger it in susceptible individuals.            </li>
            <li><h5>Genetic Predisposition:</h5>Family history and certain genetic factors can increase the likelihood of developing melasma.            </li>
            <li><h5>Skin Care Products:</h5>Some cosmetics or skincare products can irritate the skin, leading to melasma, especially when combined with sun exposure.            </li>
            <li><h5>Hormonal Changes:</h5>Hormonal fluctuations, such as those occurring during pregnancy, menopause, or with the use of birth control pills or hormone replacement therapy, are a major trigger for melasma  </li>
            <li><h5>Thyroid Disorders:</h5>There is a link between thyroid disorders and melasma, particularly in women. </li>
            <li><h5>Stress:</h5>Emotional stress and physical stress can disrupt hormone levels, which may contribute to melasma.            </li>
          </ul>
          <h3>Symptoms</h3>
          <ul>
            <li><h5>Brown or Gray-Brown Patches:</h5>The hallmark of melasma is the appearance of flat, dark patches on the skin, typically on the face. These patches may also be tan or grey-brown.            </li>
            <li><h5>Symmetrical Patterns:</h5>Melasma patches are usually symmetrical, appearing on both sides of the face, particularly on the cheeks, forehead, nose, and upper lip.            </li>
            <li><h5>No Itching or Pain:</h5>Unlike other skin conditions, melasma is not typically associated with itching, pain, or other discomfort.            </li>
            <li><h5>Hyperpigmentation on Exposed Areas:</h5>While most common on the face, melasma can occasionally occur on other sun-exposed areas, such as the neck and forearms.            </li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Emotional and Psychological Impact:</h5>Melasma can affect self-esteem, confidence, and mental health, particularly when it affects visible areas like the face.            </li>
            <li><h5>Recurrence:</h5>Melasma can be persistent and often returns, especially if underlying hormonal imbalances or sun exposure are not managed.            </li>
            <li><h5>Increased Sensitivity to Sunlight:</h5>Individuals with melasma may become more sensitive to sunlight, making it essential to use sun protection daily.            </li>
         </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Topical Skin Lightening Agents:</h5>Creams containing hydroquinone, tretinoin, or corticosteroids are commonly prescribed to lighten melasma patches.            </li>
            <li><h5>Chemical Peels:</h5>Superficial peels containing glycolic acid, salicylic acid, or lactic acid can help exfoliate the skin and reduce pigmentation.            </li>
            <li><h5>Laser and Light Therapy:</h5>Certain types of laser therapy or intense pulsed light (IPL) treatments may be used to reduce pigmentation, though results vary.            </li>
            <li><h5>Sun Protection:</h5>Using a broad-spectrum sunscreen with an SPF of at least 30 daily is crucial for preventing melasma from worsening and aiding treatment.            </li>
            <li><h5>Oral Medications:</h5>In some cases, oral medications such as tranexamic acid may be prescribed to manage severe melasma.            </li>
          </ul>
          <h3>Homoeopathic Treatment</h3>
          <ul>
            <li><h5>Thuja Occidentalis:</h5>Recommended for individuals with brown spots on the face, particularly on the cheeks. Thuja is often used for various skin conditions, especially when the person has a history of oily skin or warts.            </li>
            <li><h5>Lycopodium:</h5>Ideal for melasma accompanied by digestive disturbances and flatulence. This remedy is particularly suitable for individuals who experience symptoms worsening in the late afternoon or evening and have a tendency to develop brownish discoloration on the face.            </li>
            <li><h5>Natrum Muriaticum:</h5>Effective for vitiligo associated with emotional stress, grief, or suppressed emotions. This remedy is especially useful for individuals who are introverted, sensitive, and emotionally reserved.            </li>
            <li><h5>Sepia:</h5>Suitable for melasma triggered by hormonal changes, such as pregnancy, menstruation, or menopause. This remedy is ideal for individuals with a tendency to experience brownish patches, particularly on the face. It is also helpful for those with feelings of irritability and indifference </li>
            <li><h5>Berberis Aquifolium:</h5>Known for its action on the skin, Berberis Aquifolium is often used to lighten and clarify skin with dark spots or pigmentation issues. It’s useful for those experiencing melasma due to sun exposure or persistent pigmentation.            </li>
          </ul>
         <h3>Conclusion / Our Experience</h3>
          <p>
          In our experience, homoeopathy offers a gentle and individualised approach to managing melasma by addressing both physical symptoms and emotional well-being. Many individuals report a gradual reduction in pigmentation, better skin tone, and reduced recurrence with consistent homoeopathic treatment. While homoeopathy may not completely eliminate melasma, it serves as an effective complementary approach, especially for those looking for natural and non-invasive options. Homoeopathic remedies help balance the body and can reduce the need for long-term chemical treatments. For the best outcomes, it’s essential to combine homoeopathic treatment with daily sun protection and a healthy lifestyle, as these are key in preventing melasma from worsening.
          </p>
</div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Chloasma / Melasma Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Chloasma / Melasma.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/skin/chloasma-melasma/chloasma-diet">Diet for Chloasma / Melasma prevention</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Chloasma / Melasma</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Chloasma / Melasma</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Chloasma/ Melasma Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/gitqQ9Wb3kg?si=8iHPiQhCGPUbTREQ"
                    title="Infertility Treatment Video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
