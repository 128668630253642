import React from 'react';
import { Link } from 'react-router-dom';
import '../css/CaseStudies.css';
 
const caseStudiesData = [
  {
    title: 'Overcoming Kidney Stones Naturally',
    description: 'Exploring Homoeopathic remedies for the natural expulsion of kidney stones.',
    image: '1.webp',
    link: '/case-studies/case-kidney'
  },
  {
    title: 'Healing Urethral Stricture with Homoeopathy',
    description: 'Overcoming urethral stricture with gentle Homoeopathic treatments.',
    image: '21.webp',
    link: '/case-studies/case-urethral'
  },
  {
    title: 'Restoring Hair Health: A Solution for Alopecia',
    description: 'Revitalizing hair growth and health through personalized Homoeopathic care.',
    image: '2.webp',
    link: '/case-studies/case-alopecia'
  },
  {
    title: 'Easing Arthritis Pain with Holistic Care',
    description: 'Alleviating joint pain and inflammation with Homoeopathic solutions.',
    image: '4.webp',
    link: '/case-studies/case-arthritis'
  },
  {
    title: 'Revitalizing Skin Health Through Homoeopathy',
    description: 'Achieving glowing, healthy skin with targeted Homoeopathic treatments.',
    image: '5.webp',
    link: '/case-studies/case-skin'
  },
  {
    title: 'Managing PCOD Symptoms Effectively',
    description: 'Homoeopathic interventions to balance hormones and address PCOD.',
    image: '6.webp',
    link: '/case-studies/case-hormones'
  },
  {
    title: 'Finding Lasting Relief from Migraines',
    description: 'Long-term relief from migraines through individualized Homoeopathic care.',
    image: '5.webp',
    link: '/case-studies/case-migraine'
  },
  {
    title: 'Enhancing Fertility Through Natural Remedies',
    description: 'Boosting fertility naturally with holistic Homoeopathic approaches.',
    image: '9.webp',
    link: '/case-studies/case-fertility'
  },
  {
    title: 'Homoeopathic Care for Chronic ENT Conditions',
    description: 'Treating chronic ENT problems like sinusitis and tonsillitis with Homoeopathy.',
    image: '10.webp',
    link: '/case-studies/case-ent'
  },
  {
    title: 'Improving Quality of Life in Old Age',
    description: 'Managing geriatric health challenges with safe and effective Homoeopathic care.',
    image: '11.webp',
    link: '/case-studies/case-old-age'
  },
  {
    title: 'Balancing Female Health with Homoeopathy',
    description: 'Addressing female reproductive health issues through Homoeopathy.',
    image: '12.webp',
    link: '/case-studies/case-female'
  },
  {
    title: 'Homoeopathic Solutions for Male Reproductive Health',
    description: 'Improving male reproductive health with Homoeopathic treatment for varicocele.',
    image: '13.webp',
    link: '/case-studies/case-male'
  },
];
const CaseStudies = () => {
  return (
    <div className="case-studies-page">
      <div className="case-hero-section">
      <div className='casestudy-new'>
      <h1>Case Study</h1>
      <h2>Help us improve by sharing your experience </h2>
      <h2>with Niramaya Homoeopathy</h2>
      </div>
        {/* <img src={require('../images/case_hero.jpg')} alt="Hero" className="case-hero-img" /> */}
      </div>
      <div className="case-studies-container">
        {caseStudiesData.map((caseStudy, index) => (
          <div className="case-study-card" key={index}>
            <img src={require(`../images/${caseStudy.image}`)} alt={caseStudy.title} className="cases-study-img" />
            <div className="case-study-content">
              <h3>{caseStudy.title}</h3>
              <p>{caseStudy.description}</p>
              <Link to={caseStudy.link} className="learn-more-btn mb-1">Learn More</Link>
            </div>
          </div>
        ))}
      </div>
      <div className="contact-map-row">
        <div className="map-card">
          <h3>Our Location</h3>
          <iframe
            title="Niramaya Homoeopathy Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d114008.58764245966!2d83.31226966300316!3d26.751766994497036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39914486bfb2c0e9%3A0xaf54983a8579801c!2sDr%20Atul%20kumar%20singh%20(BHMS%2C%20MD%2C%20PG%20London)%20Homeopath!5e0!3m2!1sen!2sin!4v1728539308354!5m2!1sen!2sin"
            width="100%"
            height="250"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
 
        <div className="contact-us-card">
          <h3>Contact Us</h3>
          <h6>For inquiries and appointments, please contact us:</h6>
          <p><strong>Phone:</strong><a href="tel:+919236185711" className="contact-link">+919236185711</a>          </p>
          <p><strong>Email:</strong><a href="mailto:niramayaforyou@gmail.com" className="contact-link">niramayaforyou@gmail.com</a> 
          </p>
          <p><strong>Address:</strong> 0230 B Civil Lines 2 Bilandpur near DIG Bunglow, Gorakhpur, Uttar Pradesh, India, 273001</p>
        </div>
      </div>
    </div>
  );
};
 
export default CaseStudies;