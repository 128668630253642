import React from 'react';
import '../css/Kidney.css'; // Add custom styles here
const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/sh2.webp')}alt="Urology Hero" />
        <h1>Lichen planus</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Lichen planus</h2>
          <img src={require('../images/skin2.webp')}alt="Urology Hero" />
          <p>
          Homoeopathy treats lichen planus by considering the individual’s overall health, including their physical, mental, and emotional state. Remedies are selected based on the specific symptoms and constitution of the patient, aiming to alleviate itching, reduce inflammation, promote skin healing, and support the immune system. Lichen planus is often chronic, so homoeopathic treatment focuses on long-term relief, reducing recurrence, and improving quality of life.
          </p>
          <h3>Causes of Molluscum Contagiosum</h3>
          <ul>
            <li><h5>Autoimmune Reaction:</h5>Lichen planus is believed to be an autoimmune disorder, meaning the immune system attacks healthy cells in the skin and mucous membranes.            </li>
            <li><h5>Genetic Predisposition:</h5>A family history of autoimmune diseases or skin conditions may increase the risk of developing lichen planus.            </li>
            <li><h5>Medications:</h5>Certain medications, including NSAIDs, beta-blockers, and antimalarials, can trigger lichen planus in some individuals.            </li>
            <li><h5>Hepatitis C Infection:</h5>People with hepatitis C have a higher risk of developing lichen planus, although not everyone with hepatitis C will develop it.            </li>
            <li><h5>Allergic Reactions:</h5>Allergic reactions to metals such as gold, amalgam fillings, or other dental materials may trigger lichen planus in the mouth.            </li>
            <li><h5>Stress:</h5>Emotional stress or physical trauma may aggravate lichen planus, particularly in individuals with a predisposition to autoimmune conditions.            </li>
          </ul>
          <h3>Symptoms of Molluscum Contagiosum</h3>
          <ul>
            <li><h5>Skin Lesions:</h5>Itchy, flat, polygonal lesions that are purple or reddish-purple in colour. They may have fine white lines called Wickham’s striae on their surface.            </li>
            <li><h5>Oral Lesions:</h5>White, lacy patches or painful sores in the mouth, often on the inner cheeks, tongue, or gums.            </li>
            <li><h5>Genital Lesions</h5>Itchy, painful lesions on the genital area, often accompanied by discomfort or burning.            </li>
            <li><h5>Nail Changes:</h5>Lichen planus can cause thinning, ridges, splitting, or even loss of nails.            </li>
            <li><h5>Scalp Involvement:</h5>When the scalp is affected, it can lead to hair loss and permanent scarring, a condition known as lichen planopilaris.            </li>
            <li><h5>Itching and Discomfort:</h5>The lesions are often itchy and may cause significant discomfort, particularly when they affect the mucous membranes.            </li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Infection:</h5>Scratching the lesions can lead to secondary bacterial infections, especially on the skin.            </li>
            <li><h5>Scarring and Hyperpigmentation:</h5>Once the lesions heal, they may leave behind darkened patches or scars, particularly on darker skin tones.            </li>
            <li><h5>Increased Risk of Cancer:</h5>Chronic lichen planus, particularly when it affects the mouth or genitals, may increase the risk of developing squamous cell carcinoma.            </li>
            <li><h5>Discomfort and Quality of Life:</h5>Persistent itching, pain, and discomfort can affect sleep, mental health, and overall quality of life.            </li>
         </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Topical Corticosteroids:</h5>Creams or ointments containing corticosteroids are often prescribed to reduce inflammation and relieve itching.            </li>
            <li><h5>Oral Medications: </h5>In severe cases, oral corticosteroids, immunosuppressants, or retinoids may be prescribed to control symptoms.            </li>
            <li><h5>Antihistamines:</h5>These are often used to alleviate itching and improve sleep.            </li>
            <li><h5>Phototherapy:</h5>UV light therapy may be used for widespread skin lesions, particularly if other treatments are ineffective.            </li>
            <li><h5>Mouthwashes and Gels:</h5>For oral lichen planus, medicated mouthwashes, corticosteroid gels, or local anaesthetics can reduce pain and discomfort.            </li>
          </ul>
          <h3>Homoeopathic Treatment</h3>
          <ul>
            <li><h5>Arsenicum Album</h5>Effective for individuals with burning pain, dry, scaly skin, and intense itching that worsens at night. This remedy is suitable for those who may also experience anxiety, restlessness, and exhaustion.            </li>
            <li><h5>Graphites:</h5>Recommended for thick, dry, itchy lesions that may ooze a sticky discharge. This remedy is particularly helpful for lesions in the folds of the skin, such as the groyne or behind the ears.            </li>
            <li><h5>Sulphur:</h5>Ideal for individuals with severe itching, burning, and red, inflamed skin. The itching tends to worsen with heat and scratching. Suitable for people who often feel warm and have a tendency to dry, scaly skin conditions   </li>
            <li><h5>Natrum Muriaticum:</h5>Useful for lesions that are itchy, painful, and prone to cracking. This remedy is beneficial for those with emotional triggers, particularly those who suppress grief or stress.            </li>
            <li><h5>Rhus Toxicodendron:</h5>Effective for individuals with red, swollen, and intensely itchy lesions that feel worse with rest and better with movement. This remedy is also suitable for cases where cold weather aggravates symptoms.            </li>
          </ul>
         <h3>Conclusion / Our Experience</h3>
          <p>
          In our experience, homoeopathy offers a gentle and individualised approach for managing lichen planus by addressing underlying causes, reducing symptoms, and supporting long-term relief. Many individuals report less itching, reduced inflammation, and improved healing with consistent homoeopathic treatment. While lichen planus can be persistent, homoeopathic remedies aim to provide relief without the side effects associated with long-term use of conventional medications. For those seeking natural and holistic options, homoeopathy serves as a valuable complementary approach that can improve overall quality of life and prevent recurrence. However, it’s essential to work with a qualified healthcare provider for comprehensive care.
          </p>
</div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Lichen planus Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Lichen planus.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/skin/lichen-planus/lichen-planus-diet">Diet for Lichen planus prevention</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Lichen planus</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Lichen planus</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Lichen Planus Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/_hGVqa0cKTw?si=IA9k3NOqMJdlh9Cs"
                    title="Infertility Treatment Video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
