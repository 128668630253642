import React from 'react';
import '../css/Kidney.css'; // Add custom styles here
const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/sh1.webp')}alt="Urology Hero" />
        <h1>Molluscum contagiosum</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Molluscum contagiosum</h2>
          <img src={require('../images/skin1.webp')}alt="Urology Hero" />
          <p>
          Homoeopathy treats molluscum contagiosum by focusing on the person’s overall immune response and individual symptoms. Remedies are selected based on the patient’s unique constitution, aiming to boost the immune system, relieve symptoms, and reduce the duration of the infection. Since molluscum contagiosum is a viral infection, homoeopathy can help support the body’s natural healing process and prevent recurrence, particularly in children and individuals with a weakened immune system.
         </p>
          <h3>Causes of Molluscum Contagiosum</h3>
          <ul>
            <li><h5>Direct Skin Contact:</h5>The virus is transmitted through direct contact with an infected person’s skin or with contaminated objects like towels, clothing, or toys.            </li>
            <li><h5>Weakened Immune System:</h5>Individuals with weakened immune systems (such as those with HIV/AIDS or those undergoing immunosuppressive treatments) are more susceptible to molluscum contagiosum.            </li>
            <li><h5>Sexual Contact:</h5>Molluscum contagiosum can be spread through sexual contact, leading to lesions in the genital area in adults.            </li>
            <li><h5>Public Spaces:</h5>Exposure to the virus in public places, such as swimming pools, gyms, or communal showers, can increase the risk of infection.            </li>
            <li><h5>Sharing Personal Items:</h5>Sharing towels, razors, or other personal items with someone who has molluscum contagiosum can lead to the spread of the virus.            </li>
          </ul>
          <h3>Symptoms of Molluscum Contagiosum</h3>
          <ul>
            <li><h5>Small, Raised Bumps:</h5>The bumps are typically pink or flesh-coloured, smooth, and have a characteristic dimple or indentation in the center. They range from 2-5 mm in diameter.            </li>
            <li><h5>Itching or Irritation: </h5>While generally painless, some individuals may experience itching or mild irritation around the bumps.            </li>
            <li><h5>Spread to Other Areas:</h5>The bumps can spread to different areas of the body through scratching, shaving, or contact with contaminated items.            </li>
            <li><h5>Clusters of Lesions:</h5>Lesions often appear in clusters on the arms, legs, torso, face, or genital area, depending on the method of transmission.            </li>
            <li><h5>Inflammation:</h5>Occasionally, the bumps may become red or inflamed as the body’s immune system begins to fight off the virus.            </li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Secondary Infections:</h5>Scratching the lesions can introduce bacteria, leading to a secondary bacterial infection.            </li>
            <li><h5>Widespread Lesions in Immunocompromised Individuals:</h5>People with weakened immune systems may develop larger and more widespread lesions that are resistant to treatment.            </li>
            <li><h5>Scarring:</h5>Picking or scratching the lesions can result in scarring once the bumps heal.            </li>
            <li><h5>Eczema:</h5>Some individuals with molluscum contagiosum may develop eczema around the infected areas, leading to further skin irritation.            </li>
         </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Cryotherapy:</h5>Freezing the lesions with liquid nitrogen to destroy the virus, allowing the bumps to fall off within a few weeks.            </li>
            <li><h5>Curettage: </h5>A minor surgical procedure where the bumps are scraped off with a sharp instrument.</li>
            <li><h5>Topical Treatments:</h5>Prescription creams or ointments, such as those containing salicylic acid or imiquimod, may be applied to the lesions to help speed up resolution.            </li>
            <li><h5>Laser Therapy:</h5>Lasers can be used to remove persistent or large lesions, particularly for cosmetic purposes.            </li>
          </ul>
          <h3>Homoeopathic Treatment</h3>
          <ul>
            <li><h5>Thuja Occidentalis:</h5>One of the most common remedies for skin growths, including molluscum contagiosum. Suitable for individuals with warts, skin tags, and molluscum, particularly if the bumps are fleshy, smooth, and painless.            </li>
            <li><h5>Calcarea Carbonica:</h5>Recommended for children and adults with a tendency toward skin infections, slow healing, and a constitution that is prone to recurrent infections. Ideal for individuals who are chilly, fatigued, and prone to excessive sweating.            </li>
            <li><h5>Sulphur:</h5>Beneficial for itchy, irritated, or inflamed skin. It is suitable for individuals who experience itching and redness around the bumps and have warm, sweaty skin.            </li>
            <li><h5>Antimonium Crudum:</h5>Effective for molluscum contagiosum when the bumps are smooth and flesh-coloured, particularly if the skin is prone to calluses and cracks. This remedy is often suitable for children with skin issues.            </li>
            <li><h5>Hepar Sulphuris Calcareum:</h5>Suitable for cases where the lesions are painful or tend to become infected easily. It is ideal for individuals who are sensitive to touch and prone to secondary infections.            </li>
          </ul>
         <h3>Conclusion / Our Experience</h3>
          <p>
          In our experience, homoeopathy can offer supportive relief for individuals with molluscum contagiosum by addressing the underlying immune response and promoting faster recovery. Many patients report reduced symptoms, less itching, and improved healing with consistent homoeopathic treatment. While molluscum contagiosum often resolves on its own, homoeopathy can be beneficial for managing symptoms and preventing recurrence, particularly in individuals prone to viral skin infections. For persistent or widespread cases, homoeopathic remedies can be a gentle, natural option alongside conventional treatments, providing a holistic approach to healing.
          </p>
</div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Molluscum contagiosum Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Molluscum contagiosum.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/skin/molluscum-contagiosum/molluscum-contagiosum-diet">Diet for Molluscum contagiosum prevention</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Molluscum contagiosum</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Molluscum contagiosum</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Molluscum contagiosum Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/-ONFwsO_-L8?si=HBsb7IdRCvg28vX9"
                    title="Infertility Treatment Video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
