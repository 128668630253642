import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/eh6.webp')}alt="Urology Hero" />
        <h1>Ear Perforation Treatment</h1>
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Ear Perforation</h2>
          <img src={require('../images/ent6.webp')}alt="Urology Hero" />
        <h3> Basic Homoeopathic Approach</h3>
          <p>
          Homoeopathy aims to address the underlying factors contributing to OME, such as allergies, sinus issues, or immune system imbalances, while promoting drainage and restoring normal ear function.
          </p>
          <h3>Causes</h3>
          <p>OME can be caused by allergies, sinus infections, respiratory infections, Eustachian tube dysfunction (which prevents proper drainage of fluid from the middle ear), and sometimes by changes in air pressure.</p>
          <h3>Investigations</h3>
          <p>Diagnosis involves otoscopic examination to visualize the middle ear, tympanometry to assess middle ear function and fluid levels, and audiometry to evaluate hearing loss if present.</p>
          <h3>Complications and Risk Factors</h3>
          <p>Complications may include hearing loss, speech delays in children (due to impaired hearing during critical developmental stages), and recurrent ear infections.</p>
          <h3>Traditional Basic Treatment</h3>
          <p>Conventional treatment options often include watchful waiting (as many cases resolve on their own), antibiotics if infection is present, nasal decongestants, and in some cases, surgical intervention (like inserting ear tubes) to aid drainage.</p>
          <h3>Homoeopathic Supportive Care</h3>
         <p>Homoeopathic remedies are selected based on individual symptoms and constitution. Common remedies for OME may include Pulsatilla, Calcarea carbonica, Kali bichromicum, Silicea, and Natrum sulphuricum. These remedies aim to reduce inflammation, promote drainage, and strengthen the immune system.</p>
          <h3>Conclusion</h3>
          <p>Homoeopathy offers a holistic approach to managing OME, focusing on long-term health by addressing the root causes and enhancing the body’s innate ability to heal.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Ear Perforation Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Ear Perforation conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/ent/ear-perforation/ear-perforation-diet">Diet for Ear Perforation</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Ear Perforation</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Ear Perforation</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Ear Perforation Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/xG4c1BGzaKM?si=0OHm6656FKEqr97y"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
