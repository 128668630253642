import React from 'react';
import '../css/Kidney.css'; // Add custom styles here
const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/gh1.webp')}alt="Urology Hero" />
        <h1>Constipation</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Constipation</h2>
          <img src={require('../images/gastric1.webp')}alt="Urology Hero" />
        <h3> Basic Homoeopathic Approach</h3>
        <ul>
            <li>Homoeopathy views constipation as a disturbance in the body’s natural balance, often involving the digestive system and overall vitality.</li>
            <li>Treatment aims to restore normal bowel function by addressing underlying causes and symptoms holistically.</li>
            <li>Remedies are selected based on individual symptoms, bowel habits, and overall constitution of the person.</li>
          </ul>
          <h3>Causes</h3>
          <ul>
            <li>Causes of constipation can include inadequate fiber intake, insufficient fluid intake, lack of physical activity, and dietary factors.</li>
            <li>Certain medications, hormonal changes, stress, and underlying medical conditions (like hypothyroidism or irritable bowel syndrome) can also contribute.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li>Diagnostic evaluation includes assessing bowel habits, dietary history, and lifestyle factors.</li>
            <li>Physical examination may reveal abdominal tenderness or signs of dehydration.</li>
            <li>Homeopaths consider the overall health and emotional state of the individual in their assessment.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li>Complications of chronic constipation may include hemorrhoids, anal fissures, rectal prolapse, or fecal impaction.</li>
            <li>Risk factors include age (elderly more prone), sedentary lifestyle, certain medications, and underlying medical conditions affecting bowel function.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li>Conventional treatments include dietary modifications (increased fiber intake), adequate hydration, and regular exercise.</li>
            <li>Laxatives or stool softeners may be prescribed in some cases.</li>
            <li>Addressing underlying medical conditions contributing to constipation is essential for effective management.</li>
          </ul>
          <h3>Homoeopathic Supportive Care</h3>
          <ul>
            <li>Individualized remedies such as Nux vomica, Bryonia, Lycopodium, or Alumina may be recommended based on symptoms.</li>
            <li>Remedies are chosen to stimulate normal bowel function, relieve discomfort, and improve overall digestive health.</li>
            <li>Emphasis on lifestyle modifications, including dietary adjustments (increasing fiber-rich foods), adequate hydration, and regular physical activity.</li>
          </ul>
          <h3>Conclusion / Our Experience</h3>
          <p>Homoeopathy provides a holistic approach to managing constipation, focusing on restoring normal bowel function and improving overall well-being. It can complement conventional treatments but should be supervised by a qualified Homoeopathic practitioner. Personalized treatment plans, including dietary and lifestyle recommendations, are crucial for long-term management and prevention of constipation-related complications. Regular monitoring and adjustment of treatment are important to address the unique needs of each individual experiencing constipation.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Constipation Treatment</h3>
            <p>We offer natural, safe, and effective treatment & Homoeopathic medicine for constipation and piles.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/gastric-issues/constipation/constipation-diet">Diet for preventing Constipation</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention from Constipation</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for relief of Constipation</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Constipation Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/k7yunrtiPMk?si=UVQrbd9jeY3tIjCj"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
