import React from 'react';
import '../css/Kidney.css'; // Add custom styles here
const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/sh8.webp')}alt="Urology Hero" />
        <h1>Nails Disorder</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Nails Disorder</h2>
          <img src={require('../images/skin8.webp')}alt="Urology Hero" />
          <p>
          Homoeopathy treats nail disorders by addressing the underlying causes, which may be related to physical health, nutritional deficiencies, infections, or systemic conditions. Remedies are selected based on the individual’s specific symptoms and overall constitution, aiming to improve nail health, promote healing, and support the body’s natural defences. Nail disorders can be stubborn and recurring, so homoeopathic treatment focuses on long-term improvement and prevention of future issues.
          </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Nutritional Deficiencies:</h5>Lack of essential nutrients, including vitamins (such as B vitamins), minerals (zinc, iron, calcium), and protein, can weaken nails.            </li>
            <li><h5>Infections:</h5>Bacterial, fungal, and viral infections can affect the nails, leading to changes in color, thickness, and texture.            </li>
            <li><h5>Injury or Trauma:</h5>Physical injury to the nail or nail bed can result in discoloration, ridges, or detachment from the nail bed.            </li>
            <li><h5>Systemic Conditions:</h5>Diseases like diabetes, psoriasis, eczema, and thyroid disorders can impact nail health.            </li>
            <li><h5>Environmental Factors:</h5>Frequent exposure to water, harsh chemicals, or nail cosmetics can lead to brittle nails.            </li>
            <li><h5>Medications:</h5>Certain drugs, such as chemotherapy agents, antibiotics, and antimalarial medications, can affect nail growth and health.            </li>
          </ul>
          <h3>Symptoms of Molluscum Contagiosum</h3>
          <ul>
            <li><h5>Discoloration:</h5>Nails may become yellow, white, green, or brown, depending on the underlying condition.  </li>
            <li><h5>Thickening or Thinning:</h5>Nails can thicken due to fungal infections or psoriasis, or become thin and brittle due to nutritional deficiencies.            </li>
            <li><h5>Brittleness and Splitting:</h5>Nails that easily crack, split, or break, indicating possible deficiencies or overexposure to water.            </li>
            <li><h5>Changes in Shape or Texture:</h5>Ridges, pitting, grooves, or spoon-shaped nails may indicate underlying health issues.            </li>
            <li><h5>Pain and Inflammation:</h5>Ingrown nails and infections can cause pain, redness, and swelling around the nail bed.            </li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Psychological Impact:</h5>Vitiligo can lead to feelings of self-consciousness, anxiety, and depression, particularly if it affects visible areas of the body.            </li>
            <li><h5>Sunburn and Skin Cancer:</h5>Depigmented areas are more susceptible to sunburn, which increases the risk of skin damage and potentially skin cancer.            </li>
            <li><h5>Hearing Loss:</h5>Some studies suggest an association between vitiligo and hearing loss due to melanin-related cells in the inner ear.            </li>
            <li><h5>Eye Problems:</h5>There may be an increased risk of inflammation in the eyes, as melanin is also present in the retina.            </li>
         </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Antifungal Medications:</h5>Topical or oral antifungal treatments are commonly prescribed for fungal nail infections.            </li>
            <li><h5>Antibiotics:</h5>For bacterial infections like paronychia, antibiotics may be necessary to reduce inflammation and eliminate the infection.            </li>
            <li><h5>Surgical Intervention:</h5>In severe cases of ingrown nails or chronic infections, partial or complete nail removal may be recommended.            </li>
            <li><h5>Nail Care and Hygiene:</h5>Regular trimming, moisturising, and protecting nails from harsh chemicals can help prevent nail disorders.            </li>
            <li><h5>Nutritional Supplements:</h5>Vitamins, minerals, and protein supplements may be advised to strengthen nails and promote healthy growth.            </li>
          </ul>
          <h3>Homoeopathic Treatment</h3>
          <ul>
            <li><h5>Silicea:</h5>Suitable for brittle nails that easily break, especially if they are weak, soft, or ridged. It is also effective for fungal infections and chronic inflammation around the nails.            </li>
            <li><h5>Graphites:</h5>Recommended for thickened, deformed, and rough nails, often accompanied by dry, cracked skin. It is particularly beneficial for fungal infections and eczema around the nails.            </li>
            <li><h5>Thuja Occidentalis:</h5>Effective for warts or growths on or around the nails, as well as brittle or crumbling nails. It is also used for cases of nail discoloration and fungal infections.            </li>
            <li><h5>Antimonium Crudum:</h5>Known for treating brittle, split, and discoloured nails, particularly in individuals prone to calluses and skin thickening. It is helpful for nails that tend to split vertically.            </li>
            <li><h5>Fluoricum Acidum:</h5>Suitable for distorted, misshapen, and crumbling nails. It is particularly helpful for individuals with onycholysis (nail detachment) and nail deformities.            </li>
            <li><h5>Calcarea Fluorica:</h5>Recommended for individuals with hard, brittle nails that crack or peel. It is beneficial for nail growth and strengthening the nail bed.            </li>
            <li><h5>Sulphur:</h5>Effective for inflamed, painful, and itchy skin around the nails. It is beneficial for those with dry, scaly skin and a tendency toward fungal infections.            </li>
          </ul>
         <h3>Conclusion / Our Experience</h3>
          <p>
          In our experience, homoeopathy offers a gentle and individualised approach to managing nail disorders by addressing the root causes and supporting long-term nail health. Many individuals report improved nail strength, reduced inflammation, and fewer infections with consistent homoeopathic treatment. Homoeopathic remedies provide a natural alternative to conventional treatments, especially for those seeking to avoid the side effects of long-term medication. By focusing on the whole person, homoeopathy aims to promote balanced health and prevent recurring nail issues. Working with a qualified healthcare provider can ensure the best outcomes and a comprehensive approach to nail health.
          </p>
</div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Nails Disorder Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Nails Disorder.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/skin/nails-disorder/nails-disorder-diet">Diet for Nails Disorder prevention</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Nails Disorder</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Nails Disorder</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Nails Disorder Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/c-z6QSYrVU8?si=AlbMGbGyJKY0KQ4z"
                    title="Infertility Treatment Video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
