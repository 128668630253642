import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {

  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/eh9.webp')}alt="Urology Hero" />
        <h1>Meniere’s disease Treatment</h1>
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Meniere’s disease</h2>
          <img src={require('../images/ent9.webp')}alt="Urology Hero" />
        <h3> Basic Homoeopathic Approach</h3>
        <ul>
            <li><h5>Homoeopathic Approach:</h5>Homoeopathy aims to address the underlying imbalances in the body, such as disturbances in fluid regulation and stress responses. Treatment focuses on reducing the frequency and intensity of vertigo attacks, improving inner ear function, and alleviating associated symptoms like tinnitus and hearing loss.</li>
            <li><h5>Common Remedies:</h5>Remedies such as Gelsemium, Conium, Natrum salicylicum, China, Lycopodium, and Arsenicum album are often used. These are selected based on individual symptoms and constitutional characteristics.</li>
          </ul>
          <h3>Causes</h3>
          <ul>
            <li> The exact cause of Meniere’s disease is not fully understood. It is believed to involve factors such as fluid buildup in the inner ear (endolymphatic hydrops), autoimmune responses, genetic predisposition, and environmental triggers.</li>
          </ul>
          <h3>Symptoms</h3>
          <h3>Meniere’s disease is characterized by:</h3>
          <ul>
            <li><h5>Vertigo: </h5>Severe spinning sensation, often accompanied by nausea and vomiting.</li>
            <li><h5>Fluctuating Hearing Loss:  </h5>Typically affects one ear initially, with episodes of hearing impairment.</li>
            <li><h5>Tinnitus:</h5>Ringing, buzzing, or roaring sound in the affected ear.</li>
            <li><h5>Aural Fullness:</h5>Sensation of pressure or fullness in the ear.</li>
          </ul>
          <h3>Investigation:</h3>
          <h3>Diagnosis: Diagnosis involves:</h3>
          <ul>
            <li>Detailed medical history and physical examination.</li>
            <li>Audiometry (hearing tests) to assess hearing loss.</li>
            <li>Vestibular function tests (e.g., caloric testing, electronystagmography) to evaluate balance function.</li>
            <li>Imaging studies (MRI) may be used to rule out other conditions.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <h3>Meniere’s disease can lead to significant complications, including:</h3>
          <ul>
            <li>Permanent hearing loss in the affected ear.</li>
            <li>Chronic imbalance and increased risk of falls.</li>
            <li>Emotional stress and anxiety due to unpredictable vertigo attacks.</li>
            <li><h5>Risk Factors:</h5>Risk factors may include family history of Meniere’s disease, autoimmune conditions, and exposure to certain environmental triggers (e.g., allergens, stress).</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Medications:</h5>Anti-vertigo medications (e.g., betahistine), diuretics (to reduce fluid buildup), and antiemetics (for nausea).</li>
            <li><h5>Dietary Changes:</h5>Low-sodium diet to manage fluid retention.</li>
            <li><h5>Vestibular Rehabilitation:</h5>Exercises to improve balance and reduce symptoms.</li>
            <li><h5>Surgical Options: </h5> In severe cases, surgical procedures such as endolymphatic sac decompression or vestibular nerve section may be considered to relieve symptoms.</li>
          </ul>
          <h3>Homoeopathic Supportive Care</h3>
          <ul>
            <li>Remedies are chosen based on individual symptoms and constitutional characteristics. The goal is to address the root causes of imbalance and enhance the body’s natural healing mechanisms. Remedies like Gelsemium, Conium, Natrum salicylicum, China, Lycopodium, and Arsenicum album are commonly used to manage vertigo, improve inner ear function, and support overall health.</li>
            </ul>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Meniere’s disease Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Meniere’s disease conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/ent/meniere-disease/meniere-disease-diet">Diet for Meniere’s disease</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Meniere’s disease</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Meniere’s disease</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Meniere’s Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/xG4c1BGzaKM?si=0OHm6656FKEqr97y"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
