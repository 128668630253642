import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {

  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/treat4.webp')}alt="Urology Hero" />
        {/* <h1>Osteoporosis Treatment</h1> */}
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Osteoporosis</h2>
          <img src={require('../images/joint5.webp')}alt="Urology Hero" />
       <p>Osteoporosis is a condition that weakens bones, making them more prone to fractures (broken bones). Often called a “silent disease” because there are typically no symptoms until a fracture occurs, osteoporosis can significantly impact mobility and quality of life. Here’s a breakdown to help you understand it better:</p>
          <h3>Causes</h3>
          <p>Osteoporosis develops when the creation of new bone doesn’t keep pace with the breakdown of old bone. This can happen due to several factors:</p>
          <ul>
            <li><h5>Age:</h5> As we age, bone density naturally decreases. People over 65 are at higher risk.</li>
            <li><h5>Sex:</h5>Women are more prone to osteoporosis after menopause due to hormonal changes.</li>
            <li><h5>Hormonal Imbalances:</h5>Low levels of estrogen (in women) and testosterone (in men) can contribute to bone loss.</li>
            <li><h5>Family History:</h5>Having a close relative with osteoporosis increases your risk.</li>
            <li><h5>Diet:</h5>Deficiency in calcium and vitamin D, essential for bone health, can contribute to osteoporosis.</li>
            <li><h5>Certain Medical Conditions:</h5>Conditions like rheumatoid arthritis, celiac disease, and some thyroid disorders can increase the risk.</li>
            <li><h5>Medications:</h5>Long-term use of corticosteroids or certain medications for epilepsy or prostate cancer can weaken bones.</li>
            <li><h5>Lifestyle Factors:</h5>Lack of physical activity, smoking, and excessive alcohol consumption can contribute to bone loss.</li>

          </ul>
          <h3>Symptoms </h3>
          <p>Osteoporosis itself usually doesn’t cause any symptoms. However, the first sign may be a fracture, often in the hip, wrist, or spine, that occurs from a minor fall or bump.</p>
          <h3>Investigations </h3>
          <p>Diagnosis of osteoporosis typically involves:</p>
          <ul>
            <li><h5>Dual-energy X-ray absorptiometry (DXA scan): </h5>This painless test measures bone mineral density to assess the risk of fracture.</li>
            <li>In some cases, blood tests may be done to rule out other conditions that can cause bone loss.</li>
          </ul>
          <h3>Complications</h3>
          <p>Fractures are the most significant complication of osteoporosis. They can lead to:</p>
          <ul>
            <li><h5>Pain and disability:</h5> Fractures can cause significant pain and make it difficult to perform daily activities.</li>
            <li><h5>Loss of independence:</h5>Severe fractures, especially in the hip, can lead to a loss of independence and require long-term care.</li>
            <li><h5>Increased mortality risk:</h5> Hip fractures can be particularly serious, with an increased risk of complications and mortality.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <p>There is no cure for osteoporosis, but treatment focuses on preventing fractures and maintaining bone strength. This may involve:</p>
          <ul>
            <li><h5>Medications: </h5>Bisphosphonates, denosumab, and other medications can help slow bone loss and increase bone density.</li>
            <li><h5>Calcium and Vitamin D Supplements:</h5>Ensuring adequate intake of these nutrients is crucial for bone health.</li>
            <li><h5>Lifestyle Modifications: </h5>Regular weight-bearing exercise, a healthy diet rich in calcium and vitamin D, and quitting smoking can all help maintain bone health.</li>
            <li><h5>Physical Therapy: </h5>Exercises to improve balance and strength can help reduce the risk of falls.</li>
          </ul>
          <h3>Conclusion</h3>
          <p>Osteoporosis is a serious condition, but early diagnosis and treatment can significantly reduce the risk of fractures and improve quality of life. If you have risk factors for osteoporosis, talk to your doctor about bone density testing and preventive measures. Remember, I cannot recommend Homoeopathic treatments as they lack scientific backing.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Osteoporosis Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Osteoporosis conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/joint-bone/osteoporosis/osteoporosis-diet">Diet for Osteoporosis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Osteoporosis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Osteoporosis</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Osteoporosis Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/MWP5BRTWCGU?si=xsjNb0P0DTmUUf2p"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
