import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/treat5.webp')}alt="Urology Hero" />
        {/* <h1>Lumbar spondylosis Treatment</h1> */}
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Lumbar spondylosis</h2>
          <img src={require('../images/spinal2.webp')}alt="Urology Hero" />
        <h3> Basic Homoeopathic Approach</h3>
          <p>
          Homoeopathy offers a tailored approach to treating lumbar spondylitis, focusing on individual symptoms, overall health, and the root causes of the condition. Remedies are chosen based on the principle of “like cures like,” aiming to stimulate the body’s innate healing abilities. Treatment aims to alleviate pain, reduce inflammation, restore mobility, and prevent further progression. It emphasizes holistic care, addressing physical symptoms, emotional well-being, and lifestyle factors to achieve sustainable relief and improve quality of life.          </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Degenerative Changes:</h5>Gradual wear and tear on the lumbar spine over time lead to degeneration of intervertebral discs and joints, contributing to lumbar spondylitis.</li>
            <li><h5>Herniated Discs: </h5>Discs between vertebrae may bulge or herniate, causing compression of spinal nerves and resulting in pain, inflammation, and mobility issues.</li>
            <li><h5>Spinal Stenosis: </h5>Narrowing of the spinal canal due to bone spurs or thickened ligaments can compress spinal nerves, leading to pain and discomfort.</li>
            <li><h5>Ankylosing Spondylitis: </h5> Inflammatory arthritis affecting the spine and large joints, causing stiffness, pain, and potential fusion of vertebrae over time.</li>
            <li><h5>Spondylolisthesis: </h5>Forward displacement of a vertebra over the one beneath it, which may lead to instability and nerve compression in the lumbar spine.</li>
            <li><h5>Trauma: </h5>Previous injuries or accidents affecting the lumbar spine can result in structural damage and chronic pain.</li>
            <li><h5>Genetic Factors: </h5>Family history of spine disorders or autoimmune conditions may predispose individuals to develop lumbar spondylitis.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li><h5>Medical History and Physical Examination: </h5>Thorough assessment of symptoms, past injuries, and activities that worsen or alleviate pain. Physical examination to evaluate spine alignment, range of motion, tenderness, and neurological signs.</li>
            <li><h5>Imaging Tests:</h5>X-rays provide detailed images of the lumbar spine to detect bone spurs, disc degeneration, and alignment abnormalities. MRI scans offer more precise views of soft tissues, including discs, nerves, and spinal cord, to assess herniations and nerve compression.</li>
            <li><h5>CT Scan (Computed Tomography):</h5> Provides cross-sectional images to evaluate bone structures, particularly useful for assessing bone spurs and spinal canal narrowing.</li>
            <li><h5>Electromyography (EMG) and Nerve Conduction Studies: </h5>Measures electrical activity in muscles and nerve function to identify nerve compression or damage, aiding in determining the extent of spinal involvement.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Chronic Pain and Disability: </h5>Persistent lower back pain, stiffness, reduced mobility, and limitations in daily activities affecting quality of life.</li>
            <li><h5>Radicular Symptoms: </h5>Radiating pain, numbness, tingling, or weakness in the legs due to nerve compression in the lumbar spine.</li>
            <li><h5>Spinal Instability:</h5>Severe cases may lead to spinal instability or deformity, affecting posture and increasing the risk of falls or injuries.</li>
            <li><h5>Reduced Quality of Life: </h5>Difficulty in performing routine tasks, disrupted sleep patterns, and emotional distress due to chronic pain and physical limitations.</li>
            <li><h5>Risk Factors:</h5>Advanced age, occupations involving heavy lifting or repetitive bending, obesity, sedentary lifestyle, smoking, and poor posture increase the risk of developing lumbar spondylitis.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Medications:</h5>Nonsteroidal anti-inflammatory drugs (NSAIDs), muscle relaxants, and pain relievers to alleviate pain, reduce inflammation, and improve mobility.</li>
            <li><h5>Physical Therapy: </h5>Exercise programs to strengthen core muscles, improve flexibility, and correct posture. Manual therapy techniques such as massage, manipulation, and traction may help relieve muscle tension and improve range of motion.</li>
            <li><h5>Heat and Cold Therapy: </h5>Application of heat packs or ice packs to reduce inflammation, muscle spasms, and provide temporary pain relief.</li>
            <li><h5>Steroid Injections:</h5>Corticosteroid injections directly into the affected area to reduce inflammation and alleviate severe pain and swelling.</li>
            <li><h5>Surgery:</h5> In severe cases, surgical interventions may be necessary to stabilize the spine, decompress nerves, or correct structural abnormalities.</li>
          </ul>
          <h3>Homoeopathic Treatment:</h3>
          <ul>
            <li><h5>Rhus Toxicodendron:  </h5>Indicated for stiffness and pain in the lower back that worsens with initial movement and improves with continued motion.</li>
            <li><h5>Bryonia Alba: </h5>Suitable for sharp, stitching pains aggravated by movement and alleviated by rest, often associated with inflammation.</li>
            <li><h5>Calcarea Carbonica: </h5>Helpful for chronic lower back pain worsened by cold or damp weather, associated with weakness or fatigue.</li>
            <li><h5>Kali Carbonicum: </h5>Addresses shooting pains down the legs, particularly when pain worsens from sitting or standing for prolonged periods.</li> 
            <li><h5>Lycopodium:</h5>Indicated for lower back pain with digestive disturbances or bloating, worsened in the afternoon or evening.</li>
            </ul>
          <h3>Conclusion</h3>
          <p>Homoeopathic treatment for lumbar spondylitis focuses on personalized care and holistic management. By addressing symptoms and underlying causes, Homoeopathy supports the body’s natural healing processes and improves overall well-being. Many patients experience significant relief from pain, improved mobility, and enhanced quality of life with consistent Homoeopathic care. Our experience demonstrates that with appropriate remedy selection and patient compliance, Homoeopathy can complement conventional treatments effectively. This approach not only alleviates symptoms but also promotes long-term health benefits, emphasizing individualized treatment and lifestyle adjustments. Homoeopathy offers a gentle and non-invasive alternative, emphasizing holistic care and restoring balance to promote optimal health and well-being.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Lumbar spondylosis Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Lumbar spondylosis conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/spinal-disease/lumbar-spondylosis/lumbar-spondylosis-diet">Diet for Lumbar spondylosis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Lumbar spondylosis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Lumbar spondylosis</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Lumbar spondylosis Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/dYe0HLl9U2A?si=Z_Vx_3nVxlNS1qjy"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
