import React from 'react';
import '../css/Kidney.css'; // Add custom styles here
const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/sh6.webp')}alt="Urology Hero" />
        <h1>Psoriasis</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Psoriasis</h2>
          <img src={require('../images/skin6.webp')}alt="Urology Hero" />
          <p>
          Homoeopathy treats psoriasis by considering the person’s overall health, including their physical, mental, and emotional state. Remedies are selected based on the individual’s unique symptoms and constitution, aiming to reduce inflammation, relieve itching, and promote skin healing. Psoriasis is often chronic and recurring, so homoeopathic treatment focuses on long-term relief, minimizing flare-ups, and improving overall quality of life.
          </p>
          <h3>Causes of Molluscum Contagiosum</h3>
          <ul>
            <li><h5>Genetics:</h5>A family history of psoriasis or other autoimmune conditions increases the risk of developing psoriasis.            </li>
            <li><h5>Autoimmune Response:</h5>Psoriasis is an autoimmune disorder in which the immune system mistakenly attacks healthy skin cells, accelerating skin cell production.            </li>
            <li><h5>Environmental Triggers:</h5>Stress, infections, injuries, and weather changes can trigger or exacerbate psoriasis in individuals predisposed to the condition.            </li>
            <li><h5>Hormonal Changes:</h5>Hormonal fluctuations during puberty, pregnancy, or menopause can trigger or worsen psoriasis symptoms.            </li>
            <li><h5>Medications:</h5>Certain drugs, such as beta-blockers, lithium, and antimalarial medications, may induce or aggravate psoriasis.            </li>
            <li><h5>Lifestyle Factors:</h5>Smoking, alcohol consumption, and obesity are known to worsen psoriasis symptoms and increase the risk of flare-ups.            </li>
          </ul>
          <h3>Symptoms of Molluscum Contagiosum</h3>
          <ul>
            <li><h5>Red, Scaly Patches:</h5>Thickened areas of skin with silvery scales, often on the scalp, elbows, knees, and lower back.            </li>
            <li><h5>Itching and Burning Sensation:</h5>Psoriasis patches may itch, burn, or feel sore, especially during flare-ups.            </li>
            <li><h5>Dry, Cracked Skin:</h5>The skin may crack and bleed, leading to discomfort and increased risk of infection            </li>
            <li><h5>Nail Changes:</h5>Psoriasis can cause pitting, discoloration, and thickening of the nails or even lead to nail detachment.            </li>
            <li><h5>Joint Pain:</h5>In cases of psoriatic arthritis, joints become painful, swollen, and stiff, affecting mobility.            </li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Psoriatic Arthritis:</h5>Up to 30% of people with psoriasis develop psoriatic arthritis, which can lead to joint damage if left untreated.            </li>
            <li><h5>Cardiovascular Disease:</h5>Individuals with psoriasis have a higher risk of cardiovascular issues due to chronic inflammation.            </li>
            <li><h5>Metabolic Syndrome:</h5>Psoriasis is associated with conditions like obesity, high blood pressure, and diabetes, increasing the risk of metabolic syndrome.            </li>
            <li><h5>Emotional Impact:</h5>Psoriasis can lead to anxiety, depression, and decreased quality of life, especially when it affects visible areas of the body.            </li>
         </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Topical Treatments:</h5>Corticosteroids, vitamin D analogs, retinoids, and coal tar are commonly used to reduce inflammation and slow cell turnover.            </li>
            <li><h5>Light Therapy:</h5>UVB or PUVA phototherapy is effective for reducing symptoms in moderate to severe cases by slowing down skin cell production.            </li>
            <li><h5>Systemic Medications:</h5>In cases of severe psoriasis, oral or injectable medications like methotrexate, cyclosporine, or biologics may be prescribed to suppress the immune system.            </li>
            <li><h5>Moisturisers and Emollients:</h5>Keeping the skin hydrated helps reduce dryness, itching, and scaling.            </li>
            <li><h5>Lifestyle Modifications:</h5>Maintaining a balanced diet, reducing stress, quitting smoking, and avoiding alcohol can support overall skin health and reduce flare-ups.            </li>
          </ul>
          <h3>Homoeopathic Treatment</h3>
          <ul>
            <li><h5>Arsenicum Album:</h5>Effective for individuals with dry, scaly patches and intense itching that worsens at night. Suitable for people who may feel anxious, restless, and fatigued.            </li>
            <li><h5>Graphites:</h5>Recommended for thick, rough, cracked skin with oozing or sticky discharges. Ideal for cases where psoriasis patches are found in skin folds, such as behind the ears, in the groin, or under the breasts.            </li>
            <li><h5>Sepia:</h5>Suitable for women with hormonal imbalances that may exacerbate psoriasis, particularly during menopause or menstruation. Useful for cases with rough, dry patches, and a sense of indifference Recommended for individuals with dry, flaky skin, often on the elbows, knees, and scalp. It is helpful for people experiencing digestive issues, fatigue, and symptoms that worsen in the evening.            Useful for lesions that are itchy, painful, and prone to cracking. This remedy is beneficial for those with emotional triggers, particularly those who suppress grief or stress.            </li>
          </ul>
         <h3>Conclusion / Our Experience</h3>
          <p>
          In our experience, homoeopathy offers a holistic approach to managing psoriasis by addressing both physical symptoms and underlying emotional factors. Many individuals report less itching, reduced scaling, and fewer flare-ups with consistent homoeopathic treatment. While homoeopathy may not completely cure psoriasis, it serves as an effective complementary approach, particularly for those seeking natural, non-invasive options to manage the condition. Homoeopathic remedies help reduce the need for long-term steroid use and minimize the risk of side effects, making them suitable for people of all ages, including children. Working with a qualified healthcare provider is essential to ensure the best possible outcomes and comprehensive care.
          </p>
</div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Psoriasis Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Psoriasis.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/skin/psoriasis/psoriasis-diet">Diet for Psoriasis prevention</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Psoriasis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Psoriasis</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Psoriasis Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/ln39vgVKHIU?si=rH93n17asthuzShQ"
                    title="Infertility Treatment Video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
