import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/mih1.webp')}alt="Urology Hero" />
        <h1>Migraine Treatment</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Migraine</h2>
          <img src={require('../images/head1.webp')}alt="Urology Hero" />
        <h3> Basic Homoeopathic Approach</h3>
          <p>
          Homoeopathy offers a holistic approach to treating migraines, aiming to address the underlying causes and provide relief from symptoms & Homoeopathic medicine for good sleep. Remedies are selected based on the individual’s specific symptoms, triggers, and overall health, focusing on stimulating the body’s natural healing ability.          </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Genetic Factors:</h5> Family history of migraines can predispose individuals to the condition.</li>
            <li><h5>Triggers:</h5>Common triggers include stress, hormonal changes (menstruation, pregnancy), certain foods (chocolate, cheese), sensory stimuli (bright lights, strong odors), and changes in sleep patterns.</li>
            <li><h5>Environmental Factors:</h5> Weather changes, altitude, and sensory overload can trigger migraines.</li>
            <li><h5>Neurological Factors:</h5>Imbalances in brain chemicals, such as serotonin, may contribute to migraines.</li>
            <li><h5>Health Conditions:</h5>Certain medical conditions like high blood pressure or temporomandibular joint disorders (TMJ) can be associated with migraines.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li><h5>Detailed Medical History:</h5>Including frequency, duration, and characteristics of migraines.</li>
            <li><h5>Physical Examination: </h5>To rule out other neurological conditions and assess overall health.</li>
            <li><h5>Triggers and Lifestyle Factors:</h5>Identifying specific triggers and lifestyle habits that may contribute to migraines.</li>
            <li><h5>Diagnostic Tests:</h5>Sometimes, imaging studies (MRI or CT scan) may be recommended to rule out other causes of headaches.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Chronic Pain:</h5>Frequent migraines can lead to chronic pain and reduced quality of life.</li>
            <li><h5>Medication Overuse Headache:</h5>Over-reliance on pain medications can lead to rebound headaches.</li>
            <li><h5>Disability:</h5>Severe migraines can interfere with daily activities, work, and social life.</li>
            <li><h5>Emotional Impact:</h5>Migraines can contribute to anxiety, depression, and stress.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Pain Relief Medications:</h5>Over-the-counter pain relievers (NSAIDs like ibuprofen) or prescription medications (triptans) to relieve pain during an attack.</li>
            <li><h5>Preventive Medications:</h5>Medications taken daily to reduce the frequency and severity of migraines (beta-blockers, antidepressants, anticonvulsants).</li>
            <li><h5>Lifestyle Modifications:</h5>Stress management techniques, regular exercise, adequate sleep, and dietary adjustments to identify and avoid triggers.</li>
            <li><h5>Alternative Therapies:</h5>Acupuncture, massage therapy, biofeedback, and cognitive-behavioral therapy (CBT) to manage stress and improve coping mechanisms.</li>
          </ul>
          <h3>Homoeopathic Treatment</h3>
          <ul>
            <li><h5>Belladonna: </h5>For sudden, intense, throbbing headaches that come on quickly, often with flushed face and sensitivity to light and noise.</li>
            <li><h5>Iris Versicolor:</h5> Helpful for migraines with intense nausea, vomiting, and blurred vision, often associated with digestive disturbances.</li>
            <li><h5>Natrum Muriaticum:</h5>Indicated for migraines triggered by grief, stress, or hormonal changes, with a sensation of a tight band around the head.</li>
            <li><h5>Gelsemium:</h5> Used for migraines accompanied by dizziness, weakness, and heaviness of the eyelids, worsened by emotional stress.</li>
            <li><h5>Sanguinaria:</h5>For migraines that start in the back of the head or neck and move to the right side, often with nausea and sensitivity to light or noise.</li>
          </ul>
          <h3>Conclusion</h3>
          <p>Homoeopathy provides a personalized and holistic approach to managing migraines, addressing both acute symptoms and underlying causes. Our experience shows that Homoeopathic remedies can effectively reduce the frequency and intensity of migraines, improve overall well-being, and offer a natural alternative for individuals seeking to avoid or complement conventional treatments. By understanding the individual’s unique symptoms and triggers, Homoeopathy aims to restore balance and promote long-term relief from migraines without the potential side effects associated with medications.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Migraine Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Migraine conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/head/migraine/migraine-diet">Diet for Migraine</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Migraine</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Migraine Health</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Migraine Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/NrcniTOTGfk?si=3thYu5MmxQn1_0l1"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
