import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/pedia3.webp')}alt="Urology Hero" />
        <h1>Attention-deficit/hyperactivity disorder (ADHD) Treatment</h1>
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Attention-deficit/hyperactivity disorder (ADHD)</h2>
          <img src={require('../images/child3.webp')}alt="Urology Hero" />
        <h3> Basic Homoeopathic Approach</h3>
          <p>
          Homoeopathy approaches ADHD by treating the individual holistically, focusing on physical, emotional, and psychological aspects. The objective is to address the root causes of the disorder, not merely manage symptoms. This involves a comprehensive understanding of the patient’s personality, behavioral patterns, and life history. Homoeopathic remedies are selected based on the principle of “like cures like,” where a substance that causes symptoms in a healthy person can be used to treat similar symptoms in a sick person.
          </p>
          <h3>Causes</h3>
          <p>ADHD is believed to arise from a combination of factors, including:</p>
          <ul>
            <li><h5>Genetic Factors:</h5> A family history of ADHD or other mental health disorders can increase susceptibility.</li>
            <li><h5>Neurological Factors:</h5> Structural and functional differences in the brain, especially in areas controlling attention and behavior.</li>
            <li><h5>Environmental Factors: </h5>Exposure to toxins, such as lead, during pregnancy or early childhood.</li>
            <li><h5>Developmental Factors:   </h5> Issues during prenatal development, premature birth, or low birth weight.         </li>
            <li><h5>Social Factors:</h5> While not direct causes, unstable home environments and inconsistent parenting can exacerbate symptoms.</li>
          </ul>
          <h3>Investigations</h3>
          <p>Diagnosing ADHD involves a thorough and multi-disciplinary approach:</p>
          <ul>
            <li><h5>Comprehensive Medical History:</h5> Detailed examination of the patient’s developmental, medical, and family history.</li>
            <li><h5>Behavioral Assessments:</h5> Standardized questionnaires and direct observation of behavior in different settings (home, school, etc.). </li>
            <li><h5>Psychological Evaluations:</h5>Cognitive and emotional assessments to identify strengths and weaknesses.   </li>
            <li><h5>Neurological Tests: </h5>Brain imaging and other tests to rule out other conditions. </li>
            <li><h5>Educational Assessments: </h5> Evaluations to understand the impact of symptoms on learning and academic performance. </li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <p>ADHD can lead to several complications, such as:</p>
          <ul>
            <li><h5>Academic Challenges:</h5> Poor school performance, difficulty completing tasks, and frequent changes in academic settings.</li>
            <li><h5>Social Difficulties:</h5>Struggles with making and keeping friends, impulsive behavior, and social isolation.</li>
            <li><h5>Legal Issues: </h5> Higher risk of engaging in risky or illegal behaviors, particularly in adolescence.</li>
            <li><h5>Substance Abuse:</h5> Increased likelihood of developing dependency on alcohol or drugs as a coping mechanism.</li>
            <li><h5>Mental Health Issues:</h5> Greater risk of anxiety, depression, and other coexisting mental health conditions. Risk factors include:</li>
            <li><h5>Family History:</h5> Genetic predisposition to ADHD or other psychiatric conditions.</li>
            <li><h5>Exposure to Environmental Toxins:</h5> Lead exposure or maternal substance use during pregnancy.</li>
            <li><h5>Premature Birth:</h5>  Babies born prematurely or with low birth weight.</li>
            <li><h5>Parental Mental Health:</h5> Parental psychiatric conditions can influence the severity of ADHD symptoms.</li>
            </ul>
          <h3>Traditional Basic Treatment</h3>
          <p>Conventional treatment for ADHD includes a combination of strategies:</p>
          <ul>
            <li><h5>Behavioral Therapy: </h5> Techniques such as cognitive-behavioral therapy (CBT) to improve behavior and organizational skills.</li>
            <li><h5>Medication:</h5> Stimulant medications (like methylphenidate and amphetamines) or non-stimulant medications (like atomoxetine) to manage symptoms.</li>
            <li><h5>Parent Training:</h5> Educating parents on strategies to support their child’s development and manage behavior.</li>
            <li><h5>Educational Support:</h5> Special education programs and individualized education plans (IEPs) to cater to the child’s learning needs.</li>
            <li><h5>Lifestyle Changes: </h5> Encouraging regular physical activity, a healthy diet, and sufficient sleep to improve overall well-being.</li>
          </ul>
          <h3>Homoeopathic Supportive Care</h3>
          <p>Homoeopathy offers individualized remedies based on the patient’s unique symptoms and overall constitution:</p>
          <ul>
            <li><h5>Stramonium:</h5> For children with intense fears, aggression, and nightmares.</li>
            <li><h5>Hyoscyamus:</h5> For restlessness, impulsivity, jealousy, and inappropriate behavior.</li>
            <li><h5>Tarentula Hispanica:</h5> For hyperactivity, impatience, and a constant need for movement.</li>
            <li><h5>Tuberculinum:</h5> For chronic restlessness, dissatisfaction, and a desire for change and new experience.</li> 
            <li><h5>Medorrhinum:</h5> For children who are extremely restless, talkative, and have a love for excitement and novelty.</li>
            <li><h5>Calcarea Phosphorica:</h5> For children who are easily distracted, restless, and have difficulty concentrating.</li>
            </ul>
            <p>Homoeopathic remedies are prescribed in minute doses, tailored to the individual’s specific symptoms and overall health profile. A detailed consultation with a qualified homeopath is essential to determine the most appropriate remedy and dosage.</p>
          <h3>Conclusion</h3>
          <p>In our experience, a holistic approach combining Homoeopathic treatment, lifestyle modifications, and supportive therapies can lead to significant improvements in individuals with ADHD. Patients often report better focus, improved behavior, and enhanced overall well-being. Homoeopathy, with its emphasis on individualized care, offers a gentle yet effective solution for managing ADHD. Consulting with a qualified homeopath ensures that the treatment plan is tailored to the unique needs of each patient, promoting long-term health and balance. The integration of Homoeopathy into a comprehensive treatment strategy allows for a more nuanced and effective approach to managing ADHD, addressing the disorder at its roots and providing lasting benefits for the patient’s mental and emotional health.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Attention-deficit/hyperactivity disorder (ADHD) Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Attention-deficit/hyperactivity disorder (ADHD) conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/pediatric/attention-deficit/attention-deficit-diet">Diet for Attention-deficit/hyperactivity disorder (ADHD)</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Attention-deficit/hyperactivity disorder (ADHD)</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Attention-deficit/hyperactivity disorder (ADHD)</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Attention-deficit/hyperactivity disorder Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/Haq25w3NH6Y?si=jyfgFyqi54T-5ctU"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
