import React from 'react';
import '../css/Kidney.css'; 
const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">

      <img src={require('../images/fh12.webp')}alt="Urology Hero" />
        <h1>Menopausal Syndrome Treatment</h1>
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Menopausal Syndrome Disorder</h2>
          <img src={require('../images/female12.webp')}alt="Urology Hero" />
         <h3>Basic Homoeopathic Approach</h3>
         <p>
Homoeopathy addresses menopausal syndrome by considering the individual as a whole, including physical, mental, and emotional aspects. Rather than focusing only on the symptoms of menopause, the aim is to address underlying imbalances and support the body’s natural transition. Homoeopathic remedies are chosen based on the woman’s unique symptoms and constitution, providing relief from menopausal symptoms and enhancing overall well-being.
         </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Hormonal Decline:</h5>A decrease in oestrogen and progesterone levels leads to common menopausal symptoms such as hot flashes, mood swings, and night sweats.            </li>
            <li><h5>Ageing:</h5>Natural ageing processes affect ovarian function, resulting in the gradual cessation of menstruation.            </li>
            <li><h5>Surgical Menopause:</h5>Surgical removal of the ovaries can lead to an abrupt onset of menopausal symptoms.            </li>
            <li><h5>Lifestyle Factors:</h5>Factors like stress, poor diet, lack of exercise, and smoking can intensify menopausal symptoms.            </li>
            <li><h5>Medical Treatments:</h5>Certain cancer treatments (like chemotherapy or radiation therapy) can induce early menopause by affecting ovarian function.            </li>
          </ul>
          <h3>Symptoms of Hormonal Imbalance</h3>
          <ul>
            <li><h5>Hot Flashes and Night Sweats:</h5>Sudden waves of heat and sweating, often occurring at night and disrupting sleep.            </li>
            <li><h5>Mood Swings and Irritability:</h5>Hormonal fluctuations can lead to anxiety, depression, and irritability.            </li>
            <li><h5>Weight Gain:</h5>Hormonal changes can lead to an increase in abdominal fat and difficulty maintaining a healthy weight.            </li>
            <li><h5>Vaginal Dryness:</h5>Decreased oestrogen levels lead to reduced lubrication, causing discomfort during intercourse.            </li>
            <li><h5>Memory and Concentration Issues:</h5>Difficulty concentrating and memory lapses, often referred to as “brain fog.”            </li>
            <li><h5>Sleep Disturbances:</h5>Insomnia and frequent waking due to night sweats can result in poor sleep quality.            </li>
          </ul>
          <h3>Complications and Risk Factors:</h3>
          <ul>
            <li><h5>Osteoporosis:</h5>Declining oestrogen levels can lead to decreased bone density, raising the risk of fractures.            </li>
            <li><h5>Heart Disease:</h5>Post-menopausal women have an increased risk of cardiovascular diseases due to changes in cholesterol levels and blood pressure.            </li>
            <li><h5>Cardiovascular Disease:</h5>Imbalances in oestrogen and progesterone can raise the risk of heart disease, especially after menopause.            </li>
            <li><h5>Metabolic Syndrome:</h5>Hormonal imbalances are often linked to conditions like obesity, high blood pressure, and insulin resistance.            </li>
          </ul>
          <h3>Homoeopathic Treatment:</h3>
          <ul>
            <li><h5>Lachesis:</h5>Ideal for hot flashes, especially on the left side, with symptoms worsening at night and after sleep. Useful for mood swings, irritability, and intolerance to tight clothing.            </li>
            <li><h5>Sepia:</h5>Effective for women experiencing fatigue, irritability, hot flashes, and a sense of apathy. Also helpful for vaginal dryness and diminished libido.            </li>
            <li><h5>Sulphur:</h5>For women with hot flashes that worsen with heat, along with skin symptoms like itching or burning. Beneficial for night sweats and irritability.            </li>
            <li><h5>Pulsatilla:</h5>Suitable for women who feel weepy, emotional, and experience mood swings. Symptoms often improve with fresh air.            </li>
            <li><h5>Calcarea Carbonica:</h5>Recommended for women who experience weight gain, fatigue, night sweats, and feelings of anxiety and overwhelm.            </li>
          </ul>
          <h3>Traditional Basic Treatment:</h3>
          <ul>
            <li><h5>Hormone Replacement Therapy (HRT):</h5>HRT supplements oestrogen and progesterone to relieve symptoms like hot flashes, night sweats, and vaginal dryness.            </li>
            <li><h5>Non-Hormonal Medications:</h5>Antidepressants, anti-seizure drugs, and blood pressure medications may be prescribed for hot flashes and mood management.            </li>
            <li><h5>Lifestyle Modifications:</h5>Regular exercise, a balanced diet, reducing alcohol and caffeine, and practising stress-relief techniques can help alleviate symptoms.            </li>
            <li><h5>Vaginal Oestrogen:</h5>Topical oestrogen creams or tablets are used to relieve vaginal dryness and discomfort.            </li>
            <li><h5>Calcium and Vitamin D Supplements:</h5>Essential for maintaining bone health and reducing the risk of osteoporosis.            </li>
          </ul>
          <h3>Conclusion</h3>
          <p>In our experience, homoeopathy offers a supportive and holistic approach to managing menopausal syndrome. Many women report significant relief from symptoms like hot flashes, night sweats, and mood swings with homoeopathic treatment. Homoeopathy not only addresses specific symptoms but also focuses on improving overall emotional and physical well-being during this transitional phase. While it may not replace conventional treatments, homoeopathy serves as an effective complementary approach, especially for those seeking a natural and less invasive way to manage menopausal symptoms. </p>
        </div>
        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Menopausal Syndrome Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Menopausal Syndrome conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>
          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/female-troubles/menopausal-syndrome/menopausal-syndrome-diet">Diet for relief of Menopausal Syndrome disorder</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Menopausal Syndrome </a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Menopausal Syndrome Health</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Menopausal Syndrome Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/xKqDadi2Llc?si=7KeCNXnwuELEBthj"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
      </div>
  );
};
export default KidneyPage;
