import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {

    return (
        <div className="kidney-page">
            {/* Hero Image Section */}
            <div className="kidney-hero-image">
                <img src={require('../images/meh1.webp')} alt="Mood Disorder Hero" />
                <h1>Mood Disorder Treatment</h1>
            </div>
            {/* Main Content Section */}
            <div className="kidney-content-container">
                <div className="kidney-left-content">
                    <h2>Mood Disorders</h2>
                    <img src={require('../images/mental1.webp')} alt="" />
                    <h3>Basic Homoeopathic Approach</h3>
                    <p>
                        Homoeopathy provides a holistic and personalized approach to treating mood disorders, addressing each individual's physical, emotional, and mental symptoms. The remedies aim to balance mood, reduce symptoms, and improve overall mental health. This gentle approach not only alleviates immediate symptoms but also helps manage the underlying causes, providing long-term benefits and enhancing quality of life.
                    </p>
                    <h3>Common Causes</h3>
                    <ul>
                        <li><h5>Genetic Factors:</h5> Family history of mood disorders may increase the risk.</li>
                        <li><h5>Environmental Stressors:</h5> Prolonged exposure to stressful situations can trigger or worsen mood disorders.</li>
                        <li><h5>Imbalance of Neurotransmitters:</h5> Chemical imbalances in the brain, such as serotonin and dopamine, play a role in mood regulation.</li>
                        <li><h5>Physical Health Conditions:</h5> Chronic illnesses and hormonal imbalances can impact mental well-being.</li>
                        <li><h5>Substance Abuse:</h5> Alcohol and drug use can affect mood and exacerbate symptoms.</li>
                    </ul>
                    <h3>Diagnostic Investigations</h3>
                    <ul>
                        <li><h5>Psychiatric Evaluation:</h5> In-depth assessment of symptoms, emotional state, and mental health history.</li>
                        <li><h5>Physical Exam:</h5> Examination to rule out physical conditions that may cause mood-related symptoms.</li>
                        <li><h5>Blood Tests:</h5> Laboratory tests to assess hormone levels, thyroid function, and other factors.</li>
                        <li><h5>Psychological Questionnaires:</h5> Standardized tools like the Beck Depression Inventory for assessing mood.</li>
                    </ul>
                    <h3>Complications and Risk Factors</h3>
                    <ul>
                        <li><h5>Social Isolation:</h5> Untreated mood disorders may lead to withdrawal from social interactions.</li>
                        <li><h5>Substance Dependence:</h5> Some individuals turn to substances as a coping mechanism, leading to addiction.</li>
                        <li><h5>Chronic Physical Illness:</h5> Mood disorders may contribute to or worsen physical health problems.</li>
                        <li><h5>Risk Factors:</h5> Family history, trauma, prolonged stress, and history of substance abuse increase risk.</li>
                    </ul>
                    <h3>Traditional Treatments</h3>
                    <ul>
                        <li><h5>Medications:</h5> Antidepressants, mood stabilizers, and antipsychotics to manage symptoms.</li>
                        <li><h5>Psychotherapy:</h5> Therapies such as CBT, counseling, and psychoeducation help patients understand and manage their symptoms.</li>
                        <li><h5>Lifestyle Modifications:</h5> Regular exercise, adequate sleep, and a balanced diet to improve mental health.</li>
                        <li><h5>Mindfulness Techniques:</h5> Practices like meditation and breathing exercises help manage stress and improve mood.</li>
                    </ul>
                    <h3>Homoeopathic Supportive Care</h3>
                    <ul>
                        <li><h5>Aurum Metallicum:</h5> Suitable for feelings of sadness, hopelessness, and despair.</li>
                        <li><h5>Natrum Muriaticum:</h5> Helps with emotional suppression, grief, and mood swings.</li>
                        <li><h5>Ignatia:</h5> Often prescribed for grief, anxiety, and mood irregularities.</li>
                        <li><h5>Sepia:</h5> Effective for mood swings, irritability, and hormonal imbalances.</li>
                        <li><h5>Pulsatilla:</h5> Helps manage tearfulness, emotional dependency, and anxiety.</li>
                    </ul>
                    <h3>Conclusion</h3>
                    <p>
                        Homoeopathic treatment for mood disorders emphasizes holistic healing, focusing on the root causes of emotional distress. With individualized care and natural remedies, Homoeopathy aids in balancing mood, reducing stress, and promoting emotional stability. When combined with lifestyle adjustments and conventional therapies, it provides a gentle, non-invasive approach to managing mood disorders, helping individuals lead a balanced and fulfilling life.
                    </p>
                </div>

                {/* Right Sidebar */}
                <div className="kidney-right-sidebar">
                    <div className="kidney-treatment-section">
                        <h3>Mood Disorder Treatment</h3>
                        <p>We offer natural, safe, and effective treatment for Mood Disorder conditions.</p>
                        <button className="appointments-btn">Book Appointment</button>
                    </div>

                    <div className="related-articles">
                        <h3>Related Articles</h3>
                        <ul>
                            <li><a href="/treatments/psychiatric/mood-disorders/mood-disorders-diet">Diet for Mood Disorder</a></li>
                            <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Mood Disorders</a></li>
                            <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Mood Disorders</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* YouTube Video Section */}
            <div className="kidney-video-container">
                <h3>Watch Our Video on Mood Disorders Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/2Og2oJik3Ek?si=7yHefiAyEPZDg_my"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
        </div>
    );
};

export default KidneyPage;
