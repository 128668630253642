import React from 'react';
import '../css/Kidney.css'; // Add custom styles here
const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/gh5.webp')}alt="Urology Hero" />
        <h1>Anal Fissures</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Anal Fissures</h2>
          <img src={require('../images/gastric5.webp')}alt="Urology Hero" />
        <h3> Basic Homoeopathic Approach</h3>
        <p>
            Homoeopathy treats anal fissures by addressing both the physical symptoms and the individual's overall health. The focus is on promoting healing, relieving pain, and preventing recurrence by improving digestive health, circulation, and tissue integrity. Homoeopathic remedies are selected based on the individual’s specific symptoms and constitution, aiming to provide gentle, effective relief and support the body’s natural healing process.
        </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Constipation:</h5>Straining during bowel movements due to hard stools can tear the lining of the anus.            </li>
            <li><h5>Diarrhoea:</h5>Frequent, loose stools can irritate and erode the anal lining, causing fissures.            </li>
            <li><h5>Childbirth:</h5>The pressure exerted during delivery can result in tears around the anus.            </li>
            <li><h5>Trauma:</h5>Injury to the anal area, such as from anal intercourse or insertion of foreign objects, can lead to fissures.            </li>
            <li><h5>Underlying Medical Conditions:</h5>Conditions like Crohn’s disease, ulcerative colitis, and other inflammatory bowel diseases increase the risk of developing anal fissures.            </li>
            <li><h5>Poor Circulation:</h5>Reduced blood flow to the anus, often due to underlying conditions, can delay healing and make the area more prone to tearing.            </li>
          </ul>
          <h3>Symptoms of Anal Fissures</h3>
          <ul>
            <li><h5>Pain During Bowel Movements:</h5>Sharp or burning pain during and after bowel movements, sometimes lasting for hours.            </li>
            <li><h5>Bright Red Blood:</h5>Streaks of blood on the stool or toilet paper due to the tearing of the anal lining.            </li>
            <li><h5>Itching or Irritation:</h5>Persistent discomfort and itchiness around the anus.            </li>
            <li><h5>Visible Tears:</h5>A small crack or tear can sometimes be visible around the anus.            </li>
            <li><h5>Muscle Spasms:</h5>Tightening or spasms of the anal sphincter muscle, which can exacerbate pain and delay healing.            </li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Chronic Fissures:</h5>Fissures that don’t heal within six weeks are considered chronic and may require further treatment.            </li>
            <li><h5>Infection:</h5>Untreated fissures can become infected, leading to abscesses or fistulas.            </li>
            <li><h5>Recurrent Fissures:</h5>Once a fissure has occurred, the risk of recurrence is higher, particularly with ongoing constipation or diarrhoea.            </li>
            <li><h5>Anal Stenosis:</h5>Scar tissue from repeated fissures can lead to narrowing of the anal canal, making bowel movements painful and difficult.            </li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Topical Medications:</h5>Numbing creams, hydrocortisone, or nitroglycerin ointments may be applied to relieve pain and promote healing.            </li>
            <li><h5>Stool Softeners:</h5>Softening the stool can reduce straining during bowel movements, which may help the fissure heal.            </li>
            <li><h5>Sitz Baths:</h5>Soaking the anal area in warm water several times a day can relieve pain, promote circulation, and facilitate healing.            </li>         
            <li><h5>Surgical Intervention:</h5>In severe cases, a procedure such as a lateral internal sphincterotomy may be performed to reduce muscle spasms and allow healing.            </li>
            <li><h5>Botox Injections:</h5>Used to relax the anal sphincter muscle, reducing spasms and allowing the fissure to heal.            </li>
            </ul>
            <h3>Homoeopathic Treatment</h3>
          <ul>
            <li><h5>Nitricum Acidum:</h5>Ideal for fissures that cause intense, sharp pain and bleeding, especially when the pain feels like “splinters” or is accompanied by soreness.</li>
            <li><h5>Ratanhia:</h5>Suitable for burning pain and soreness after bowel movements. It is particularly helpful for fissures with a sensation of sharp, cutting pain.            </li>
            <li><h5>Graphites:</h5>Effective for chronic fissures with intense itching and burning. It is also helpful for individuals with a tendency toward constipation and dryness.            </li>         
            <li><h5>Paeonia:</h5>For fissures accompanied by soreness, rawness, and itching around the anus. It helps with fissures that produce a lot of discomfort and are often moist.            </li>
            <li><h5>Aloe Socotrina:</h5>Useful for fissures in those with a history of diarrhoea or a tendency to haemorrhoids. It’s effective when there is intense discomfort and fullness around the rectal area.            </li>
            </ul>
          <h3>Conclusion / Our Experience</h3>
          <p>
          In our experience, homoeopathy provides effective relief for individuals with anal fissures by addressing both the symptoms and underlying causes. Many patients report less pain, faster healing, and a reduction in recurrences with homoeopathic treatment. Homoeopathy not only helps to manage immediate symptoms but also strengthens the body’s ability to prevent future occurrences by improving overall digestive and circulatory health. While it may not replace conventional treatments for severe cases, homoeopathy serves as a gentle, complementary approach for those seeking a natural and non-invasive way to manage anal fissures.
          </p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Anal Fissures Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Anal Fissures.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/gastric-issues/anal-fissures/anal-fissures-diet">Diet for preventing Anal Fissures</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention from Anal Fissures</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for relief of Anal Fissures</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Anal Fissures Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/iHvkPunZKvY?si=W_R6MVtliL_36jHs"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
