import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/hh4.webp')}alt="Urology Hero" />
        <h1>Hyperthyroidism</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Hyperthyroidism</h2>
          <img src={require('../images/hormones4.webp')}alt="Urology Hero" />
        <h3> Basic Homoeopathic Approach</h3>
        <ul>
            <li><h5>Individualized Remedies:</h5>Homoeopathy treats hypothyroidism based on specific symptoms and the patient’s overall constitution.</li>
            <li><h5>Miasmatic Analysis:</h5>Considering underlying miasms (inherited predispositions) that could influence thyroid function.</li>
            <li><h5>Potency and Dosage:</h5>Remedies prescribed in varying potencies and dosages tailored to the individual’s response.</li>
          </ul>
          <h3>Causes</h3>
          <ul>
            <li><h5>Autoimmune Thyroiditis:</h5>Such as Hashimoto’s disease, where the immune system attacks the thyroid gland.</li>
            <li><h5>Iodine Deficiency:</h5>Essential for thyroid hormone production.</li>
            <li><h5>Thyroid Surgery or Radiation: </h5> Treatment for hyperthyroidism or thyroid cancer can lead to hypothyroidism.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li><h5>Thyroid Function Tests: </h5> Assessing TSH, free T3, and free T4 levels.</li>
            <li><h5>Antibody Tests: </h5>Checking for autoimmune thyroid antibodies (e.g., anti-TPO antibodies).</li>
            <li><h5>Ultrasound:</h5>To visualize the thyroid gland and detect abnormalities.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Metabolic Issues:</h5>Weight gain, fatigue, and cold intolerance.</li>
            <li><h5>Cardiovascular Problems:</h5>Increased risk of heart disease and elevated cholesterol levels.</li>
            <li><h5>Myxedema:</h5>Severe untreated hypothyroidism leading to coma or life-threatening complications.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Levothyroxine Replacement:</h5>Synthetic thyroid hormone to restore hormone levels.</li>
            <li><h5>Monitoring:</h5>Regular thyroid function tests to adjust medication dosage.</li>
            <li><h5>Lifestyle Management:</h5>Emphasizing iodine-rich diet and stress management.</li>
          </ul>
          <h3>Homoeopathic Supportive Care:</h3>
          <ul>
            <li><h5>Symptom-Based Remedies:</h5>Calcarea carbonica for sluggish metabolism and weight gain.</li>
            <li><h5>Thyroidinum:</h5>Derived from thyroid tissue, used for general thyroid support.</li>
            <li><h5>Adjunctive Remedies:</h5>Sepia for hormonal imbalances and mood swings.</li>
          </ul>
          <h3>Conclusion / Our Experience</h3>
          <p>Homoeopathic treatment for hypothyroidism aims to alleviate symptoms and support thyroid function using individualized remedies. It complements conventional medicine but should not replace essential thyroid hormone replacement therapy in severe cases. Homeo medicine for weight loss & Weight gain is helpful. Collaborative care ensures comprehensive management and optimal health outcomes. Always consult healthcare professionals for proper diagnosis and treatment guidance.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Hyperthyroidism</h3>
            <p>We offer natural, safe, and effective treatment for Hyperthyroidism conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/hormonal-diseases/hyperthyroidism/hyperthyroidism-diet">Diet for Hyperthyroidism</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Hyperthyroidism</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Hyperthyroidism</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Hyperthyroidism Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/_KhFpHtkYjk?si=3i8T94Yys4uchVPC"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div> 
    </div>
  );
};

export default KidneyPage;
