import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/mgh5.webp')}alt="Urology Hero" />
        <h1>Epididymitis Treatment</h1>
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Epididymitis</h2>
          <img src={require('../images/male5.webp')}alt="Urology Hero" />
        <h3> Basic Homoeopathic Approach</h3>
          <p>
          Homoeopathy treats epididymitis by considering the patient’s overall health, lifestyle, and specific symptoms. The aim is to stimulate the body’s natural healing processes, reduce inflammation, alleviate pain, and prevent recurrence. Remedies are selected based on a comprehensive assessment of the individual’s physical and emotional state, ensuring a personalized approach to treatment.          </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Infections:</h5>The most common cause is bacterial infections, including sexually transmitted infections (STIs) such as chlamydia and gonorrhea. Non-STI bacteria, like E. coli, can also cause epididymitis.</li>
            <li><h5>Urinary Tract Infections (UTIs):</h5>Bacteria from a UTI can spread to the epididymis, leading to inflammation.</li>
            <li><h5>Prostate Infections: </h5>An infection of the prostate gland (prostatitis) can extend to the epididymis.</li>
            <li><h5>Trauma or Injury:</h5>Physical injury to the groin or scrotal area can cause inflammation of the epididymis.</li>
            <li><h5>Autoimmune Conditions: </h5>In rare cases, autoimmune diseases can result in epididymitis due to the body’s immune response attacking the epididymis.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li><h5>Physical Examination: </h5>A thorough examination of the scrotum to check for tenderness, swelling, and redness. The doctor may also examine the abdomen and groin for signs of other issues.</li>
            <li><h5>Urinalysis: </h5>A urine sample is tested for signs of infection, such as the presence of bacteria or white blood cells.</li>
            <li><h5>Ultrasound:</h5>Scrotal ultrasound is used to confirm the diagnosis, evaluate the extent of inflammation, and rule out other conditions like testicular torsion or tumors.</li>
            <li><h5>Sexually Transmitted Infection (STI) Testing: </h5>Swab or urine tests are conducted to detect the presence of STIs like chlamydia and gonorrhea.</li>
            <li><h5>Blood Tests: </h5>Blood tests can help identify systemic signs of infection or inflammation and check for underlying conditions.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Abscess Formation: </h5>Severe infections can lead to the formation of pus-filled pockets (abscesses) in the scrotum, which may require drainage.</li>
            <li><h5>Chronic Epididymitis:</h5>Persistent or recurrent inflammation can lead to chronic pain and discomfort, sometimes lasting for months.</li>
            <li><h5>Infertility:</h5>Severe or untreated epididymitis can cause scarring of the epididymis or vas deferens, potentially leading to infertility.</li>
            <li><h5>Testicular Atrophy:</h5>Chronic inflammation can result in the shrinking (atrophy) of the affected testicle.</li>
            <li><h5>Sepsis:</h5>In rare cases, the infection can spread to the bloodstream, leading to a potentially life-threatening condition known as sepsis.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Antibiotics:</h5>Antibiotics are prescribed to treat the bacterial infection. The choice of antibiotic depends on the specific bacteria causing the infection, with a focus on those effective against STIs if present.</li>
            <li><h5>Pain Relief:</h5>Over-the-counter painkillers such as ibuprofen or acetaminophen help manage pain and reduce inflammation. In some cases, stronger prescription pain medications may be necessary.</li>
            <li><h5>Rest and Scrotal Support:</h5>Bed rest and using an athletic supporter can help alleviate discomfort and support the scrotum.</li>
            <li><h5>Ice Packs: </h5>Applying ice packs to the scrotum can reduce swelling and pain.</li>
            <li><h5>Surgery: </h5>In severe or complicated cases, surgical intervention may be required to drain abscesses or remove part of the epididymis (epididymectomy).</li>
          </ul>
          <h3>Homoeopathic Treatment</h3>
          <ul>
            <li><h5>Apis Mellifica:</h5> Indicated for epididymitis with marked swelling, redness, and stinging pain. It is particularly useful when there is a sensation of heat in the affected area.</li>
            <li><h5>Belladonna:</h5>For cases with a sudden onset of intense pain, heat, and swelling. The affected area may be red and very tender to touch.</li>
            <li><h5>Pulsatilla: </h5>Effective for epididymitis with shifting pain that is worse in warm environments and better with cool applications. This remedy is often used for individuals with a gentle, weepy disposition.</li>
            <li><h5>Arnica Montana:  </h5>Useful for epididymitis resulting from trauma or injury, characterized by bruised pain and soreness.</li> 
            <li><h5>Mercurius Solubilis:</h5>For cases with purulent discharge, swollen lymph nodes, and significant inflammation. The patient may also experience excessive sweating and a tendency to feel cold.</li>
            </ul>
          <h3>Conclusion</h3>
          <p>Homoeopathy offers a holistic and individualized approach to treating epididymitis, focusing on the patient’s overall well-being and specific symptoms. In our experience, patients often experience significant relief from pain, reduced swelling, and improved recovery times with Homoeopathic treatment. By addressing the underlying causes and promoting the body’s natural healing processes, Homoeopathy can be an effective complementary approach to conventional treatments, particularly for those seeking a natural and less invasive option. Our experience shows that individualized Homoeopathic remedies can enhance the quality of life for patients with epididymitis, providing a safe and effective alternative to traditional medical treatments.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Epididymitis Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Epididymitis conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/male-genital/epididymitis/epididymitis-diet">Diet for Epididymitis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Epididymitis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Epididymitis</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Epididymitis Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/3YXbreT64Bs?si=VhdOWd-iKv-qnCPw"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
