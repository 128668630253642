import React from 'react';
import '../css/VideoCaseStudies.css';

const videoCaseStudiesData = [
  {
    title: 'Review of Migraine Treatment',
    videoSrc: 'https://www.youtube.com/embed/Lx1jl9bZxOY?si=dOhMVeyp7crXlMSa',
  },
  {
    title: 'Allergic Rhinitis Treatment Review',
    videoSrc: 'https://www.youtube.com/embed/XRvtK184uVM?si=3E96MbaF2bWSGW21',
  },
  {
    title: 'Infertility Treatment Review',
    videoSrc: 'https://www.youtube.com/embed/VEPV3oBV_2I?si=LyIAv40dvLzLnz4j',
  },
  {
    title: 'Rheumatoid Arthritis Treatment Review',
    videoSrc: 'https://www.youtube.com/embed/xKqDadi2Llc?si=HTbYXSyGYzgzA2S_',
  },
  {
    title: 'Hypothyroidism Treatment Review',
    videoSrc: 'https://www.youtube.com/embed/m92CGom7t9o?si=2Yc0ppgkABe8OCF0',
  },
  {
    title: 'Osteoarthritis Treatment Review',
    videoSrc: 'https://www.youtube.com/embed/MWP5BRTWCGU?si=XUjhHWjiKKlnWnUz',
  },
  {
    title: 'Infertility Case Study',
    videoSrc: 'https://www.youtube.com/embed/jGZzwUiyZGk?si=bJPqqTiVhIIjwzaG',
  },
  {
    title: 'Diabetes Treatment Review',
    videoSrc: 'https://www.youtube.com/embed/a2k89dSBhic?si=_QHzJQCeSnU_cS_h',
  },
  {
    title: 'Asthma Treatment Review',
    videoSrc: 'https://www.youtube.com/embed/P3aQh9kK4vM?si=17Hf40tjW9a_1jRX',
  }
];

const VideoCaseStudies = () => {
  return (
    <div className="video-case-studies-page">
      <div className="video-hero-section">
      <div className='video-new'>
      <h1>Video Case Studies</h1>
      <h2>Homoeopathy takes care of everything</h2>
      </div>
        {/* <img src={require('../images/video_hero.jpg')} alt="Hero" className="video-hero-img" /> */}
      </div>

      <div className="container video-case-studies-container">
        {videoCaseStudiesData.map((videoCase, index) => (
          <div className="video-card" key={index}>
            <iframe
              width="560"
              height="315"
              src={videoCase.videoSrc}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
            <p className="video-title">{videoCase.title}</p>
           {/* <p className="disclaimer">***Disclaimer: Results may vary from person to person</p>*/}
          </div>
        ))}
      </div>
      <div className="contact-map-row">
        <div className="map-card">
          <h3>Our Location</h3>
          <iframe
            title="Niramaya Homoeopathy Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d114008.58764245966!2d83.31226966300316!3d26.751766994497036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39914486bfb2c0e9%3A0xaf54983a8579801c!2sDr%20Atul%20kumar%20singh%20(BHMS%2C%20MD%2C%20PG%20London)%20Homeopath!5e0!3m2!1sen!2sin!4v1728539308354!5m2!1sen!2sin"
            width="100%"
            height="250"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>

        <div className="contact-us-card">
          <h3>Contact Us</h3>
          <p>For inquiries and appointments, please contact us:</p>
          <p><strong>Phone:</strong><a href="tel:+919236185711" className="contact-link">+919236185711</a>          </p>
          <p><strong>Email:</strong><a href="mailto:niramayaforyou@gmail.com" className="contact-link">niramayaforyou@gmail.com</a> </p>
          <p><strong>Address:</strong> P9RG+WV3, Cantt Rd, Bansgaon Colony, Kalepur, Gorakhpur, Uttar Pradesh 273001</p>
        </div>
      </div>
    </div>
    
  );
};

export default VideoCaseStudies;
