import React from 'react';
import '../../css/KidneyApproach.css'; // Import the updated CSS file

const KidneyApproach = () => {
  return (
    <div className="kidney-approach-page">
      <h1 className="kidney-approach-title">Approach for Kidney Stone Treatment</h1>
      <p className="kidney-approach-paragraph">
        Before going for treatment of kidney stones, the first choice should be an expert homeopathic doctor because
        whenever a doctor is not experienced, kidney stone treatment becomes complicated and, due to obstructive
        pathology, the kidney may get damaged.
      </p>
      <p className="kidney-approach-paragraph">
        This is undoubtedly the key principle on which kidney stone treatment is based: our holistic approach with
        personalized care and a preventive focus.
      </p>

      <h2 className="kidney-approach-subtitle">Comprehensive Treatment Approach</h2>
      <p className="kidney-approach-paragraph">
        Whenever we treat a kidney stone case, the approach depends on the site, size, and symptomatic presentation of
        the stone. The treatment plan depends on the size because treating kidney stones larger than 5 mm is more
        difficult than treating stones smaller than 5 mm.
      </p>
      <p className="kidney-approach-paragraph">
        Expulsion of a 5 mm kidney stone is easier because the ureter is more than 5 mm across its course except in
        three places:
      </p>
      <ul className="kidney-approach-list">
        <li>The pelvic ureteric junction</li>
        <li>The midline of the ureter</li>
        <li>The ureterovesical junction</li>
      </ul>
      <p className="kidney-approach-paragraph">
        These are the three places where a stone can become stuck, and the case can become surgical. So, we need to
        adopt a comprehensive integrity mode of homeopathic medication.
      </p>

      <h3 className="kidney-approach-heading">Integrated Homeopathic Treatment</h3>
      <p className="kidney-approach-paragraph">
        Niramaya Homeopathy adopts the integrated mode of homeopathic medication. Our constitution-based medicine breaks
        the stones parallel to dilating the ureter and urethra to facilitate the expulsion of stones from the ureter,
        bladder, and urethra.
      </p>

      <h2 className="kidney-approach-subtitle">Proper Case History of Patient</h2>
      <p className="kidney-approach-paragraph">
        In this process, first of all, we individualize the patient by an appropriate study of the case in which we
        focus on the symptomatic presentation of the stones as well as the modality and concomitants found in patients'
        symptomatology.
      </p>

      <h3 className="kidney-approach-heading">Evaluation of Symptoms</h3>
      <p className="kidney-approach-paragraph">
        Evaluation of symptoms is an essential part of homeopathic key management regarding kidney stone treatment.
        According to the hierarchy of symptoms, we arrange strange, rare, uncommon, and peculiar symptoms regarding the
        kidney stone case. Common symptoms are not as important as rare and peculiar symptoms in selecting homeopathic
        medicines. The patient's mental and general conditions are also essential when we complete a proper case history
        of the kidney stone patient.
      </p>

      <h3 className="kidney-approach-heading">Role of Investigation</h3>
      <p className="kidney-approach-paragraph">
        Investigations play a very significant role in kidney stone treatment. When treating larger kidney stones, it is
        crucial to ensure that kidney function tests (KFT, GFR, UACR) are within standard parameters. If not, surgical
        intervention may be required. Otherwise, regular ultrasonography, X-rays, and NCCT KUB are recommended to
        monitor the progress of ongoing homeopathic treatment.
      </p>

      <h2 className="kidney-approach-subtitle">Role of Diet and Precautions</h2>
      <p className="kidney-approach-paragraph">
        Without precautions and diet management, kidney stone cases cannot be treated. Patients should drink water
        regularly to stay hydrated and reduce sodium and protein intake. Citrus fruits play a significant role in
        reducing oxalate sedimentation, which causes kidney stones. At our clinic, we re-educate patients regarding
        their precautions and diet management.
      </p>

      <h2 className="kidney-approach-subtitle">Role of Lifestyle Changes</h2>
      <p className="kidney-approach-paragraph">
        Lifestyle plays a significant role in the treatment of kidney stones. Regular exercise, such as walking 5000
        steps a day and performing moderate exercises, increases the likelihood of stone removal with homeopathic
        medicines.
      </p>

      <h2 className="kidney-approach-subtitle">Role of Pain Management</h2>
      <p className="kidney-approach-paragraph">
        When kidney or ureter stones obstruct the urine pathway, they cause pain, discomfort, and symptoms like nausea,
        vomiting, and hematuria. Many patients fear these severe symptoms, but this is where homeopathic medicine plays
        a quick and effective role. It dilates the ureter, facilitates muscle contractions, and helps remove stones.
      </p>
    </div>
  );
};

export default KidneyApproach;
