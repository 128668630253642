import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
   
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/arf.webp')}alt="Urology Hero" />
        <h1>Acute Renal Failure (ARF) Treatment</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Acute Renal Failure (ARF)</h2>
          <img src={require('../images/kidney9.webp')}alt="Urology Hero" />
          <h3>Causes</h3>
          <ul>
            <li><h5>Prerenal Causes:</h5>Disproportionate levels of calcium, oxalate, uric acid, or other minerals in the urine contribute to stone formation.</li>
            <li><h5>Dehydration:</h5>Insufficient fluid intake leads to concentrated urine, facilitating mineral crystallization.</li>
            <li><h5>Dietary Factors:</h5>High intake of oxalate-rich foods (e.g., spinach, nuts), sodium, or animal protein.</li>
            <li><h5>Medical Conditions:</h5> Such as hyperparathyroidism, gout, urinary tract infections, or genetic predisposition.</li>
            <li><h5>Obesity:</h5> Associated with increased urinary calcium and uric acid levels, contributing to stone formation.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li><h5>Medical History and Physical Examination: </h5>Assess symptoms, risk factors, and overall health status.</li>
            <li><h5>Imaging: </h5>X-ray, CT scan, or ultrasound to visualize stones, determine their size, location, and assess potential complications.</li>
            <li><h5>Urinalysis:</h5>Examination of urine composition to identify crystals and abnormalities.</li>
            <li><h5>Blood Tests:</h5> Evaluate kidney function, mineral levels, and detect underlying conditions.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Severe Pain:</h5>Intense pain in the back, sides, abdomen, or groin as stones move through the urinary tract.</li>
            <li><h5>Obstruction:</h5>Stones can block urine flow, causing acute pain, urinary tract infections, or kidney damage.</li>
            <li><h5>Recurrence:</h5>Previous stone formation increases the likelihood of future episodes.</li>
            <li><h5>Infection:</h5> Stones can harbor bacteria, leading to urinary tract infections.</li>
            <li><h5>Chronic Kidney Disease:</h5> Prolonged obstruction or recurrent stones may impair kidney function over time.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Pain Management:</h5>NSAIDs, opioids, or antispasmodics for pain relief.</li>
            <li><h5>Hydration:</h5>Encouraged fluid intake to facilitate stone passage.</li>
            <li><h5>Medical Expulsion Therapy:</h5>Medications to relax ureter muscles and aid stone passage.</li>
            <li><h5>Extracorporeal Shock Wave Lithotripsy (ESWL):</h5> Non-invasive procedure using shock waves to break stones into smaller fragments.</li>
            <li><h5>Ureteroscopy:</h5>Minimally invasive procedure to remove or fragment stones in the ureter or kidney.</li>
            <li><h5>Percutaneous Nephrolithotomy (PCNL):</h5> Surgical procedure to remove larger stones from the kidney.</li>
            <li><h5>Preventive Measures: </h5>Dietary modifications (e.g., reduced sodium, oxalate, protein intake), increased fluid intake, medications to manage underlying conditions.</li>
          </ul>
          <h3>Homoeopathic Treatment</h3>
          <p>Homoeopathy offers remedies based on stone type and individual symptoms:</p>
          <ul>
            <li><h5>Berberis Vulgaris: </h5> For renal colic with shooting pains radiating to the bladder, associated with burning urine.</li>
            <li><h5>Lycopodium:</h5>Right-sided kidney stones, pain extending to the bladder, bloating, and digestive issues.</li>
            <li><h5>Cantharis:</h5>Intense burning pain, frequent, urgent urination.</li>
            <li><h5>Sarsaparilla:</h5> Gravel or sand-like sediment in urine, pain at the end of urination.</li>
            <li><h5>Belladonna:</h5> Sudden, intense pain with fever, flushed face.</li>
            <li><h5>Apis Mellifica:</h5> Stinging pain, swelling, burning sensation.</li>
            <li><h5>Pareira Brava:</h5>Bladder retention, painful urination, stones in the bladder.</li>
          </ul>
          <h3>Conclusion / Our Experience</h3>
          <p>Homoeopathy provides a natural and comprehensive approach to managing kidney stones, focusing on symptom relief, facilitating stone passage, preventing recurrence, and promoting overall kidney health. Our clinical experience indicates that Homoeopathic treatment can effectively alleviate acute pain, aid in the passage of stones, and reduce the frequency of stone formation.</p>
          <h3>Key Strategies for Effective Management:</h3>
          <ul>
            <li><h5>Individualized Care: </h5>Tailoring treatment based on specific symptoms, stone type, and overall health.</li>
            <li><h5>Hydration and Dietary Guidance:</h5>Encouraging adequate fluid intake and dietary modifications to prevent stone recurrence.</li>
            <li><h5>Integrated Approach:</h5>Collaborating with conventional medical interventions when necessary for optimal patient care.</li>
            <li><h5>Lifestyle Adjustments:</h5>Emphasizing healthy habits, including diet, hydration, regular exercise, and stress management.</li>
            <li><h5>Monitoring and Follow-up:</h5>Regular evaluation to monitor stone status, kidney function, and overall well-being.</li>
          </ul>
          <h3>Holistic Care Model:</h3>
          <p>
          Homoeopathy’s holistic approach not only addresses acute symptoms but also aims to improve kidney function, prevent future episodes, and enhance quality of life for individuals with kidney stones. By focusing on natural healing and personalized treatment plans, Homoeopathy supports long-term kidney health and well-being.
          </p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Acute Renal Failure (ARF) Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Acute Renal Failure (ARF) related conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/urology/acute-renal-failure/acute-renal-failure-diet">Diet for Acute Renal Failure (ARF)</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention from Acute Renal Failure (ARF)</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Acute Renal Failure (ARF) Health</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Acute renal failure Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/zGSAQgy0Vw8?si=CcbJh6rn93RhxSLl"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
