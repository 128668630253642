// import React from 'react';
// import '../css/Feedback.css';

// const Feedback = () => {
  
//   return (
//     <div className="feedback-page">
//       <div className="heroo-section">
//         <img src={require('../images/feedbackhero.jpg')} alt="Hero" className="heroo-img" />
//         <div className="heroo-overlay">
//           <h1 className="heroo-title">We Value Your Feedback</h1>
//           <p className="heroo-text">Help us improve by sharing your experience with Niramaya Homoeopathy.</p>
//         </div>
//       </div>

//       <div className="feedback-form-section">
//         <h2>Leave Your Feedback</h2>
//         <form className="feedback-form">
//           <input type="text" placeholder="Your Name" required />
//           <input type="email" placeholder="Your Email" required />
//           <textarea placeholder="Your Feedback" rows="4" required></textarea>
//           <button type="submit">Submit Feedback</button>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default Feedback;








import React, { useState } from 'react';
import '../css/Feedback.css';

const Feedback = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    feedback: '',
  });

  const [responseMessage, setResponseMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="feedback-page">
      <div className="heroo-section">
        <div className='feedback-new'>
          <h1>We Value Your Feedback</h1>
          <h2>Help us improve by sharing your experience</h2>
          <h2>with Niramaya Homoeopathy</h2>
        </div>
        {/* <img src={require('../images/feed_hero.jpg')} alt="Hero" className="heroo-img" /> */}
      </div>

      <div className="feedback-form-section">
        <h2>Leave Your Feedback</h2>
        <form 
          className="feedback-form" 
          action="https://api.web3forms.com/submit" 
          method="POST"
          onSubmit={() => setResponseMessage("Feedback submitted successfully!")}
        >
          {/* Access Key for Web3Forms */}
          <input
            type="hidden"
            name="access_key"
            value="90277967-72b9-4089-93c9-5ec2b7137a38" // Replace with your actual Web3Forms access key
          />
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
          <textarea
            name="feedback"
            placeholder="Your Feedback"
            rows="4"
            value={formData.feedback}
            onChange={handleInputChange}
            required
          ></textarea>
          <button type="submit">Submit Feedback</button>
        </form>
        {responseMessage && <p>{responseMessage}</p>}
      </div>
    </div>
  );
};

export default Feedback;































// import React, { useState } from 'react';
// import '../css/Feedback.css';

// const Feedback = () => {
//   // State to store the form data
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     feedback: '',
//   });

//   // State to store API response or error message
//   const [responseMessage, setResponseMessage] = useState('');

//   // Handle form input change
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   // Handle form submit
//   const handleSubmit = (e) => {
//     e.preventDefault();

//     // Call the API using fetch
//     fetch('http://192.168.29.66:8000/niramayabackend/feedback/', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(formData), // Send form data as JSON
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         // Handle successful response
//         if (data.success) {
//           setResponseMessage(data.success);
//           setFormData({ name: '', email: '', feedback: '' }); // Clear form
//         } else {
//           setResponseMessage('Failed to submit feedback. Please try again.');
//         }
//       })
//       .catch((error) => {
//         // Handle errors
//         setResponseMessage('An error occurred. Please try again.');
//         console.error('Error:', error);
//       });
//   };

//   return (
//     <div className="feedback-page">
//       <div className="heroo-section">
//       <div className='feedback-new'>
//       <h1>We Value Your Feedback</h1>
//       <h2>Help us improve by sharing your experience </h2>
//       <h2>with Niramaya Homoeopathy</h2>
//       </div>
//         {/* <img src={require('../images/feed_hero.jpg')} alt="Hero" className="heroo-img" /> */}
//       </div>

//       <div className="feedback-form-section">
//         <h2>Leave Your Feedback</h2>
//         <form className="feedback-form" onSubmit={handleSubmit}>
//           <input
//             type="text"
//             name="name"
//             placeholder="Your Name"
//             value={formData.name}
//             onChange={handleInputChange}
//             required
//           />
//           <input
//             type="email"
//             name="email"
//             placeholder="Your Email"
//             value={formData.email}
//             onChange={handleInputChange}
//             required
//           />
//           <textarea
//             name="feedback"
//             placeholder="Your Feedback"
//             rows="4"
//             value={formData.feedback}
//             onChange={handleInputChange}
//             required
//           ></textarea>
//           <button type="submit">Submit Feedback</button>
//         </form>
//         {responseMessage && <p>{responseMessage}</p>}
//       </div>
//     </div>
//   );
// };

// export default Feedback;
