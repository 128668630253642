import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/treat4.webp')}alt="Urology Hero" />
        {/* <h1>Reactive arthritis (ReA) Treatment</h1> */}
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Reactive arthritis (ReA)</h2>
          <img src={require('../images/joint6.webp')}alt="Urology Hero" />
          <h3>Causes</h3>
          <p>Reactive arthritis occurs when the immune system, after fighting off an infection, mistakenly attacks healthy tissues in the joints, eyes, and urethra (the tube that carries urine out of the body). While the exact cause is unknown, certain infections are known triggers:</p>
          <ul>
            <li><h5>Bacterial infections:</h5> These commonly involve the intestines (e.g., Shigella, Salmonella, Campylobacter) or the genitourinary tract (e.g., Chlamydia).</li>
            <li>Less commonly, viral or other infections can also trigger reactive arthritis.</li>
          </ul>
          <h3>Symptoms </h3>
          <p>Symptoms of reactive arthritis can appear weeks or even months after the initial infection. They may include:</p>
          <ul>
            <li><h5>Joint pain, stiffness, and swelling:</h5>Typically affecting the knees, ankles, or feet, often asymmetrical (affecting different joints on opposite sides of the body).</li>
            <li><h5>Morning stiffness:</h5>Lasting for at least 30 minutes after waking up.</li>
            <li><h5>Eye inflammation (conjunctivitis):</h5>This can cause redness, irritation, and pain in one or both eyes.</li>
            <li><h5>Urinary problems: </h5>Burning sensation during urination (dysuria) or frequent urination (urinary frequency) may occur in some cases.</li>
            <li><h5>Skin lesions:</h5>Small, painless bumps or sores on the soles of the feet or palms of the hands can develop in some people.</li>
          </ul>
          <h3>Investigations </h3>
          <p>Diagnosing reactive arthritis can be challenging as there’s no specific test. A doctor will typically consider:</p>
          <ul>
            <li><h5>Medical history:</h5>Learning about any recent infections and your overall health.</li>
            <li><h5>Physical examination:</h5> Checking your joints for swelling, tenderness, and warmth, and examining your eyes and skin for signs of inflammation.</li>
            <li><h5>X-rays: </h5>To assess for joint damage, although X-rays may be normal in early stages.</li>
            <li><h5>Blood tests: </h5> Looking for signs of inflammation and ruling out other conditions.</li>
            <li><h5>Other tests:</h5>In some cases, additional tests like joint fluid analysis or MRI may be used to confirm the diagnosis.</li>
          </ul>
          <h3>Complications</h3>
          <p>While not life-threatening, reactive arthritis can lead to complications like:</p>
          <ul>
            <li><h5>Joint damage: </h5>Chronic inflammation can erode cartilage and bone, leading to joint deformity and disability.</li>
            <li><h5>Vision problems: </h5>Severe eye inflammation can damage the cornea, potentially impacting vision.</li>
            <li><h5>Long-term pain: </h5>Symptoms can persist for months or even years, impacting daily life.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <p>There is no cure for reactive arthritis, but treatment focuses on managing symptoms, reducing inflammation, and preventing joint damage. It may involve:</p>
          <ul>
            <li><h5>Medications: </h5>Pain relievers (NSAIDs), medications to suppress the immune system (disease-modifying antirheumatic drugs or DMARDs), and corticosteroids can help manage pain and inflammation.</li>
            <li><h5>Physical therapy: </h5>Exercises to improve joint flexibility, strength, and range of motion.</li>
            <li><h5>Occupational therapy: </h5>Learning techniques to perform daily activities with less joint strain.</li>
            <li><h5>Lifestyle modifications: </h5>Maintaining a healthy weight, eating a balanced diet, and getting enough sleep can all support overall well-being.</li>
          </ul>
          <h3>Conclusion</h3>
          <p>Reactive arthritis can be a challenging condition, but with proper diagnosis and treatment, you can manage symptoms and maintain a good quality of life. If you experience persistent joint pain, stiffness, or other symptoms after an infection, consult a doctor for evaluation and discuss treatment options. Remember, I cannot recommend Homoeopathic treatments as they lack scientific backing.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Reactive arthritis (ReA) Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Reactive arthritis (ReA) conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/joint-bone/reactive-arthritis/reactive-arthritis-diet">Diet for Reactive arthritis (ReA)</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Reactive arthritis (ReA)</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Reactive arthritis (ReA)</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Reactive arthritis Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/03EBkuUzmpo?si=cQKKaw2MX7JIOnrI"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
