import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/pedia6.webp')}alt="Urology Hero" />
        <h1>Gastric trouble Treatment</h1>
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Gastric trouble</h2>
          <img src={require('../images/child6.webp')}alt="Urology Hero" />
        <h3> Basic Homoeopathic Approach</h3>
          <p>
          Homoeopathy approaches gastric trouble by addressing individual symptoms and underlying causes, aiming to restore digestive balance using natural remedies. Remedies are selected based on the principle of “like cures like,” where substances causing symptoms in healthy individuals are used in highly diluted forms to treat similar symptoms in patients.
            </p>
          <h3>Causes</h3>
          <p>Gastric trouble can stem from various factors:</p>
          <ul>
            <li><h5>Dietary Habits:</h5> Poor eating habits, excessive consumption of spicy or fatty foods.</li>
            <li><h5>Stress:</h5> Emotional stress or anxiety impacting digestive processes.</li>
            <li><h5>Infections:</h5> Bacterial or viral infections affecting the gastrointestinal tract.</li>
            <li><h5>Medications:</h5> Certain drugs can irritate the stomach lining.</li>
            <li><h5>Medical Conditions:</h5> GERD, gastritis, ulcers, or other digestive disorders.</li>
          </ul>
          <h3>Investigations</h3>
          <p>Diagnosing gastric trouble may involve:</p>
          <ul>
            <li><h5>Medical History:</h5> Detailed discussion about symptoms, diet, and medical conditions.</li>
            <li><h5>Physical Examination:</h5> Abdominal examination to assess tenderness or abnormalities.</li>
            <li><h5>Diagnostic Tests:</h5>Including endoscopy, stool tests, breath tests for H. pylori, or imaging studies to evaluate the digestive system.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <p>Untreated gastric trouble can lead to complications like:</p>
          <ul>
            <li><h5>Ulcers:</h5> Sores in the stomach lining.</li>
            <li><h5>Malnutrition:</h5> Inadequate nutrient absorption due to impaired digestion.</li>
            <li><h5>Esophagitis:</h5> Inflammation of the esophagus from stomach acid reflux.</li>
            <li><h5>Bleeding:</h5> Ulcers or stomach lining irritation leading to bleeding.</li>
            <li><h5>Increased Infection Risk:</h5> Reduced stomach acid increasing susceptibility to gastrointestinal infections.</li>
          </ul>
          <p>Risk factors may include:</p>
          <ul>
            <li><h5>Diet:</h5> Spicy/fatty foods, alcohol, caffeine.</li>
            <li><h5>Stress:</h5> Chronic stress affecting digestion.</li>
            <li><h5>Smoking:</h5> Tobacco use exacerbating gastric issues.</li>
            <li><h5>Medications:</h5> NSAIDs, steroids, antibiotics.</li>
            <li><h5>Medical Conditions:</h5> GERD, gastritis, ulcers.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <p>Conventional treatments include:</p>
          <ul>
            <li><h5>Dietary Adjustments:</h5> Avoiding triggers, smaller meals.</li>
            <li><h5>Medications:</h5> Antacids, PPIs, H2-receptor antagonists.</li>
            <li><h5>Lifestyle Changes: </h5>Stress management, quitting smoking, reducing alcohol.</li>
            <li><h5>Treating Underlying Conditions:</h5> Antibiotics for H. pylori, ulcer healing meds, GERD treatments.</li>
          </ul>
          <h3>Homoeopathic Supportive Care</h3>
          <p>Homoeopathy offers remedies tailored to gastric issues:</p>
          <ul>
            <li><h5>Nux Vomica:</h5> Overeating, spicy food, alcohol.</li>
            <li><h5>Pulsatilla:</h5> Heaviness after rich/fatty foods.</li>
            <li><h5>Arsenicum Album:</h5> Burning pains, anxiety.</li>
            <li><h5> Lycopodium:</h5> Bloating, gas, fullness.</li> 
            <li><h5>Carbo Vegetabilis:</h5> Bloating, belching, flatulence.</li>
            <li><h5>Natrum Phosphoricum: </h5>  Acidity, sour belching, heartburn.</li>
            </ul>
          <h3>Conclusion</h3>
          <p>Homoeopathy complements conventional care, offering gentle relief and addressing root causes for improved digestive health. Personalized treatment plans support long-term wellness, tailored for individual needs. Consulting a qualified homeopath ensures effective assessment and treatment, promoting balanced digestive function and overall well-being.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Gastric trouble Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Gastric trouble conditions.</p>                   
            <button className="appointments-btn">Book Appointment</button>
          </div>                                                                                                                     

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/pediatric/gastric-trouble/gastric-trouble-diet">Diet for Gastric trouble</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Gastric trouble</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Gastric trouble</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Gastric trouble Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/mhrVOXgDDEA?si=kehTjuU1luhZRhdO"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
