import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/fh5.webp')}alt="Urology Hero" />
        <h1>Dysfunctional Uterine Bleeding (DUB)</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Dysfunctional Uterine Bleeding (DUB)</h2>
          <img src={require('../images/female5.webp')}alt="Urology Hero" />
        <h3> Basic Homoeopathic Approach</h3>
          <p>
          Homoeopathy addresses dysfunctional uterine bleeding (DUB) by treating the individual holistically, considering physical, emotional, and lifestyle factors. The aim is to restore balance and support the body’s natural healing processes through highly personalized remedies. Practitioners conduct detailed consultations to understand the patient’s unique symptoms and overall health, tailoring treatment plans accordingly.          </p>
          <h3>Causes</h3>
          <p>DUB can be caused by various factors, often related to hormonal imbalances:</p>
          <ul>
            <li><h5>Hormonal Imbalance:</h5>Irregularities in estrogen and progesterone levels disrupt the menstrual cycle.</li>
            <li><h5>Anovulation:</h5>Absence of ovulation leads to continuous estrogen exposure without progesterone, causing heavy and irregular bleeding.</li>
            <li><h5>Polycystic Ovary Syndrome (PCOS):</h5>A condition marked by multiple cysts on the ovaries, hormonal imbalance, and irregular periods.</li>
            <li><h5> Thyroid Disorders:</h5>Both hyperthyroidism and hypothyroidism can impact menstrual regularity and cause abnormal bleeding.</li>
            <li><h5> Obesity:</h5>Excess body weight influences hormone production and can lead to menstrual irregularities.</li>
            <li><h5>Medications:</h5>Drugs such as anticoagulants, hormonal contraceptives, and antipsychotics can cause abnormal uterine bleeding.</li>
            <li><h5>Chronic Diseases:</h5>Conditions like diabetes, liver disorders, and kidney disease can affect menstrual health.</li>
            <li><h5>Stress:</h5>High levels of stress can disrupt hormonal balance and menstrual cycles, leading to DUB.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li><h5>Medical History and Physical Examination: </h5>Essential for identifying potential underlying causes and assessing overall health.</li>
            <li><h5>Blood Tests: </h5>To evaluate hormone levels, thyroid function, and other relevant indicators such as clotting factors and complete blood count.</li>
            <li><h5>Pelvic Ultrasound:</h5>Provides detailed images of the uterus, ovaries, and other pelvic structures to identify abnormalities like fibroids or polyps.</li>
            <li><h5>Endometrial Biopsy: </h5>Sampling the uterine lining to check for abnormal or cancerous cells.</li>
            <li><h5>Hysteroscopy:  </h5>Using a camera to inspect the inside of the uterus for polyps, fibroids, or other issues.</li>
            <li><h5>Laparoscopy:</h5>Minimally invasive procedure to examine the pelvic organs and rule out conditions like endometriosis.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Anemia:</h5>Chronic heavy bleeding can lead to significant iron deficiency anemia, causing fatigue and weakness.</li>
            <li><h5>Infertility:</h5>Conditions like PCOS associated with DUB can impair fertility.</li>
            <li><h5>Chronic Pelvic Pain:</h5>Often linked to underlying conditions causing DUB, such as endometriosis or fibroids.</li>
            <li><h5>Increased Risk of Endometrial Hyperplasia or Cancer:</h5>Prolonged exposure to unopposed estrogen can lead to abnormal thickening of the uterine lining.</li>
            <li><h5>Psychological Impact:</h5>Chronic menstrual issues can lead to stress, anxiety, and depression, affecting overall well-being.</li>
            <li><h5>Obesity:</h5>Exacerbates hormonal imbalances and increases the risk of menstrual disorders.</li>
            <li><h5>Family History:</h5>Genetic predisposition to hormonal imbalances and menstrual irregularities.</li>
            <li><h5>Age:</h5>Adolescents and women approaching menopause are more prone to hormonal fluctuations causing DUB.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Medications:</h5></li>
            <li><h5>Hormonal Therapies:</h5>Birth control pills, hormone-releasing IUDs, and hormone replacement therapy to regulate menstrual cycles and reduce bleeding.</li>
            <li><h5>Nonsteroidal Anti-Inflammatory Drugs (NSAIDs):</h5>Help reduce menstrual pain and bleeding.</li>
            <li><h5>Tranexamic Acid: </h5>Used to reduce heavy menstrual bleeding.</li>
            <li><h5>Lifestyle Modifications: </h5>Stress management techniques, weight loss programs, balanced diet, and regular physical activity to maintain overall health and hormonal balance.</li>
            <li><h5>Surgical Options: </h5></li>
            <li><h5>Dilation and Curettage (D&C): </h5>Scraping of the uterine lining to manage heavy bleeding and diagnose potential abnormalities.</li>
            <li><h5>Endometrial Ablation:</h5>Destroying the uterine lining to reduce or stop bleeding.</li>
            <li><h5>Myomectomy: </h5>Surgical removal of fibroids while preserving the uterus.</li>
            <li><h5>Hysterectomy: </h5>Complete removal of the uterus in severe cases or when other treatments fail.</li>    
          </ul>
          <h3>Homoeopathic Treatment</h3>
          <p>Homoeopathy offers a range of remedies tailored to the individual’s specific symptoms and overall health:</p>
          <ul>
            <li><h5>Pulsatilla:</h5>For irregular periods, often accompanied by mood swings and a preference for open air and mild weather.</li>
            <li><h5>Sepia:</h5>Effective for irregular cycles, pelvic heaviness, bearing down sensation, and irritability, especially in women feeling overwhelmed and exhausted.</li>
            <li><h5>Calcarea Carbonica:</h5>Suitable for women with heavy, prolonged periods, a tendency to feel cold, and associated fatigue and anxiety.</li>
            <li><h5>Lachesis:</h5>Helps with symptoms that worsen before menstruation, including hot flashes, emotional intensity, and sensitivity around the abdomen.</li>
            <li><h5>Chamomilla:</h5>For severe menstrual pain, irritability, hypersensitivity, and restlessness.</li>
            <li><h5>Nux Vomica:</h5>Beneficial for irregular periods, digestive disturbances, stress-related symptoms, and sensitivity to stimuli.</li>
          </ul>
          <h3>Conclusion</h3>
          <p>Homoeopathy provides a natural and individualized approach to managing dysfunctional uterine bleeding, aiming to restore hormonal balance and address underlying causes. Our clinical experience indicates that Homoeopathic treatment can significantly alleviate symptoms and enhance overall quality of life. Key to successful management is early intervention, a personalized selection of remedies, and regular follow-ups. Integrating Homoeopathic care with lifestyle modifications—such as stress management, a balanced diet, and regular exercise—enhances treatment efficacy and promotes long-term health. Regular monitoring through appropriate diagnostic tools ensures progress is tracked and treatment plans are adjusted as needed, ensuring optimal outcomes for the patient.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Dysfunctional Uterine Bleeding</h3>
            <p>We offer natural, safe, and effective treatment for Dysfunctional Uterine Bleeding conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/female-troubles/dysfunctional-uterine-bleeding/dysfunctional-uterine-bleeding-diet">Diet for Dysfunctional Uterine Bleeding</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention from Dysfunctional Uterine Bleeding</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Dysfunctional Uterine Bleeding</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Dysfunctional Uterine Bleeding Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/xKqDadi2Llc?si=bBOvhd4tAopAOWsv"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
      </div>
  );
};

export default KidneyPage;
