import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/bph.webp')}alt="Urology Hero" />
        <h1>BPH Prostate Treatment</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>BPH Prostate</h2>
          <img src={require('../images/kidney3.webp')}alt="Urology Hero" />
        <h3> Basic Homoeopathic Approach</h3>
          <p>
          Homoeopathy treats Benign Prostatic Hyperplasia (BPH) by considering the individual’s unique symptoms and overall health. Remedies are selected based on the principle of “like cures like,” aiming to stimulate the body’s natural healing processes. Treatment involves a holistic approach, addressing both physical symptoms and emotional well-being. The goal is to reduce the size of the prostate, alleviate urinary symptoms, and improve the patient’s quality of life. Homoeopathy focuses on strengthening the body’s immune system and balancing the hormonal levels that influence prostate growth.
          </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Aging:</h5>BPH is a common part of aging, with the risk increasing significantly as men grow older.</li>
            <li><h5>Hormonal Changes:</h5>Alterations in testosterone and estrogen levels contribute to prostate growth. An increase in dihydrotestosterone (DHT) levels is particularly implicated in BPH.</li>
            <li><h5>Genetics:</h5>Family history of BPH can increase the risk, indicating a genetic predisposition.</li>
            <li><h5>Lifestyle Factors:</h5>Diet, physical activity, and obesity can influence the development of BPH. High-fat diets and lack of physical activity are linked to increased risk.</li>
            <li><h5>Medical Conditions:</h5>Conditions like diabetes and heart disease are associated with an increased risk of BPH, possibly due to their impact on blood flow and hormonal balance.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li><h5>Medical History and Physical Examination: </h5>A detailed medical history and physical exam, including a digital rectal exam (DRE), are essential for diagnosing BPH.</li>
            <li><h5>Prostate-Specific Antigen (PSA) Test: </h5>Measures the level of PSA in the blood to rule out prostate cancer. Elevated PSA levels can indicate BPH or other prostate issues.</li>
            <li><h5>Urine Flow Test (Uroflowmetry):</h5>Assesses the flow rate and volume of urine, helping to detect obstructions in the urinary tract.</li>
            <li><h5>Postvoid Residual Volume Test:</h5>Measures the amount of urine left in the bladder after urination, indicating how well the bladder is emptying.</li>
            <li><h5>Transrectal Ultrasound (TRUS):</h5>Provides detailed images of the prostate to assess its size and detect abnormalities. It helps in guiding biopsies if needed.</li>
            <li><h5>Cystoscopy:</h5>Allows direct visualization of the bladder and urethra to identify obstructions and assess the severity of the condition.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Urinary Retention:</h5>Inability to urinate, leading to bladder discomfort and potential damage. Acute urinary retention can be a medical emergency.</li>
            <li><h5>Bladder Stones:</h5>Form due to stagnant urine in the bladder, causing irritation and infection.</li>
            <li><h5>Recurrent UTIs:</h5>Frequent infections due to incomplete bladder emptying, leading to bacteria growth.</li>
            <li><h5>Bladder Damage:</h5>Overstretching or weakening of the bladder muscles due to chronic obstruction, reducing bladder function.</li>
            <li><h5>Kidney Damage:</h5>Backflow of urine (hydronephrosis) can affect the kidneys, leading to potential kidney damage.</li>
            <li><h5>Risk Factors:</h5>Age, family history, lifestyle factors (such as high-fat diets and sedentary lifestyle), and medical conditions like diabetes and heart disease increase the risk of developing BPH.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <h4>Medications:</h4>
            <li><h5>Alpha-blockers:</h5>Relax the muscles of the prostate and bladder neck to improve urine flow (e.g., tamsulosin).</li>
            <li><h5>5-alpha-reductase inhibitors:</h5>Reduce the size of the prostate by inhibiting hormonal changes (e.g., finasteride).</li>
            <h4>Minimally Invasive Procedures:</h4><p>Techniques like transurethral microwave therapy (TUMT) and transurethral needle ablation (TUNA) reduce prostate tissue using heat or radiofrequency energy.</p>
            <h4>Surgery:</h4>
            <li><h5>Transurethral Resection of the Prostate (TURP): </h5>Removes part of the prostate to relieve obstruction.</li>
            <li><h5>Laser Surgery:</h5> Uses laser energy to remove excess prostate tissue.</li>
            <li><h5>Open Prostatectomy: </h5>Reserved for very large prostates or complex cases.</li>
            <h4>Lifestyle Changes:</h4>
            <li><h5>Dietary modifications: </h5>Reducing intake of caffeine and alcohol, increasing fiber.</li>
            <li><h5>Regular exercise:</h5>Promotes overall health and may reduce symptoms.</li>
            <li><h5>Managing fluid intake: </h5>Reducing evening fluid intake to prevent nocturia (frequent urination at night).</li>
          </ul>
          <h3>Homoeopathic Treatment</h3>
          <ul>
            <li><h5>Sabalis Serrulata (Saw Palmetto):  </h5>Effective for frequent urination, especially at night, with a sensation of fullness in the bladder.</li>
            <li><h5>Conium Maculatum: </h5>Used for difficulty in starting urination and weak urine flow, often accompanied by a sense of incomplete emptying.</li>
            <li><h5>Chimaphila Umbellata: </h5>For a constant urge to urinate with pain and dribbling. It helps reduce prostate swelling.</li>
            <li><h5>Thuja Occidentalis: </h5>Prescribed for a weak stream and interrupted flow of urine, particularly in cases with associated prostate enlargement.</li>
            <li><h5>Baryta Carbonica: </h5> Helpful in older men with BPH, addressing frequent urination, dribbling, and symptoms of an enlarged prostate.</li>
          </ul>
          <h3>Conclusion / Our Experience</h3>
          <p>Homoeopathic treatment for BPH focuses on individualized care and holistic healing. By addressing both physical symptoms and emotional aspects, Homoeopathy aims to promote overall well-being and prevent the progression of BPH. Many patients have reported significant improvement in urinary symptoms and quality of life with consistent Homoeopathic care. Our experience has shown that with the correct remedy selection and patient adherence, Homoeopathy can be an effective complementary approach to managing BPH. This approach not only alleviates symptoms but also supports the body’s natural healing processes, leading to long-term health benefits. Homoeopathy offers a gentle and non-invasive alternative to conventional treatments, emphasizing the importance of treating the whole person rather than just the disease.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>BPH Prostate Treatment</h3>
            <p>We offer natural, safe, and effective treatment for BPH Prostate and other BPH Prostate-related conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/urology/bph-prostate/bph-diet">Diet for BPH Prostate</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention from BPH Prostate</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for BPH Prostate Health</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on BPH Prostrate Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/XTxBwdVUVr8?si=eeNJvVET8EyBeS1i"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
