import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/mih3.webp')}alt="Urology Hero" />
        <h1>Brain tumor Treatment</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Brain tumor</h2>
          <img src={require('../images/head3.webp')}alt="Urology Hero" />
        <h3> Basic Homoeopathic Approach</h3>
          <p>
          Homoeopathy may offer supportive care in managing symptoms associated with brain tumors. It aims to support overall health, alleviate symptoms, and improve quality of life through individualized remedies chosen based on the patient’s symptoms and constitution. However, it is important to note that Homoeopathy is not a substitute for conventional medical treatment for brain tumors.
          </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Genetic Factors:</h5>Some brain tumors have genetic predispositions.</li>
            <li><h5>Environmental Factors:</h5>Exposure to certain chemicals or radiation can increase the risk.</li>
            <li><h5>Unknown Factors: </h5>In many cases, the exact cause of brain tumors remains unknown.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li><h5>Imaging Studies:</h5>MRI or CT scans are used to diagnose and monitor the size and location of brain tumors.</li>
            <li><h5>Biopsy: </h5>A tissue sample is taken and examined under a microscope to determine the type of tumor.</li>
            <li><h5>Neurological Examination:</h5>Evaluates neurological function and symptoms related to the tumor.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Neurological Impairment:</h5>Depending on the location and size, tumors can cause neurological deficits.</li>
            <li><h5>Increased Intracranial Pressure: </h5>Tumors can lead to a buildup of pressure within the skull, causing headaches, nausea, and vomiting.</li>
            <li><h5>Seizures:</h5>Some tumors can trigger seizures due to abnormal electrical activity in the brain.</li>
            <li><h5>Impact on Cognitive Function: </h5>Cognitive changes such as memory loss or difficulties with speech and comprehension can occur.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Surgery:</h5>To remove as much of the tumor as possible without causing damage to surrounding brain tissue.</li>
            <li><h5>Radiation Therapy:</h5>Uses high-energy beams to target and destroy cancer cells.</li>
            <li><h5>Chemotherapy:</h5>Drugs are used to kill cancer cells or prevent their growth..</li>
            <li><h5>Targeted Therapy:</h5>Drugs that specifically target certain molecules involved in cancer growth.</li>
          </ul>
          <h3>Homoeopathic Supportive Care</h3>
          <ul>
            <li><h5>Symptomatic Relief: </h5>Remedies like Belladonna or Bryonia may help alleviate symptoms such as headaches or nausea associated with brain tumors.</li>
            <li><h5>Supportive Treatment:</h5>Homoeopathic remedies are chosen based on the patient’s individual symptoms and constitution to support overall health and well-being during conventional treatment.</li>
          </ul>
          <h3>Conclusion</h3>
          <p>While Homoeopathy may provide supportive care and symptom relief for patients with brain tumors, it is crucial for individuals to receive appropriate medical diagnosis and treatment from qualified healthcare professionals. Homoeopathic treatment should always complement, rather than replace, conventional medical therapies for serious conditions like brain tumors.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Brain tumor Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Brain tumor conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/head/brain-tumor/brain-tumor-diet">Diet for Brain tumor</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Brain tumor</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Brain tumor </a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Brain tumor Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/JJqIwUMqkF8?si=UZFjKE8niPHOQYPT"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
