import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/mgh1.webp')}alt="Urology Hero" />
        <h1>Varicocele Treatment</h1>
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Varicocele</h2>
          <img src={require('../images/male1.webp')}alt="Urology Hero" />
        <h3> Basic Homoeopathic Approach</h3>
          <p>
          Homoeopathy approaches varicoceles holistically, focusing on improving venous circulation, reducing discomfort, and addressing associated fertility concerns. Remedies are selected based on individual symptoms, overall health, and constitutional characteristics to stimulate the body’s self-healing mechanisms.          </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Venous Insufficiency:</h5>Dysfunction in the valves of the spermatic veins, leading to blood pooling and dilation.</li>
            <li><h5>Genetic Predisposition: </h5>Family history of varicoceles increases susceptibility.</li>
            <li><h5>Obstruction: </h5>Blockage or compression of veins due to anatomical factors or increased intra-abdominal pressure.</li>
            <li><h5>Hormonal Imbalances: </h5>Disruptions in testosterone or other hormone levels may contribute.</li>
            <li><h5>Physical Strain: </h5>Heavy lifting, prolonged standing, or activities increasing intra-abdominal pressure.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li><h5>Clinical Examination: </h5>Physical assessment to palpate for swelling or enlargement in the scrotum.</li>
            <li><h5>Ultrasound:</h5> Imaging to confirm the presence, size, and severity of varicoceles.</li>
            <li><h5>Semen Analysis:</h5>Evaluation of sperm count, motility, and morphology to assess fertility implications.</li>
            <li><h5>Doppler Ultrasound:</h5>Specialized imaging to assess blood flow and detect any abnormalities.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <h4>Complications</h4>
          <ul>
            <li><h5>Male Infertility:</h5>Varicoceles can impair sperm production and quality, affecting fertility.</li>
            <li><h5>Testicular Atrophy: </h5>Reduced testicular size due to chronic venous congestion.</li>
            <li><h5>Chronic Discomfort:</h5>Aching, heaviness, or dragging sensation in the scrotum, especially during physical exertion.</li>
            </ul>
            <h4>Risk Factors</h4>
            <ul>
            <li><h5>Age:</h5>Typically diagnosed during adolescence or early adulthood.</li>
            <li><h5>Occupational Factors:</h5>Jobs involving prolonged sitting or heavy lifting.</li>
            <li><h5>Postural Habits: </h5>Prolonged standing or sedentary lifestyle contributing to venous congestion.</li>
            <li><h5>Previous Trauma or Surgery:</h5>History of scrotal injury or surgery increasing risk.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Surgical Intervention (Varicocelectomy): </h5>Removal or ligation of dilated veins to redirect blood flow and restore normal venous function.</li>
            <li><h5>Embolization:</h5>Minimally invasive procedure using coils or sclerosing agents to block off affected veins.</li>
            <li><h5>Medications:</h5>Pain relief medications or anti-inflammatory drugs for symptomatic relief.</li>
            <li><h5>Watchful Waiting: </h5>Monitoring without intervention unless symptoms worsen or fertility issues arise.</li>
          </ul>
          <h3>Homoeopathic Treatment</h3>
          <p>Homoeopathy offers tailored remedies to alleviate symptoms and support overall health in varicoceles. Commonly prescribed remedies include:</p>
          <ul>
            <li><h5>Arnica montana:</h5>For bruised, sore sensation and inflammation in the scrotum.</li>
            <li><h5>Pulsatilla:</h5> For varicoceles accompanied by a sensation of heaviness, improved by cold applications.</li>
            <li><h5>Hamamelis virginiana: </h5>For dragging or throbbing pain in the scrotum with a sense of fullness.</li>
            <li><h5>Lycopodium clavatum:</h5>For right-sided varicoceles with digestive disturbances and bloating.</li> 
            <li><h5>Nux Vomica:</h5>For varicoceles worsened by sedentary lifestyle, stress, and overwork.</li>
            </ul>
          <h3>Conclusion</h3>
          <p>In our clinical experience, Homoeopathic treatment can complement conventional approaches in managing varicoceles by alleviating symptoms and supporting overall reproductive health. While surgical intervention remains the primary treatment for significant varicoceles affecting fertility, Homoeopathy can provide symptomatic relief and improve the well-being of patients. Collaboration with a healthcare provider is essential to tailor treatment plans to individual needs and monitor progress effectively. Regular follow-up ensures the effectiveness of Homoeopathic remedies and allows adjustments as needed. Overall, Homoeopathy offers a gentle and holistic approach to managing varicoceles, aiming to enhance quality of life and support reproductive health through personalized care and natural remedies.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Varicoceles Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Varicoceles conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/male-genital/varicocele/varicocele-diet">Diet for Varicoceles</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Varicoceles</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Varicoceles</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Varicoceles Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/85QHSjbX6uE?si=2BD0zm-iYsp_2fFZ"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
