import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/pedia7.webp')}alt="Urology Hero" />
        <h1>Recurrent pneumonia Treatment</h1>
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Recurrent pneumonia</h2>
          <img src={require('../images/child7.webp')}alt="Urology Hero" />
        <h3> Basic Homoeopathic Approach</h3>
          <p>
          Homoeopathy offers a holistic approach to recurrent pneumonia, focusing on strengthening the immune system and addressing underlying susceptibilities. Remedies are chosen based on individual symptoms and overall health, aiming to reduce the frequency and severity of episodes naturally.          </p>
          <h3>Causes</h3> 
          <p>Recurrent pneumonia may stem from various factors:</p>
          <ul>
            <li><h5>Immune System Weakness:</h5>Reduced ability to fight infections.</li>
            <li><h5>Chronic Health Conditions: </h5>Such as asthma, COPD, or immune deficiencies.</li>
            <li><h5>Environmental Factors: </h5>Exposure to pollutants, smoke, or allergens.</li>
            <li><h5>Anatomical Abnormalities: </h5>Structural issues in the lungs or airways.</li>
            <li><h5>Infections: </h5>Viral, bacterial, or fungal infections predisposing to pneumonia.</li>
          </ul>
          <h3>Investigations</h3>
          <p>Diagnosing recurrent pneumonia involves:</p>
          <ul>
            <li><h5>Medical History : </h5>Detailed review of previous episodes, health conditions, and environmental exposures.</li>
            <li><h5>Physical Examination: </h5> Including lung auscultation and respiratory assessment.</li>
            <li><h5>Diagnostic Tests:</h5>Chest X-ray, blood tests (CBC, inflammatory markers), sputum culture, or imaging to evaluate lung function.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <p>Complications of recurrent pneumonia can include:</p>
          <ul>
            <li><h5>Chronic Respiratory Problems: </h5>Persistent cough and breathing difficulties.</li>
            <li><h5>Lung Damage:</h5> Scarring or fibrosis.</li>
            <li><h5>Respiratory Failure:</h5> Sometimes necessitating oxygen therapy or mechanical ventilation.</li>
            <li><h5>Secondary Infections:</h5> Increased susceptibility to other respiratory illnesses.</li>
            <li><h5>Systemic Effects:</h5> Prolonged illness impacting overall health.</li>
          </ul>
          <p>Risk factors may involve:</p>
          <ul>
            <li><h5>Age: </h5>Extremes of age are at higher risk.</li>
            <li><h5>Smoking:</h5>Active or passive exposure.</li>
            <li><h5>Chronic Disease:</h5>Conditions weakening immunity or affecting lung health.</li>
            <li><h5>Environmental Exposures: </h5>Pollutants, allergens, or occupational hazards.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <p>Conventional treatments typically include:</p>
          <ul>
            <li><h5>Antibiotics:</h5> Targeting bacterial causes.</li>
            <li><h5>Bronchodilators: </h5>For associated conditions like asthma or COPD.</li>
            <li><h5>Corticosteroids:</h5> In severe cases to reduce inflammation.</li>
            <li><h5>Oxygen Therapy:</h5> For low oxygen levels.</li>
            <li><h5>Vaccinations: </h5> Influenza and pneumococcal vaccines for prevention.</li>
          </ul>
          <h3>Homoeopathic Supportive Care</h3>
          <p>Homoeopathy provides personalized remedies for recurrent pneumonia:</p>
          <ul>
            <li><h5>Hepar Sulph: </h5>For frequent respiratory infections aggravated by cold air.</li>
            <li><h5>Phosphorus: </h5>For acute pneumonia with rapid onset, chest pain, and dry cough.</li>
            <li><h5>Arsenicum Album: </h5>For anxiety, restlessness, and burning pains.</li>
            <li><h5>Antimonium Tart:</h5> For rattling cough and difficulty clearing mucus.</li> 
            <li><h5>Bryonia: </h5> For pneumonia worsened by movement with sharp pains.</li>
            <li><h5>Calcarea Carb:</h5> For recurrent pneumonia in children, clammy hands and feet.</li>
            </ul>
            <p>Remedies are selected based on symptoms and health status, administered in diluted forms to promote healing with minimal side effects.</p>
          <h3>Conclusion</h3>
          <p>In our experience, integrating Homoeopathy into treatment plans for recurrent pneumonia can effectively reduce episodes and enhance overall respiratory health. Homoeopathic remedies support the immune system & Homoeopathic medicine for typhoid, address underlying factors, and promote natural healing. Each treatment plan is tailored to individual needs, aiming for sustained improvement and reducing reliance on conventional medications. Consulting with a qualified homeopath ensures thorough assessment, personalized treatment, and comprehensive support for optimal respiratory function and well-being.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Recurrent pneumonia Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Recurrent pneumonia conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/pediatric/recurrent-pneumonia/recurrent-pneumonia-diet">Diet for Recurrent pneumonia</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Recurrent pneumonia</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Recurrent pneumonia</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Recurrent pneumonia Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/FfAM9hWeCBk?si=5e9I1_dTkX9JDOUN"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
