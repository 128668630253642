import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Urology.css'; // Include your custom CSS here

const UrologyPage = () => {
  return (
    <div className="urology-page">
      {/* Hero Image */}
      <div className="uro-hero-image">
        <img src={require('../images/treat11.webp')}alt="Urology Hero" />
       
      </div>

      {/* Main content section */}
      <div className="uro-content-section">
        {/* Disease Links */}
        <div className="disease-links">
          <h1>Related Skin Problem</h1>
          <ul>
            <li><Link to="/treatments/skin/molluscum-contagiosum">Molluscum contagiosum</Link></li>
            <li><Link to="/treatments/skin/lichen-planus">Lichen planus (LP)</Link></li>
            <li><Link to="/treatments/skin/hair-fall">Hair fall</Link></li>
            <li><Link to="/treatments/skin/acne">ACNE</Link></li>
            <li><Link to="/treatments/skin/alopecia">Alopecia</Link></li>
            <li><Link to="/treatments/skin/psoriasis">Psoriasis</Link></li>
            <li><Link to="/treatments/skin/vitiligo">Vitiligo</Link></li>
            <li><Link to="/treatments/skin/nails-disorder">Nails Disorder</Link></li>
            <li><Link to="/treatments/skin/chloasma-melasma">Chloasma / Melasma</Link></li>
            <li><Link to="/treatments/skin/contact-dermatitis">Contact Dermatitis</Link></li>
            <li><Link to="/treatments/skin/urticaria">Urticaria</Link></li>
            <li><Link to="/treatments/skin/keloid">Keloid</Link></li>
          </ul>
        </div>

        {/* Right Side Content */}
        <div className="uro-right-content">
          <div className="uro-search-box">
            <h3>Search</h3>
            <div className="uro-search-bar">
              <input type="text" placeholder="Search..." />
              <button>Search</button>
            </div>
          </div>
          <div className="recent-posts">
            <h3>Recent Posts</h3>
            <ul>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Understanding Skin Problem</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Best Diet & Homoeopathic medicine for skin disease & hair</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>FAQs about skin & hair</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Treatment Options for skin & hair</a></li>
            </ul>
          </div>
        </div>
      </div>
       <div className="uro-contact-us-card">
        <h2>Contact Us</h2>
        <p>
          Have any questions about urological health? Feel free to get in touch with us for expert advice, consultation, or to book an appointment.
        </p>
        <p>
        📞 Phone: <a href="tel:+919236185711">+919236185711</a><br />
          📧 Email: <a href="mailto:niramayaforyou@gmail.com">niramayaforyou@gmail.com</a><br />
          📍 Address: 0230 B Civil Lines 2 Bilandpur near DIG Bunglow, Gorakhpur, Uttar Pradesh, India, 273001
        </p>
        <button className="appointment-btn">Book Appointment</button>
      </div>
    </div>
  );
};

export default UrologyPage;
