import React from 'react';
import '../css/Kidney.css'; // Add custom styles here
const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/gh8.webp')}alt="Urology Hero" />
        <h1>Gallstone</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Gallstone</h2>
          <img src={require('../images/gastric8.webp')}alt="Urology Hero" />
        <h3> Basic Homoeopathic Approach</h3>
        <p>
        Homoeopathy treats gallstones by considering the individual’s overall health, including physical, mental, and emotional aspects. The goal is to relieve symptoms, promote the expulsion of gallstones when possible, and prevent recurrence by addressing underlying causes such as poor digestion and liver function. Homoeopathic remedies are selected based on the specific symptoms and constitution of the individual, aiming to restore balance and support the body’s natural healing processes.
        </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Excess Cholesterol:</h5>When there is too much cholesterol in the bile, it can solidify into stones.            </li>
            <li><h5>Bile Imbalance:</h5>If the liver produces an imbalance of bile salts and cholesterol, gallstones can form.            </li>
            <li><h5>Incomplete Gallbladder Emptying:</h5>When the gallbladder doesn’t empty completely, bile can become concentrated, leading to stone formation.            </li>
            <li><h5>Obesity and Poor Diet:</h5>A diet high in fats, cholesterol, and low in fiber can increase the risk of gallstones.            </li>
            <li><h5>Rapid Weight Loss:</h5>Quick weight loss can cause the liver to release extra cholesterol, which can lead to gallstone formation.            </li>
            <li><h5>Medical Conditions:</h5>Conditions such as diabetes, Crohn's disease, and cirrhosis increase the risk of gallstones.            </li>
          </ul>
          <h3>Symptoms of Anal Fissures</h3>
          <ul>
            <li><h5>Upper Abdominal Pain:</h5>Intense pain, often on the right side, which may radiate to the back or right shoulder. Pain typically occurs after eating a fatty meal.            </li>
            <li><h5>Nausea and Vomiting:</h5>Gallstone attacks often cause nausea and vomiting, particularly after meals.            </li>
            <li><h5>Indigestion:</h5>Bloating, gas, and indigestion are common symptoms of gallstones.            </li>
            <li><h5>Jaundice:</h5>If a gallstone blocks the bile duct, it can lead to yellowing of the skin and eyes due to bilirubin buildup.            </li>
            <li><h5>Dark Urine and Pale Stools:</h5>Blockages in the bile ducts can affect the colour of urine and stools, resulting in dark urine and pale stools.            </li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Cholecystitis:</h5>Inflammation of the gallbladder caused by blockage from gallstones, leading to severe pain and fever.            </li>
            <li><h5>Biliary Colic:</h5>Sharp pain caused by a gallstone temporarily blocking the bile duct, usually after a meal.            </li>
            <li><h5>Pancreatitis:</h5>Gallstones can block the pancreatic duct, leading to inflammation of the pancreas.            </li>
            <li><h5>Gallbladder Cancer:</h5>Long-term inflammation and irritation from gallstones increase the risk of gallbladder cancer.            </li>
            <li><h5>Septicemia:</h5>An infection resulting from gallstones can lead to blood poisoning, a serious and potentially life-threatening condition.            </li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Medication:</h5>Pain relievers and anti-inflammatory drugs are prescribed to relieve symptoms. In some cases, medications like ursodiol are used to dissolve cholesterol-based stones, though this process is slow and not always effective.            </li>
            <li><h5>Surgery:</h5>Cholecystectomy (gallbladder removal surgery) is often recommended, particularly for recurrent or severe gallstone attacks.            </li>
            <li><h5>Extracorporeal Shock Wave Lithotripsy (ESWL):</h5>A non-invasive procedure that uses shock waves to break up stones so they can be passed naturally.            </li>         
            <li><h5>Endoscopic Retrograde Cholangiopancreatography (ERCP):</h5>A minimally invasive procedure to remove stones from the bile duct using a flexible tube with a camera.            </li>
            <li><h5>Lifestyle and Dietary Changes:</h5>A diet low in fats and cholesterol, combined with regular exercise, can help prevent gallstone formation.            </li>
            </ul>
            <h3>Homoeopathic Treatment</h3>
          <ul>
            <li><h5>Chelidonium Majus:</h5>One of the most commonly used remedies for gallstones, especially when there is pain in the right upper abdomen radiating to the back, and jaundice. It is helpful for individuals with digestive issues, particularly when symptoms are worse after eating fatty foods.            </li>
            <li><h5>Lycopodium:</h5>Effective for bloating, gas, and pain in the right side of the abdomen that worsens in the evening. It is especially useful for those with digestive disturbances and intolerance to foods such as beans, cabbage, and dairy products.         </li>
            <li><h5>Calcarea Carbonica:</h5>Suitable for individuals with a tendency to obesity and sluggish digestion. It is recommended for people who experience sour belching, heartburn, and abdominal pain, particularly after consuming fatty foods.            </li>         
            <li><h5>Nux Vomica:</h5>Ideal for individuals with a history of overindulgence in rich foods, alcohol, or stimulants. It helps with symptoms of nausea, vomiting, and intense cramping pain in the upper abdomen.            </li>
            <li><h5>Carduus Marianus:</h5>Known as a liver and gallbladder tonic, it is beneficial for people with jaundice, liver congestion, and a history of gallstone attacks. It also helps with digestive issues like bloating, constipation, and a bitter taste in the mouth.            </li>
            </ul>
          <h3>Conclusion / Our Experience</h3>
          <p>
          In our experience, homoeopathy offers a supportive approach for managing gallstones by addressing the underlying causes and providing symptom relief. Many individuals report improvement in symptoms such as pain, bloating, and indigestion, and some have even been able to avoid surgery with consistent homoeopathic treatment. Homoeopathy serves as an effective complementary approach for those seeking natural and gentle options, particularly for managing small gallstones or after undergoing conventional treatments. While it may not replace conventional treatment for severe cases, homoeopathy can play a valuable role in long-term management and prevention.
          </p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Gallstone Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Gallstone.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/gastric-issues/gallstone/gallstone-diet">Diet for preventing Gallstone</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention from Gallstone</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for relief of Gallstone</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Gallstone Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/IJHrCUzTT4E?si=AKO2toBp-u5bG7FP"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
