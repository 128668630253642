import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/fh3.webp')}alt="Urology Hero" />
        <h1>Uterine Fibroid</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Uterine Fibroid</h2>
          <img src={require('../images/female3.webp')}alt="Urology Hero" />
        <h3> Basic Homoeopathic Approach</h3>
          <p>
          Homoeopathy seeks to treat the individual holistically, addressing both physical and emotional aspects. The goal is to stimulate the body’s natural healing processes by choosing remedies that match the unique symptom profile of the patient. Homoeopathic practitioners conduct a comprehensive examination, considering factors such as the patient’s lifestyle, diet, mental state, and specific symptoms to devise a personalized treatment plan.         
        </p>
          <h3>Causes</h3>
          <p>
          The exact cause of uterine fibroids remains unclear, but several factors may contribute:
          </p>
          <ul>
            <li><h5>Hormonal Imbalance:</h5>Estrogen and progesterone, hormones that regulate the menstrual cycle, play a key role in fibroid growth.</li>
            <li><h5>Genetic Factors:</h5>A family history of fibroids increases susceptibility.</li>
            <li><h5>Growth Factors:</h5>Substances that help maintain tissues may also influence fibroid development.</li>
            <li><h5>Extracellular Matrix (ECM):</h5> The ECM, which makes cells stick together, is increased in fibroids and makes them fibrous.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li><h5>Pelvic Examination:</h5>A routine exam can often detect fibroids.</li>
            <li><h5>Ultrasound: </h5>Transvaginal or pelvic ultrasound provides confirmation and details about fibroid size and location.</li>
            <li><h5>MRI:</h5>Offers detailed imaging, crucial for treatment planning, especially for surgical options.</li>
            <li><h5>Hysterosonograph:</h5>Enhances ultrasound imaging by using saline solution to expand the uterine cavity.</li>
            <li><h5>Hysteroscopy:</h5>Involves inserting a camera into the uterus to inspect the uterine walls and fallopian tube openings.</li>
            <li><h5>Biopsy:</h5>Occasionally performed to rule out cancer if there are atypical symptoms.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Heavy Menstrual Bleeding:</h5>Can lead to significant anemia and fatigue.</li>
            <li><h5>Pain:</h5>Includes pelvic pain, lower back pain, and pain during intercourse.</li>
            <li><h5>Bladder Issues:</h5>Such as frequent urination or difficulty emptying the bladder completely.</li>
            <li><h5>Reproductive Problems:</h5>Infertility, recurrent pregnancy loss, and complications during pregnancy and labor.</li>
            <li><h5>Rapid Growth:</h5>Sudden increase in fibroid size can indicate a rare, cancerous form called leiomyosarcoma.</li>
            <li><h5>Race: </h5>African-American women are at a higher risk.</li>
            <li><h5>Obesity:</h5>Increased body weight is a significant risk factor.</li>
            <li><h5>Diet:</h5>High consumption of red meat and low intake of green vegetables may contribute to the development of fibroids.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Medications:</h5>Used to regulate the menstrual cycle, alleviate symptoms, and shrink fibroids. Options include.</li>
            <li><h5>Gonadotropin-Releasing Hormone (GnRH) Agonists:</h5>Temporarily shrink fibroids by blocking hormone production.</li>
            <li><h5>Progestin-Releasing IUDs:</h5>Provide symptom relief without shrinking fibroids.</li>
            <li><h5>Anti-Inflammatory Drugs:</h5>Help reduce pain and bleeding.</li>
            <li><h5>Non-Invasive Procedures:</h5>MRI-guided focused ultrasound surgery (FUS) uses high-frequency sound waves to destroy fibroid tissue.</li>
            <li><h5>Minimally Invasive Procedures:</h5></li>
            <li><h5>Uterine Artery Embolization (UAE): </h5>Cuts off the blood supply to fibroids, causing them to shrink.</li>
            <li><h5>Traditional Surgical Procedures:</h5></li>
            <li><h5>Abdominal Myomectomy: </h5>Removes fibroids through a larger abdominal incision.</li>
            <li><h5>Hysterectomy: </h5>Complete removal of the uterus, ensuring fibroids do not recur.</li>
          </ul>
          <h3>Homoeopathic Treatment</h3>
          <ul>
            <li><h5>Calcarea Carbonica: </h5>Suited for fibroids with heavy menstrual bleeding, accompanied by weakness, and a tendency to feel cold.</li>
            <li><h5>Thuja Occidentalis:</h5>For fibroids with a sensation of a lump in the pelvis and associated with a history of vaccination issues.</li>
            <li><h5>Lachesis:</h5>Effective for fibroids with hot flashes, bleeding between periods, and abdominal sensitivity.</li>
            <li><h5>Sepia:</h5>For women experiencing a bearing down sensation in the pelvic region, irregular menstrual cycles, and emotional irritability.</li>
            <li><h5>Pulsatilla:</h5> Beneficial for women with changeable symptoms who feel better in fresh air and have a mild, yielding disposition.</li>
          </ul>
          <h3>Conclusion / Our Experience</h3>
          <p>Homoeopathic treatment for uterine fibroids aims to provide symptomatic relief and enhance overall well-being without the side effects commonly associated with conventional therapies. Successful treatment relies on the individualized approach of Homoeopathy, considering the patient’s unique constitution and symptoms. Our clinical experience suggests that early intervention and regular follow-ups are crucial for effective management. Consistent monitoring through ultrasounds and other diagnostic tools helps track progress and allows for necessary adjustments to the treatment plan, ensuring optimal outcomes for the patient exercise.</p>
          <h3>Key Strategies for Effective Management:</h3>
          <ul>
            <li><h5>Individualized Care: </h5>Tailoring treatment based on specific symptoms, stone type, and overall health.</li>
            <li><h5>Hydration and Dietary Guidance:</h5>Encouraging adequate fluid intake and dietary modifications to prevent stone recurrence.</li>
            <li><h5>Integrated Approach:</h5>Collaborating with conventional medical interventions when necessary for optimal patient care.</li>
            <li><h5>Lifestyle Adjustments:</h5>Emphasizing healthy habits, including diet, hydration, regular exercise, and stress management.</li>
            <li><h5>Monitoring and Follow-up:</h5>Regular evaluation to monitor stone status, kidney function, and overall well-being.</li>
          </ul>
          <h3>Holistic Care Model:</h3>
          <p>
          Homoeopathy’s holistic approach not only addresses acute symptoms but also aims to improve kidney function, prevent future episodes, and enhance quality of life for individuals with kidney stones. By focusing on natural healing and personalized treatment plans, Homoeopathy supports long-term kidney health and well-being.
          </p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Kidney Treatment</h3>
            <p>We offer natural, safe, and effective treatment for kidney stones and other kidney-related conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/female-troubles/uterine-fibroid/uterine-fibroid-diet">Diet for Uterine Fibroid</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Kidney Stones</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Kidney Health</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Uterine Fibroid Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/FP-HGv1WhpY?si=9WtehE0FQy1Pn_2k"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
