import React from 'react';
import '../css/Kidney.css'; // Add custom styles here
const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/gh11.webp')}alt="Urology Hero" />
        <h1>Appendicitis</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Appendicitis</h2>
          <img src={require('../images/gastric11.webp')}alt="Urology Hero" />
        <h3> Basic Homoeopathic Approach</h3>
        <p>
        Homoeopathy focuses on managing appendicitis by addressing the individual's symptoms, constitution, and overall health. Since appendicitis can become a medical emergency if left untreated, homoeopathic remedies are generally used for supportive care during early stages or for recovery after surgery. In acute cases, immediate medical intervention is essential. Homoeopathy can help reduce inflammation, relieve pain, and support healing, but should not replace conventional treatment for severe or advanced appendicitis.
        </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Blockage:</h5>A blockage in the lining of the appendix, often due to hardened stool, tumours, or foreign bodies, can lead to bacterial infection and inflammation.            </li>
            <li><h5>Infection:</h5>Infections in the digestive tract, such as a gastrointestinal virus, can cause the appendix to swell and become inflamed.            </li>
            <li><h5>Inflammatory Bowel Disease (IBD):</h5>Conditions like Crohn’s disease may increase the risk of appendicitis due to chronic inflammation in the intestines.            </li>
            <li><h5>Trauma:</h5>Physical injury or trauma to the abdominal area may sometimes lead to appendicitis.            </li>
            <li><h5>Parasites:</h5>In rare cases, parasitic infections can block the appendix, resulting in inflammation.            </li>
          </ul>
          <h3>Symptoms of Anal Fissures</h3>
          <ul>
            <li><h5>Abdominal Pain:</h5>Starts as a dull ache around the navel and later shifts to the lower right side of the abdomen. We provide Homoeopathic medicine for lower abdominal pain.           </li>
            <li><h5>Nausea and Vomiting:</h5>Often follows the onset of pain and can lead to reduced appetite and fatigue.            </li>
            <li><h5>Loss of Appetite:</h5>Many individuals with appendicitis experience a sudden decrease in appetite.            </li>
            <li><h5>Fever and Chills:</h5>A low-grade fever that may worsen as the condition progresses.            </li>
            <li><h5>Constipation or Diarrhoea:</h5>Changes in bowel movements, including difficulty passing gas, are common with appendicitis.            </li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Ruptured Appendix:</h5>If the appendix bursts, it can lead to peritonitis, a serious infection in the abdominal cavity that requires immediate surgery.            </li>
            <li><h5>Abscess:</h5>A pocket of pus may form around a ruptured appendix, leading to an abscess that can spread infection if not treated promptly.            </li>
            <li><h5>Peritonitis:</h5>Inflammation of the lining of the abdominal cavity due to infection, which can lead to sepsis, a life-threatening condition.            </li>
            <li><h5>Sepsis:</h5>Infection from a ruptured appendix can spread into the bloodstream, causing sepsis, which is potentially fatal if not treated urgently.            </li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Surgery (Appendectomy):</h5>The primary treatment for appendicitis is surgical removal of the appendix. This can be done through an open procedure or a minimally invasive laparoscopic procedure.            </li>
            <li><h5>Antibiotics:</h5>Given to reduce the risk of infection before and after surgery. In some cases, antibiotics may be used as initial treatment for mild appendicitis.            </li>
            <li><h5>Pain Management:</h5>Pain relievers may be administered to manage abdominal pain before and after surgery.            </li>         
            <li><h5>IV Fluids:</h5>Hydration and electrolyte management are important, especially if the patient is unable to eat or drink due to nausea or vomiting.            </li>
            </ul>
            <h3>Homoeopathic Treatment</h3>
          <ul>
            <li><h5>Belladonna:</h5>Useful for sudden onset of sharp, throbbing pain in the abdomen, accompanied by fever, flushed face, and sensitivity to touch. Symptoms may worsen with movement or pressure.            </li>
            <li><h5>Bryonia:</h5>Effective for severe pain in the lower abdomen that worsens with movement. The person may prefer to remain completely still and may feel thirsty.            </li>
            <li><h5>Arsenicum Album:</h5>Suitable for individuals experiencing burning pain in the abdomen, weakness, and restlessness. It is helpful for symptoms accompanied by nausea, vomiting, and anxiety.            </li>         
            <li><h5>Lycopodium:</h5>Recommended for right-sided abdominal pain, especially if the pain radiates to the back. Useful for digestive disturbances, such as bloating and gas.            </li>
            <li><h5>Mercurius Solubilis:</h5>Beneficial for individuals with intense abdominal pain, foul-smelling breath, sweating, and sensitivity to both hot and cold temperatures.            </li>
            </ul>
          <h3>Conclusion / Our Experience</h3>
          <p>
          In our experience, homoeopathy may be beneficial in the early stages of appendicitis to help alleviate symptoms or as a supportive measure following surgery to aid recovery. However, appendicitis is a serious condition that requires immediate medical attention, especially if there is severe pain, high fever, or risk of rupture. Homoeopathic remedies can complement conventional treatments but should not replace them in cases of acute appendicitis or emergencies. For those looking for a natural approach during recovery, homoeopathy can support overall healing and improve well-being. Always consult a healthcare provider to determine the best course of action.
          </p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Appendicitis Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Appendicitis.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/gastric-issues/appendicitis/appendicitis-diet">Diet for preventing Appendicitis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention from Appendicitis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for relief of Appendicitis</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Appendicitis Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/MJbQu91BDsg?si=rad48AfFjPZmDnuT"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
