import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/meh2.webp')}alt="Urology Hero" />
        <h1>Depression Treatment</h1>
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Depression</h2>
          <img src={require('../images/mental2.webp')}alt="Urology Hero" />
        <h3> Basic Homoeopathic Approach</h3>
          <p>
          Homoeopathy provides a holistic and individualized treatment for allergic rhinitis, focusing on the overall health and specific symptoms of the patient. Remedies are chosen based on the principle of “like cures like,” aiming to stimulate the body’s natural healing mechanisms. The treatment addresses the underlying sensitivity of the immune system, reducing the frequency and intensity of allergic reactions. It emphasizes a personalized approach, considering the patient’s physical, emotional, and environmental factors to achieve long-term relief and enhanced quality of life.
          </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Allergens:</h5>Common allergens include pollen, dust mites, mold, animal dander, and certain foods. Exposure to these triggers the immune system to overreact.</li>
            <li><h5>Genetic Predisposition:</h5>Family history of allergies or asthma increases the likelihood of developing allergic rhinitis.</li>
            <li><h5>Environmental Factors: </h5>Exposure to pollutants, tobacco smoke, and strong odors can exacerbate symptoms.</li>
            <li><h5>Seasonal Changes: </h5>Variations in pollen levels during different seasons can trigger symptoms, leading to seasonal allergic rhinitis (hay fever).</li>
            <li><h5>Infections: </h5>Upper respiratory infections can aggravate the condition and make symptoms more persistent.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li><h5>Medical History and Physical Examination: </h5>A detailed assessment of symptoms, triggers, and family history. Physical examination to check for signs of nasal inflammation, congestion, and other related symptoms.</li>
            <li><h5>Allergy Testing: </h5>Skin prick tests or blood tests (such as the radioallergosorbent test, or RAST) to identify specific allergens causing the reaction.</li>
            <li><h5>Nasal Endoscopy:</h5>A procedure using a thin, flexible tube with a camera to examine the inside of the nasal passages and sinuses for inflammation or other abnormalities.</li>
            <li><h5>Immunoglobulin E (IgE) Levels:</h5>Blood tests to measure the level of IgE antibodies, which are elevated in allergic conditions.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Chronic Sinusitis:</h5>Persistent nasal congestion and inflammation can lead to sinus infections.</li>
            <li><h5>Otitis Media:</h5>Allergic rhinitis can cause eustachian tube dysfunction, leading to middle ear infections, particularly in children.</li>
            <li><h5>Asthma:</h5>Allergic rhinitis is often associated with asthma, and uncontrolled symptoms can exacerbate asthma attacks.</li>
            <li><h5>Nasal Polyps:</h5>Chronic inflammation can lead to the formation of nasal polyps, which can obstruct nasal passages and complicate treatment.</li>
            <li><h5>Risk Factors:</h5>Family history of allergies, exposure to allergens, living in urban areas with high pollution levels, and having other allergic conditions like eczema or asthma.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Medications:</h5> Antihistamines to relieve sneezing, itching, and runny nose. Decongestants to reduce nasal congestion. Corticosteroid nasal sprays to reduce inflammation. Leukotriene receptor antagonists to block the action of inflammatory chemicals.</li>
            <li><h5>Allergen Avoidance:</h5> Identifying and avoiding exposure to known allergens through measures like using air purifiers, regularly cleaning bedding, and keeping windows closed during high pollen seasons.</li>
            <li><h5>Immunotherapy:</h5>Allergy shots or sublingual tablets to gradually desensitize the immune system to specific allergens.</li>
            <li><h5>Saline Nasal Irrigation:</h5>Using a saline solution to rinse the nasal passages and remove allergens and mucus.</li>
          </ul>
          <h3>Homoeopathic Supportive Care</h3>
          <ul>
            <li><h5>Allium Cepa: </h5>Indicated for watery, irritating nasal discharge with sneezing and sensitivity to light. Symptoms are often worse indoors and better in open air.</li>
            <li><h5>Sabadilla:</h5>Suitable for violent sneezing with itching in the nose, and watery nasal discharge, especially when symptoms are triggered by the smell of flowers.</li>
            <li><h5>Arsenicum Album: </h5>Used for frequent sneezing, burning nasal discharge, and restlessness. Symptoms are worse in cold air and at night, but better with warmth.</li>
            <li><h5>Natrum Muriaticum: </h5>Effective for clear, watery nasal discharge, loss of smell and taste, and a tendency to develop cold sores.</li> 
            <li><h5>Nux Vomica:</h5>Helpful for nasal congestion, especially at night and in the morning, with frequent sneezing and irritability. Symptoms often worsen in dry, cold weather.</li>
            </ul>
          <h3>Conclusion</h3>
          <p>Homoeopathic treatment for allergic rhinitis focuses on personalized care and holistic management. By addressing symptoms and underlying causes, Homoeopathy supports the body’s natural healing processes and improves overall well-being. Many patients experience significant relief from symptoms, reduced frequency of allergic reactions, and enhanced quality of life with consistent Homoeopathic care. Our experience demonstrates that with appropriate remedy selection and patient compliance, Homoeopathy can complement conventional treatments effectively. This approach not only alleviates symptoms but also promotes long-term health benefits, emphasizing individualized treatment and lifestyle adjustments. Homoeopathy offers a gentle and non-invasive alternative, emphasizing holistic care and restoring balance to promote optimal health and well-being.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Depression Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Depression conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/psychiatric/depression/depression-diet">Diet for Depression</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Depression</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Depression</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Depression Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/LBmw4duebJY?si=TpGbugPkRcNHI_ua"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
