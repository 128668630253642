import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/meh6.webp')}alt="Urology Hero" />
        <h1>Obsessive-compulsive disorder (OCD) Treatment</h1>
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Obsessive-compulsive disorder (OCD)</h2>
          <img src={require('../images/mental6.webp')}alt="Urology Hero" />
        <h3> Basic Homoeopathic Approach</h3>
          <p>
          Homoeopathy offers a holistic approach to treating Obsessive-Compulsive Disorder (OCD), focusing on the mental, emotional, and physical aspects of the individual. Remedies are selected based on the specific obsessive thoughts, compulsive behaviors, and underlying emotional triggers. The goal is to provide relief from anxiety, reduce compulsions, and address the root cause of the disorder through gentle, natural treatment.
</p>
          <h3>Causes</h3>
          <p>OCD is a mental health condition characterized by persistent, unwanted thoughts (obsessions) and repetitive behaviors (compulsions). Some contributing factors include:</p>
          <ul>
            <li><h5>Genetic Predisposition:</h5> A family history of OCD or other anxiety disorders can increase the likelihood of developing OCD.</li>
            <li><h5>Brain Chemistry:</h5> An imbalance in neurotransmitters like serotonin is believed to play a role in the development of OCD.</li>
            <li><h5>Stress and Trauma:</h5> High levels of stress or traumatic experiences may trigger or worsen OCD symptoms.</li>
            <li><h5>Personality Traits: </h5> Individuals with perfectionist tendencies or high levels of conscientiousness may be more prone to OCD.</li>
            <li><h5>Environmental Factors:</h5> Situational stressors, including lifestyle or work pressures, can exacerbate OCD symptoms.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li><h5>Psychiatric Evaluation:</h5> A thorough psychological assessment, including an examination of thoughts, feelings, and behavior patterns. </li>
            <li><h5>Clinical Interviews:</h5> Detailed interviews to assess the severity, type of obsessions, and compulsions, as well as any related mental health conditions such as anxiety or depression.</li>
            <li><h5>Rating Scales:</h5> Tools like the Yale-Brown Obsessive Compulsive Scale (Y-BOCS) are used to evaluate the severity of OCD.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Severe Anxiety:</h5> OCD is often accompanied by overwhelming anxiety that interferes with daily life.</li>
            <li><h5>Depression:</h5> Individuals with OCD may develop depression due to the chronic stress caused by intrusive thoughts and compulsions.</li>
            <li><h5>Impaired Functioning:</h5> OCD can significantly impair functioning at work, in relationships, and in social situations.</li>
            <li><h5>Avoidance Behavior:</h5> To prevent triggering obsessions or compulsions, individuals may avoid certain situations, leading to isolation. </li>
            <li><h5>Physical Effects: </h5> Repetitive behaviors, such as excessive hand-washing, may lead to physical harm, including skin irritation or infections.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Cognitive Behavioral Therapy (CBT): </h5> The most commonly used therapy for OCD, especially Exposure and Response Prevention (ERP), which helps individuals confront their fears and reduce compulsive behaviors.</li>
            <li><h5>Selective Serotonin Reuptake Inhibitors (SSRIs): </h5> These antidepressants are commonly prescribed to help reduce the severity of OCD symptoms.</li>
            <li><h5>Relaxation Techniques:</h5>  Practices such as mindfulness, meditation, or yoga are often used to reduce stress and anxiety.</li>
            <li><h5>Syphilinum: </h5> For compulsive hand-washing or fears of contamination, where the individual is highly focused on personal hygiene.</li>
            <li><h5>Calcarea Carbonica: </h5>For individuals who feel overwhelmed by responsibilities, leading to anxiety, repetitive thoughts, and compulsive behavior. There may also be a fear of failure and the need for security.</li>
            <li><h5>Lycopodium: </h5>For individuals who experience anticipatory anxiety, low self-confidence, and compulsive behaviors related to fear of making mistakes or being criticized.</li>
            <li><h5>Argentum Nitricum:</h5> For individuals who feel driven by impulsive thoughts and engage in ritualistic behavior to avoid imagined catastrophes. Anxiety and fear of losing control often accompany this state.</li>
            <li><h5>Silicea: </h5>For perfectionists who are very concerned about details, often leading to repetitive checking behaviors, such as making sure doors are locked or appliances are turned off.</li>
          </ul>
          <h3>Conclusion</h3>
          <p>Homoeopathy provides a natural and holistic approach to managing OCD by addressing the emotional and mental aspects of the disorder. By focusing on the individual’s unique symptom profile and underlying emotional triggers, Homoeopathy helps to reduce obsessive thoughts and compulsive behaviors. Clinical experience suggests that Homoeopathy can offer relief from the anxiety associated with OCD and support the individual’s mental and emotional well-being, improving quality of life over time.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Obsessive-compulsive disorder (OCD) Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Obsessive-compulsive disorder (OCD) conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/psychiatric/obsessive-compulsive-disorder/ocd-diet">Diet for Obsessive-compulsive disorder (OCD)</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Obsessive-compulsive disorder (OCD)</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Obsessive-compulsive disorder (OCD)</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Obsessive-compulsive disorder (OCD) Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/C1YBPwan6D4?si=mdECMlqQIo5wuxWt"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
