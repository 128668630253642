import React, { useState } from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleVideoClick = () => {
    setIsVideoPlaying(true);
  };
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/eh5.webp')}alt="Urology Hero" />
        <h1>Adenoids Treatment</h1>
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Adenoids</h2>
          <img src={require('../images/ent5.webp')}alt="Urology Hero" />
        <h3> Basic Homoeopathic Approach</h3>
          <p>
          Homoeopathy offers a holistic and individualized approach to treating enlarged adenoids. The treatment aims to reduce inflammation, alleviate symptoms, and enhance the immune system to prevent recurrence. Remedies are chosen based on the principle of “like cures like,” tailored to the patient’s specific symptoms, overall health, and emotional state. This personalized approach ensures long-term relief and improves the patient’s overall quality of life.
         </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Infections:</h5>Repeated infections from viruses and bacteria can lead to chronic inflammation and enlargement of the adenoids.</li>
            <li><h5>Allergies:</h5>Allergic reactions to substances like pollen, dust, or pet dander can cause the adenoids to swell.</li>
            <li><h5>Genetics: </h5> A hereditary predisposition can make some individuals more likely to develop enlarged adenoids.</li>
            <li><h5>Environmental Factors:</h5>Exposure to pollutants, tobacco smoke, and other irritants can contribute to the enlargement of the adenoids.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li><h5>Medical History and Physical Examination: </h5>Comprehensive evaluation of the patient’s symptoms and medical history, along with a physical examination to check for signs of enlarged adenoids.</li>
            <li><h5>X-rays: </h5>Imaging studies such as X-rays or CT scans can help determine the size and extent of adenoid enlargement.</li>
            <li><h5>Nasal Endoscopy:</h5>A flexible tube with a camera (endoscope) is inserted through the nose to visualize the adenoids and assess their size and condition.</li>
            <li><h5>Sleep Studies:</h5>In cases of sleep disturbances, a sleep study may be conducted to evaluate the impact of enlarged adenoids on breathing during sleep.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Recurrent Infections:</h5>Enlarged adenoids can block the nasal passages and Eustachian tubes, leading to frequent ear and sinus infections.</li>
            <li><h5>Obstructive Sleep Apnea:</h5>Enlarged adenoids can obstruct the airway, causing breathing difficulties during sleep, leading to disrupted sleep patterns and daytime fatigue.</li>
            <li><h5>Chronic Sinusitis:</h5>Persistent inflammation and obstruction can result in chronic sinusitis, with symptoms such as nasal congestion, facial pain, and postnasal drip.</li>
            <li><h5>Hearing Loss:</h5>Blockage of the Eustachian tubes can lead to fluid buildup in the middle ear, causing temporary hearing loss.</li>
            <li><h5>Risk Factors:</h5>Age (children are more prone to enlarged adenoids), exposure to infections, and environmental factors such as pollution and tobacco smoke increase the risk of developing enlarged adenoids.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Medications:</h5>Antibiotics for bacterial infections, antihistamines for allergies, and nasal corticosteroids to reduce inflammation.</li>
            <li><h5>Nasal Irrigation:</h5>Saline nasal sprays or rinses to help clear nasal passages and reduce congestion.</li>
            <li><h5>Humidifiers:</h5>Using a humidifier to keep the air moist can help alleviate symptoms and improve breathing.</li>
            <li><h5>Surgery:</h5>Adenoidectomy, the surgical removal of the adenoids, may be recommended in cases of chronic or severe enlargement that do not respond to other treatments.</li>
          </ul>
          <h3>Homoeopathic Treatment:</h3>
          <ul>
            <li><h5>Baryta Carb: </h5>For enlarged adenoids in children with delayed physical and mental development, frequent throat infections, and difficulty swallowing.</li>
            <li><h5>Calcarea Carb:</h5>For children with enlarged adenoids who are prone to catching colds, have a tendency to sweat excessively, and have a craving for eggs and indigestible substances.</li>
            <li><h5>Kali Bichrome:</h5>For thick, sticky mucus, nasal obstruction, and postnasal drip, often with a loss of smell and taste.</li>
            <li><h5>Hydrastis: </h5>For chronic nasal catarrh, thick yellowish mucus, and a constant desire to blow the nose.</li> 
            <li><h5>Tuberculinum:</h5>For children with a family history of tuberculosis or recurrent respiratory infections, with symptoms of chronic adenoid enlargement and persistent colds.</li>
            </ul>
          <h3>Conclusion</h3>
          <p>Homoeopathic treatment for enlarged adenoids focuses on personalized care and holistic management. By addressing both symptoms and underlying causes, Homoeopathy supports the body’s natural healing processes and improves overall well-being. Our patients often experience significant relief from symptoms, reduced frequency of infections, and enhanced quality of life with consistent Homoeopathic care. Our extensive experience demonstrates that, with appropriate remedy selection and patient compliance, Homoeopathy can effectively complement conventional treatments. This approach not only alleviates symptoms but also promotes long-term health benefits, emphasizing individualized treatment and lifestyle adjustments. Homoeopathy offers a gentle and non-invasive alternative, prioritizing holistic care and restoring balance to promote optimal health and well-being. In our practice, we have observed that patients who adhere to Homoeopathic treatment protocols typically report fewer episodes of infection, improved respiratory function, and better overall health. By considering each patient’s unique symptoms and health history, we can tailor treatments that not only target the enlarged adenoids but also support the immune system and enhance the body’s ability to heal itself. This comprehensive approach fosters lasting wellness and helps prevent the recurrence of adenoid-related issues.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Adenoids Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Adenoids conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/ent/adenoids/adenoids-diet">Diet for Adenoids</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Adenoids</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Adenoids</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
        <h3>Watch Our Video on Adenoids Treatment</h3>
        {!isVideoPlaying ? (
          <img
          src="https://img.youtube.com/vi/881Ui-soo20/hqdefault.jpg"
          alt="Video Thumbnail"
            onClick={handleVideoClick}
            style={{ cursor: 'pointer', width: '50%', height: '400px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}
          />
        ) : (
          <iframe
            src="https://www.youtube.com/embed/881Ui-soo20?si=YZSfLZ_J3CFucSRH"
            title="Kidney Stone Treatment Video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </div>
    </div>
  );
};

export default KidneyPage;
