import React from 'react';
import '../../css/CaseStudy.css';
import { Helmet } from "react-helmet";

const PCOSCaseStudy = () => {
  return (
    <div className="case-study-page">
       <Helmet>
       <link rel="canonical" href="https://niramayahomoeopathy.com/case-studies/case-hormones/" />
       </Helmet>
      <div className="case-study-hero">
        <h1>Penelope’s Journey to Lasting Relief from PCOS with Homeopathy</h1>
        {/* <img src={require('../images/case-study-hero.jpg')} alt="PCOS Recovery" className="case-study-img" /> */}
      </div>

      <div className="case-study-content">
        <p>
          Penelope, a 23-year-old from South Bunbury, Australia, had struggled with PCOS symptoms like irregular periods, missed cycles (amenorrhea), severe hair loss, and weight gain for years. Despite five years of treatment with general and naturopathic medicines, nothing brought lasting relief. Frustrated, she was encouraged by her fiancé, who found an online homeopathy clinic in June 2018, to try a new approach. Penelope decided to start homeopathic treatment on June 22, 2018.
        </p>

        <h2>Starting with Homeopathy</h2>
        <p>
          Though hesitant at first, Penelope felt hopeful about trying something natural that focused on the root cause of her PCOS. In her first consultation, the doctor discussed her symptoms, lifestyle, and medical history to create a personalized plan. She was informed that homeopathy works gradually, so the recovery would take time, with follow-up sessions every three months to track her progress.
        </p>

        <h2>A Balanced Approach</h2>
        <p>
          Alongside her remedies, Penelope’s doctor suggested lifestyle and dietary changes to support her healing, encouraging stress management, balanced nutrition, and gentle exercise. This holistic approach helped her feel more in control, and her monthly follow-ups ensured her progress stayed on track.
        </p>

        <h2>First Year of Improvement</h2>
        <p>
          In the first year, Penelope noticed small improvements, such as less hair loss and better energy levels. By the end of the year, her periods showed signs of becoming regular, and her weight started stabilizing. Motivated by these positive changes, Penelope continued with her follow-ups every three months.
        </p>

        <h2>Major Progress in the Second Year</h2>
        <p>
          In the second year, Penelope saw greater improvements: her periods became regular, her weight was more manageable, and her hair loss significantly reduced. Her doctor adjusted her treatment as needed, ensuring steady progress without symptoms interfering with her life.
        </p>

        <h2>Full Recovery After Three Years</h2>
        <p>
          After three years of consistent homeopathic treatment, Penelope’s symptoms were fully managed. Her periods were regular, she no longer experienced severe hair loss, and her weight was stable. With her doctor’s guidance, she gradually stopped the medication, and, to her relief, the symptoms did not return.
        </p>

        <h2>Life Today</h2>
        <p>
          Now, three years after ending treatment, Penelope remains symptom-free and lives her life confidently and healthily. She is grateful to her fiancé for finding this treatment option and to her homeopathic journey for finally bringing her lasting relief.
        </p>

        <h2>Final Thoughts</h2>
        <p>
          Penelope’s journey is proof of how homeopathy, patience, and lifestyle changes can manage even stubborn conditions like PCOS. Her story inspires others dealing with chronic conditions to explore natural treatments and stay committed to their health journey.
        </p>
      </div>
    </div>
  );
};

export default PCOSCaseStudy;
