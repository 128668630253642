import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/fh9.webp')}alt="Urology Hero" />
        <h1>Polyps</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Polyps</h2>
          <img src={require('../images/female9.webp')}alt="Urology Hero" />
        <h3> Basic Homoeopathic Approach</h3>
          <p>
          Homoeopathy aims to address the underlying causes of polyp formation, such as chronic inflammation, allergies, or irritants. Treatment focuses on reducing inflammation, shrinking the polyp, and improving overall mucosal health.          </p>
          <h3>Causes</h3>
          <p>Polyps in ENT areas can result from chronic irritation or inflammation of the mucous membranes lining the nasal passages, sinuses, throat, or larynx. Allergies, chronic sinusitis, nasal infections, and irritants like smoke or pollutants can contribute to their development.</p>
          <h3>Investigations</h3>
          <p>Diagnosis involves physical examination by an ENT specialist, including endoscopic examination to visualize the polyp and assess its location and size. Imaging studies such as CT scans may be used to evaluate the extent of the polyp and its impact on surrounding structures.</p>
          <h3>Complications and Risk Factors</h3>
          <p>Complications may include nasal obstruction, difficulty breathing, recurrent sinus infections, changes in voice quality (if polyps affect the vocal cords), and in severe cases, obstructive sleep apnea.</p>
          <h3>Traditional Basic Treatment</h3>
          <p>Conventional treatments may include nasal corticosteroid sprays to reduce inflammation, saline nasal rinses to keep nasal passages clear, oral corticosteroids for severe cases, and surgical removal of the polyp (polypectomy) if conservative measures fail.</p>
          <h3>Homoeopathic Treatment</h3>
          <p>Homoeopathic remedies for ENT polyps are chosen based on individual symptoms and constitution. Common remedies may include Calcarea carbonica, Kali bichromicum, Pulsatilla, Thuja occidentalis, and Silicea. These remedies aim to reduce inflammation, shrink the polyp, and improve mucosal health.</p>
          <h3>Conclusion / Our Experience</h3>
          <p>Homoeopathy offers a holistic approach to managing ENT polyps, focusing on addressing the underlying causes, reducing symptoms, and promoting long-term health. It can be used alone or in conjunction with conventional treatments depending on the severity and individual response.</p>

        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Polyps Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Polyps conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/female-troubles/polyps/polyps-diet">Diet for Polyps</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Polyps</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homoeopathy for Polyps</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Polyps Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/YO2iRcN9ukU?si=2hAi7lwm10qZ33BY"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
